import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { login, logout, isAuthenticated, getToken } from "../services/auth";

import { uniqueId } from 'loadsh';
import filesize from 'filesize';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/getscouted.css';
import '../css/dados.css';

import api from "../services/api";

import Admnav from '../components/admnav';

import Upload from '../components/upload';
import FileList from '../components/FileList';


import { translate } from "../services/translate";

import { vet_medidas, vet_bust, vet_waist, vet_hips, vet_hair, vet_eyes, vet_category, vet_day, vet_city, vet_pettype, vet_gender } from "../util/medidas";


class Dados extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	uploadedFiles: [],
	    	pet: null,
	    };
	}

	componentDidMount() {
	    this._downloadModels();
		this._checkLogin();
	}

	_checkLogin = async () => {
	    api.get(`/staff/check`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        //Logado
	      }
	      else {
	        //Erro no login
	        logout();
	        this.props.history.push("/login");
	      }
	    }).catch((err) => {
	    	logout();
	        this.props.history.push("/login");
	    });

	}

	_downloadModels = async () => {
	    axios.get(`/pets/${this.props.match.params.id}`)
	    .then(res => {
	    	console.log(res);
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.pets;
	        this.setState({
	          	pet: dados,
	      		approved: dados.approved,
	    		firstname: dados.firstname,
	    		lastname: dados.lastname,
	    		petname: dados.petname,
				phone: dados.phone,
				email: dados.email,
				birthday_day: dados.birthday_day,
				birthday_month: dados.birthday_month,
				birthday_year: dados.birthday_year,
				about: dados.about,
				city: dados.city,
				breed: dados.breed,
				skills: dados.skills,
				category: dados.category,
				gender: dados.gender,
	        });
	      }
	      else {
	      	this.props.history.push("/404");
	      }
	    }).catch((error) => {
	    	this.props.history.push("/404");
	    });

	}


	handleUpload = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
		});

		uploadedFiles.forEach(this.processUpload);

	};

	updateFile = (id, data) => {
		this.setState({
			uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
				return id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile;
			})
		});
	}

	processUpload = (uploadedFile) => {
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/pets/insertphoto/${this.state.pet._id}`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				this.updateFile(uploadedFile.id, {
					progress,
				});
			}
		}).then(response => {
			//console.log(response);
			this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});
		}).catch((error) => {
			//console.log(error);
			this.updateFile(uploadedFile.id, {
				error: true
			});
		});
	};

	handleDelete = async id => {
		await api.delete(`/pets/photo/${id}`);
		this.setState({
			uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
		});
	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}


	handleSubmit = async e => {
	    e.preventDefault();

	    const form = {
	    	approved: this.state.pet.approved,
	    	firstname: this.state.firstname,
	    	lastname: this.state.lastname,
	    	petname: this.state.petname,
			phone: this.state.phone,
			email: this.state.email,
			birthday_day: this.state.birthday_day,
			birthday_month: this.state.birthday_month,
			birthday_year: this.state.birthday_year,
			about: this.state.about,
			city: this.state.city,
			skills: this.state.skills,
			breed: this.state.breed,
			category: this.state.category,
			gender: this.state.gender,
	    }

	    try {
	      await api.put(`/staffpet/pet/${this.state.pet._id}`, form)
	      .then(response => { 
	        this.setState({
	        	pet: response.data.pets,
	        })
	        alert("Salvo!");
	      })
	      .catch(error => {
	      	alert('Oops! Ocorreu uma falha!');
	      });
	    } catch (err) {
	      alert('Oops! Ocorreu uma falha.');
	    }

	};

	removerfoto = async (id) => {
		api.delete(`/pets/photo/${id}`)
	    .then(res => {
	    	this._downloadModels();
	    }).catch((error) => {
	      alert('Falhou');
	    });
	};

	setcover = async (id) => {
		api.put(`/pets/setcover/${this.state.pet._id}/${id}`)
	    .then(res => {
	    	alert('Capa alterada');
	    }).catch((error) => {
	      alert('Falhou');
	    });
	};

	

 	render() {
 		const { uploadedFiles } = this.state;
 		let years = []
	    for(let i=0; i < 70; i++){
	      years.push(<option value={2019-i}>{2019-i}</option>)
	    }
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Admnav page={'modelos'}/>


			 	

				{this.state.pet !== null &&
					<section className="centered">
						<div className="form-title">Dados</div>
						<form className="row form" onSubmit={this.handleSubmit}>
							<div className="col-6">
								FIRST NAME<br/>
								<input type="text" name="firstname" value={this.state.pet.firstname} onChange={e => this.handleChange(e)}/><br/>
							</div>
							
							<div className="col-6">
								LAST NAME<br/>
								<input type="text" name="lastname" value={this.state.pet.lastname} onChange={e => this.handleChange(e)}/><br/>
							</div>

							<div className="col-4">
								PHONE<br/>
				  				<input type="text" name="phone" value={this.state.pet.phone} onChange={e => this.handleChange(e)}/><br/>
				  			</div>


				  			<div className="col-4">
				  				E-MAIL<br/>
				  				<input type="text" name="email" value={this.state.pet.email} onChange={e => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-4 fullwidth">
				  				CITY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="city" className="selectavant" value={this.state.pet.city} onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_city.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>


				  			<div className="col-12 form-birthday">
				  				BIRTHDAY
				  			</div>

				  			<div className="col-4 fullwidth">
				  				DAY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_day" className="selectavant" value={this.state.pet.birthday_day} onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_day.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-4 fullwidth">
				  				MONTH<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_month" className="selectavant" value={this.state.pet.birthday_month} onChange={e => this.handleChange(e)}>
										<option value=""></option>
										<option value="1">January</option>
										<option value="2">February</option>
										<option value="3">March</option>
										<option value="4">April</option>
										<option value="5">May</option>
										<option value="6">June</option>
										<option value="7">July</option>
										<option value="8">August</option>
										<option value="9">September</option>
										<option value="10">October</option>
										<option value="11">November</option>
										<option value="12">December</option>
									</select>
				  				</div>
				  			</div>

				  			<div className="col-4 fullwidth">
				  				YEAR<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_year" className="selectavant" value={this.state.pet.birthday_year} onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{years}
									</select>
				  				</div>
				  			</div>



				  			<div className="col-12 form-subtitle">
				  				PET INFORMATION
				  			</div>

				  			<div className="col-6 fullwidth">
				  				CATEGORY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="category" className="selectavant" value={this.state.pet.category} onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_pettype.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-6">
								BREED<br/>
								<input type="text" name="breed" value={this.state.pet.breed} onChange={e => this.handleChange(e)}/><br/>
							</div>

							<div className="col-6">
								PET NAME<br/>
								<input type="text" name="petname" value={this.state.pet.petname} onChange={e => this.handleChange(e)}/><br/>
							</div>

							<div className="col-6 fullwidth">
				  				GENDER<br/>
				  				<div className="divselect fullwidth">
				  					<select name="category" className="selectavant" value={this.state.pet.gender} onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_gender.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>
				  			
				  			<div className="col-12">
				  				TELL US A LITTLE ABOUT YOUR PET<br/>
				  				<textarea name="about" value={this.state.pet.about} onChange={e => this.handleChange(e)}></textarea>
				  			</div>


				  			<div className="col-12">
				  				SKILLS<br/>
				  				<input type="text" name="skills" value={this.state.pet.skills} onChange={e => this.handleChange(e)}/>
				  			</div>

				  			

				  			<div className="col-12">
								<input className="sendbtn" type="submit" value="Alterar dados"/>
							</div>


						</form>
			
						<div className="form-title">Fotos</div>

						<div className="row d-flex justify-content-start">
							{this.state.pet.images.map((i, index) =>
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={i.url} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={i.url} /><br/>
						            	</a>
						            	<div>
						            		<button onClick={() => {this.setcover(i._id)}}>Tornar capa</button>
						            		<button onClick={() => {this.removerfoto(i._id)}}>Remover</button>
						            	</div>
						          	</div>
						        </div>
							)}
						</div>

						

						{( (this.state.pet.photo1 !== null) && (this.state.pet.photo1 !== undefined) && (this.state.pet.photo2 !== null) && (this.state.pet.photo2 !== undefined) && (this.state.pet.photo3 !== null) && (this.state.pet.photo3 !== undefined) && (this.state.pet.photo4 !== null) && (this.state.pet.photo4 !== undefined) ) &&
							<div className="form-title">Fotos de inscrição</div>
						}

						<div className="row d-flex justify-content-start">

							{(this.state.pet.photo1 !== null) && (this.state.pet.photo1 !== undefined) &&
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={this.state.pet.photo1} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={this.state.pet.photo1} /><br/>
						            	</a>
						          	</div>
						        </div>
							}

							{(this.state.pet.photo2 !== null) && (this.state.pet.photo2 !== undefined) &&
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={this.state.pet.photo2} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={this.state.pet.photo2} /><br/>
						            	</a>
						          	</div>
						        </div>
							}

							{(this.state.pet.photo3 !== null) && (this.state.pet.photo3 !== undefined) &&
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={this.state.pet.photo3} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={this.state.pet.photo3} /><br/>
						            	</a>
						          	</div>
						        </div>
							}

							{(this.state.pet.photo4 !== null) && (this.state.pet.photo4 !== undefined) &&
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={this.state.pet.photo4} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={this.state.pet.photo4} /><br/>
						            	</a>
						          	</div>
						        </div>
							}

							
						</div>

						<div className="form-title">Adicionar fotos</div>
						<p className="tips">
							Arraste ou clique para adicionar novas fotos
						</p>
						<Upload onUpload={this.handleUpload}/>
						{ !!uploadedFiles.length && (
							<FileList files={uploadedFiles} onDelete={this.handleDelete}/>
						)}
						<br/>





					</section>
				}




			 </div>
			</div>
	    );
	}
}

export default withRouter(Dados);
