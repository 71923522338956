import React, { Component } from 'react';

import '../css/style.css';
import logo from '../images/logo.png';

import { translate } from "../services/translate";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }



  render() {
    return (
      <>
        <header>
          <img className="logo noselect" href="index.html" src={logo} alt="Logo"/>
          <div className="menu-toggle"></div>
          <nav>
            <ul>
              <li><a href="/" className={this.props.page === 'HOME' ? 'active' : ''}>{translate('home')}</a></li>
              
              <li><a href="/models/0/ALL/ALL" className={this.props.page === 'MODELS' ? 'active' : ''}>{translate('models')}</a>
                <ul>
                  <li><a href="/models/0/WOMEN/ALL" className={this.props.page === 'WOMEN' ? 'active' : ''}>{translate('women')}</a></li>
                  <li><a href="/models/0/MEN/ALL" className={this.props.page === 'MEN' ? 'active' : ''}>{translate('men')}</a></li>
                  {/*<li><a href="/models/0/KIDS/ALL" className={this.props.page === 'KIDS' ? 'active' : ''}>{translate('kids')}</a></li>*/}
                  <li><a href="/models/0/PROMOGIRL/ALL" className={this.props.page === 'PROMOGIRL' ? 'active' : ''}>PROMOGIRL</a></li>
                  {/*<li><a href="/pets/ALL/ALL" className={this.props.page === 'PETS' ? 'active' : ''}>PETS</a></li>*/}
                </ul>
              </li>
              {/*<li><a href="/models/INFLUENCERS/ALL" className={this.props.page === 'INFLUENCERS' ? 'active' : ''}>{translate('influencers')}</a></li>*/}
              <li><a href="/models/1/ALL/ALL" className={this.props.page === 'TALENTS' ? 'active' : ''}>{translate('talents')}</a>
                <ul>
                  <li><a href="/models/1/ACTOR/ALL" className={this.props.page === 'ACTOR' ? 'active' : ''}>{translate('actor')}</a></li>
                  <li><a href="/models/1/SINGER/ALL" className={this.props.page === 'SINGER' ? 'active' : ''}>{translate('singer')}</a></li>
                  <li><a href="/models/1/DJ/ALL" className={this.props.page === 'DJ' ? 'active' : ''}>DJ</a></li>
                  {/*<li><a href="/models/1/COMEDIA/ALL" className={this.props.page === 'COMEDY' ? 'active' : ''}>{translate('comedy')}</a></li>*/}
                  <li><a href="/models/1/TV HOST/ALL" className={this.props.page === 'TV HOSTER' ? 'active' : ''}>{translate('hoster')}</a></li>
                </ul>
              </li>
              <li><a href="/models/1/ALL/ALL" className={this.props.page === 'ARTISTS' ? 'active' : ''}>{translate('artists')}</a>
                <ul>
                  
                  <li><a href="/models/1/HAIR STYLES/ALL" className={this.props.page === 'HAIRSTYLES' ? 'active' : ''}>{translate('hairstyles')}</a></li>
                  <li><a href="/models/1/MAKE-UP/ALL" className={this.props.page === 'MAKEUP' ? 'active' : ''}>{translate('makeup')}</a></li>
                  <li><a href="/models/1/PHOTOGRAPHERS/ALL" className={this.props.page === 'PHOTOGRAPHERS' ? 'active' : ''}>PHOTOGRAPHERS</a></li>
                </ul>
              </li>

              <li><a href="/rv" className={this.props.page === 'RVS' ? 'active' : ''}>RV'S</a>
                <ul>
                  <li><a href="/rv" className={this.props.page === 'RV ABOUT' ? 'active' : ''}>{translate('about')}</a></li>
                  <li><a href="/rv/1" className={this.props.page === 'Anna Belle' ? 'active' : ''}>RV Anna Belle</a></li>
                  <li><a href="/rv/2" className={this.props.page === 'Betty Lou' ? 'active' : ''}>RV Betty Lou</a></li>
                  <li><a href="/rv/3" className={this.props.page === 'Retro Classic' ? 'active' : ''}>RV The Retro Classic</a></li>
                  <li><a href="/rv/4" className={this.props.page === 'Walter' ? 'active' : ''}>RV Walter</a></li>
                  <li><a href="/rv/clients" className={this.props.page === 'CLIENTS' ? 'active' : ''}>CLIENTS</a></li>
                </ul>
              </li>
              
              <li><a href="/getscouted" className={this.props.page === 'GET SCOUTED' ? 'active' : ''}>{translate('get scouted')}</a></li>
              {/*<li><a href="/getscouted" className={this.props.page === 'GET SCOUTED' ? 'active' : ''}>{translate('get scouted')}</a>
                <ul>
                  <li><a href="/getscouted" className={this.props.page === 'GET SCOUTED' ? 'active' : ''}>{translate('for me')}</a></li>
                  <li><a href="/getscoutedpet" className={this.props.page === 'GET SCOUTED PET' ? 'active' : ''}>{translate('for my pet')}</a></li>
                </ul>
              </li>*/}

              <li><a href="/about" className={this.props.page === 'ABOUT' ? 'active' : ''}>{translate('about')}</a>
                <ul>
                  <li><a href="/about" className={this.props.page === 'ABOUT' ? 'active' : ''}>{translate('about')}</a></li>
                  <li><a href="/projects" className={this.props.page === 'projects' ? 'active' : ''}>{translate('projects')}</a></li>
                  <li><a href="/workshop" className={this.props.page === 'WORKSHOP' ? 'active' : ''}>{translate('workshop')}</a></li> 
                </ul>
              </li>

              <li><a href="/faq/all" className={this.props.page === 'FAQall' ? 'active' : ''}>FAQ</a>
                <ul>
                  <li><a href="/faq/1" className={this.props.page === 'FAQ1' ? 'active' : ''}>AVANT GARDE TALENTS</a></li>
                  <li><a href="/faq/3" className={this.props.page === 'FAQ3' ? 'active' : ''}>BECOMING A TALENT</a></li>
                  <li><a href="/faq/8" className={this.props.page === 'FAQ7' ? 'active' : ''}>CELEBRITY</a></li>
                  <li><a href="/faq/4" className={this.props.page === 'FAQ4' ? 'active' : ''}>CASTINGS AND AUDITIONS</a></li>
                  <li><a href="/faq/9" className={this.props.page === 'FAQ7' ? 'active' : ''}>HOW DOES AVANTGARDETALENTS.COM WORK?</a></li>
                  {/*<li><a href="/faq/10" className={this.props.page === 'FAQ10' ? 'active' : ''}>SPECIALISTS IN PRODUCING DREAMS!</a></li>*/}
                  <li><a href="/faq/5" className={this.props.page === 'FAQ5' ? 'active' : ''}>PHOTOGRAPHY</a></li>
                  {/*<li><a href="/faq/6" className={this.props.page === 'FAQ6' ? 'active' : ''}>INDUSTRY PROS</a></li>
                  <li><a href="/faq/7" className={this.props.page === 'FAQ7' ? 'active' : ''}>BEWARE OF SCAMS</a></li>*/}
                  
                  
                </ul>
              </li>

              <li><a href="/contactpage" className={this.props.page === 'CONTACT' ? 'active' : ''}>{translate('contact')}</a></li>
              
              {/*<li><a href="/news" className={this.props.page === 'NEWS' ? 'active' : ''}>{translate('news')}</a></li>*/}
              
            </ul>
          </nav>
          <div className="clearfix"></div>
            
        </header>
      </>
    );
  }
}




export default Navbar;






/*
import React, { Component } from 'react';

import '../css/style.css';
import logo from '../images/logo.png';

import { translate } from "../services/translate";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }



  render() {
    return (
      <>
        <header>
          <img className="logo noselect" href="index.html" src={logo} alt="Logo"/>
          <div className="menu-toggle"></div>
          <nav>
            <ul>
              <li><a href="/" className={this.props.page === 'HOME' ? 'active' : ''}>{translate('home')}</a></li>
              <li><a href="/about" className={this.props.page === 'ABOUT' ? 'active' : ''}>{translate('about')}</a></li>
              <li><a href="/models/ALL/ALL" className={this.props.page === 'MODELS' ? 'active' : ''}>{translate('models')}</a>
                <ul>
                  <li><a href="/models/WOMEN/ALL" className={this.props.page === 'WOMEN' ? 'active' : ''}>{translate('women')}</a></li>
                  <li><a href="/models/MEN/ALL" className={this.props.page === 'MEN' ? 'active' : ''}>{translate('men')}</a></li>
                  <li><a href="/models/KIDS/ALL" className={this.props.page === 'KIDS' ? 'active' : ''}>{translate('kids')}</a></li>
                </ul>
              </li>
              <li><a href="/models/INFLUENCERS/ALL" className={this.props.page === 'INFLUENCERS' ? 'active' : ''}>{translate('influencers')}</a></li>
              <li><a href="/models/ARTISTS/ALL" className={this.props.page === 'ARTISTS' ? 'active' : ''}>{translate('artists')}</a>
                <ul>
                  <li><a href="/models/ACTOR/ALL" className={this.props.page === 'ACTOR' ? 'active' : ''}>{translate('actor')}</a></li>
                  <li><a href="/models/SINGER/ALL" className={this.props.page === 'SINGER' ? 'active' : ''}>{translate('singer')}</a></li>
                  <li><a href="/models/COMEDIA/ALL" className={this.props.page === 'COMEDY' ? 'active' : ''}>{translate('comedy')}</a></li>
                  <li><a href="/models/HAIRSTYLES/ALL" className={this.props.page === 'HAIRSTYLES' ? 'active' : ''}>{translate('hairstyles')}</a></li>
                  <li><a href="/models/MAKEUP/ACTOR" className={this.props.page === 'MAKEUP' ? 'active' : ''}>{translate('makeup')}</a></li>
                </ul>
              </li>
              <li><a href="/getscouted" className={this.props.page === 'GET SCOUTED' ? 'active' : ''}>{translate('get scouted')}</a>
                <ul>
                  <li><a href="/getscouted" className={this.props.page === 'GET SCOUTED' ? 'active' : ''}>{translate('for me')}</a></li>
                  <li><a href="/getscoutedpet" className={this.props.page === 'GET SCOUTED PET' ? 'active' : ''}>{translate('for my pet')}</a></li>
                </ul>
              </li>

              <li><a href="/contact" className={this.props.page === 'CONTACT' ? 'active' : ''}>{translate('contact')}</a></li>
              <li><a href="/projects" className={this.props.page === 'PROJECTS' ? 'active' : ''}>{translate('projects')}</a></li>
              <li><a href="/workshop" className={this.props.page === 'WORKSHOP' ? 'active' : ''}>{translate('workshop')}</a></li> 
              
            </ul>
          </nav>
          <div className="clearfix"></div>
            
        </header>
      </>
    );
  }
}




export default Navbar;
*/