import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/home.css';
import '../css/anihome.css';

import photo from '../images/1.jpeg';

import api from "../services/api";
//import { login, logout, isAuthenticated, getToken } from "../services/auth";

//import Rodape from '../components/rodape';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { translate } from "../services/translate";

import video1 from '../images/marilia.mp4';

//import '../css/footer.css';

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

class home extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	featuredTop: [],
	    	featured: [],
	    	blocos: [],
	    	width: 0, 
	      	height: 0,
	      	f1: null,
	      	f2: null,
	      	f3: null,
	      	f1v: false,
	      	f2v: false,
	      	f3v: false,
	      	featuredbkp: [],
	    };
	    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
  		window.addEventListener('resize', this.updateWindowDimensions);
	    this._getFeatured();
	    this._getFeaturedTop();
	    this._getFeaturedBlocos();
	    this.pageView();
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	prepara() {
		//console.log(this.state.featured[0]);
		//remover do inicio
		//escolher aleatorio o f
		//adicionar no final
		this.setState({
			f1v: false,
			f2v: false,
			f3v: false
		});

		var feat = this.state.featured;

		//var i;
		//for (i = 0; i < 3; i++) {}
		var f1 = feat.shift();
		var f2 = feat.shift();
		var f3 = feat.shift();

		//feat.push(f1);
		//feat.push(f2);
		//feat.push(f3);

		this.setState({
			featured: feat,
			f1: f1,
			f2: f2,
			f3: f3,
			f1v: true,
			f2v: true,
			f3v: true
		});

		setTimeout(() => { this.muda(); }, 10000);
	}


	muda() {
		const e1 = 680;
		const e2 = 30;

		const x = Math.floor(Math.random() * 3); //0 a 2
		const t = getRndInteger(6, 12) * 1000; 

		if (x === 0) {
			this.setState({
				f1v: false,
			});

			setTimeout(() => {
				var feat = this.state.featured;

				var f1 = feat.shift();

				feat.push(this.state.f1);

				this.setState({
					featured: feat,
					f1: f1,
				});

				setTimeout(() => {
					this.setState({
						f1v: true
					});
					setTimeout(() => {  this.muda(); }, t);
				}, e2);

			}, e1);
		}

		if (x === 1) {
			this.setState({
				f2v: false,
			});

			setTimeout(() => {
				var feat = this.state.featured;

				var f2 = feat.shift();

				feat.push(this.state.f2);

				this.setState({
					featured: feat,
					f2: f2,
				});

				setTimeout(() => {
					this.setState({
						f2v: true
					});
					setTimeout(() => {  this.muda(); }, t);
				}, e2);

			}, e1);
		}

		if (x === 2) {
			this.setState({
				f3v: false,
			});

			setTimeout(() => {
				var feat = this.state.featured;

				var f3 = feat.shift();

				feat.push(this.state.f3);

				this.setState({
					featured: feat,
					f3: f3,
				});

				setTimeout(() => {
					this.setState({
						f3v: true
					});
					setTimeout(() => {  this.muda(); }, t);
				}, e2);

			}, e1);
		}

		
		
		//setTimeout(() => {  this.muda(); }, 10000);
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Home / Início' });
	};

	_getFeaturedBlocos = async () => {
	    axios.get(`/blocos`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.featured;
	        this.setState({
	          blocos: dados,
	        });
	      }
	      else {
	        /*this.setState({
	          redirect: true,
	        });*/
	      }
	    }).catch(() => {
	      /*this.setState({
	        redirect: true,
	      });*/
	    });

	}

	_getFeatured = async () => {
	    axios.get(`/featured/2`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.featured;
	        this.setState({
	          featured: dados,
	        });
	        this.prepara();
	      }
	      else {
	        /*this.setState({
	          redirect: true,
	        });*/
	      }
	    }).catch(() => {
	      /*this.setState({
	        redirect: true,
	      });*/
	    });

	  }

	_getFeaturedTop = async () => {
	    axios.get(`/featured/1`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.featured;
	        this.setState({
	          featuredTop: dados,
	        });
	      }
	      else {
	        /*this.setState({
	          redirect: true,
	        });*/
	      }
	    }).catch(() => {
	      /*this.setState({
	        redirect: true,
	      });*/
	    });

	  }

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'HOME'}/>

			 	<section className="modelcontainer">

			 		<div className="row">

			 			{this.state.f1 !== null &&
			 				<>
				 				<a className="col-md-4 col-sm-12" href={this.state.f1.link}>
									<div className="photoboxhome" style={{height: this.state.height - 200}}>
										<img className={this.state.f1v?'fadeIn photo':'fadeOut photo'} src={this.state.f1.url}/>
									</div>
								</a>

								<a className="col-md-4 col-sm-12" href={this.state.f2.link}>
									<div className="photoboxhome" style={{height: this.state.height - 200}}>
										<img className={this.state.f2v?'fadeIn photo':'fadeOut photo'} src={this.state.f2.url}/>
									</div>
								</a>

								<a className="col-md-4 col-sm-12" href={this.state.f3.link}>
									<div className="photoboxhome" style={{height: this.state.height - 200}}>
										<img className={this.state.f3v?'fadeIn photo':'fadeOut photo'} src={this.state.f3.url}/>
									</div>
								</a>
							</>
			 			}

			 			

						



			 			{/*<div className="col-12" style={{marginBottom: 10}}>
			 				<Carousel autoplay={true} initialSlideHeight={500}
			 					renderCenterLeftControls={({ previousSlide }) => (
						    		<button onClick={previousSlide} className="btnslider">{'<'}</button>
						  		)}
						  		renderCenterRightControls={({ nextSlide }) => (
						    		<button onClick={nextSlide} className="btnslider">{'>'}</button>
						  		)}

			 				>
						        
						        {this.state.featuredTop.map((f, index) =>
                        			<div style={{height: 500, width: '100%'}}>
                        				<img className="fotomeio" src={f.url} />
                        			</div>
		                    	)}
						      </Carousel>
			 			</div>*/}

			 			{/*
			 			<div className="col-2"/>

			 			<div className="col-8">
			 				<div style={{marginTop: 30, marginBottom: 30}}>
			 				<video className="homevideo" controls>
							 	<source src={video1} type="video/mp4"/>
								Your browser does not support the video tag.
							</video>
							</div>
			 			</div>

			 			<div className="col-2"/>*/}












			 			{/*
			 			<div className="col-12" style={{marginBottom: 10}}>
			 				<Carousel autoplay={true} initialSlideHeight={500} wrapAround={true}
			 					renderCenterLeftControls={({ previousSlide }) => (
						    		<button onClick={previousSlide} className="btnslider">{'<'}</button>
						  		)}
						  		renderCenterRightControls={({ nextSlide }) => (
						    		<button onClick={nextSlide} className="btnslider">{'>'}</button>
						  		)}

			 				>

			 					{this.state.blocos.map((b, index) =>
			 						<div className="row">
			 							{b.map((f, index) =>
							    			<a className="col-4" href={f.link}>
												<div className="photoboxhome">
													<img className="photo" src={f.url}/>
												</div>
											</a>
							    		)}
			 						</div>
			 					)}
						    
						    </Carousel>
			 			</div>
						*/}

			 			{/*
			 			{this.state.featured.map((f, index) =>
                        
							<a className={index === 0 ? "col-4" : "col-4"} href={f.link}>
								<div className={index === 0 ? "photoboxhome" : "photoboxhome"}>
									<img className={index === 0 ? "photo" : "photo"} src={f.url}/>
								</div>
							</a>
						
                    	)}*/}

                    	

                    </div>

				
				</section>

			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(home);