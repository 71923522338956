import React, {Component} from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import api from "../services/api";

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/about.css';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { translate } from "../services/translate";


class about extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'About / Sobre' });
	};

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'ABOUT'}/>

				
			 	<section className="modelcontainer">

			 		<div className="row name">
						<div className="col-12">
							<div className="form-title">ABOUT US</div>
						</div>
					</div>

					<div className="row">
					
 
						<div className="col-12">
							<div className="textbox_a">

								{/*<p>
									<strong>Specialists in Producing Dreams!</strong>
								</p>
								<p>
									<br/><strong>AVANT GARDE TALENTS</strong> positions itself in the market as an entertainment solutions agency specializing in serving companies across the World, deploying our expertise in making our customers perceive a difference from an exclusive show.
								</p>

								<p>
									<br/><strong>AVANT GARDE TALENTS</strong> professionals have a great deal of experience in the art market and event production, as well as assessing the need for each show in full with the contractor, adding value to their event, from choosing the right attraction to performing on the day. show.<br/>
								</p>

								<p>
									We are not a supplier, but a partner interested in the end result differently.<br/>
								</p>

								<p>
									<strong>You idealize, We perform!</strong>
								</p>

								<p>
									Here at <strong>AVANT GARDE TALENTS</strong> we love to turn ideas into reality. Therefore, we reduce the size or complexity of the project, we embrace as proposals of our customers with passion and total commitment. We put all our energy and experience into delivering efficient, modern solutions that can deliver great results. More than producing a show, our mission is to connect people, mark events and convey great ideas through art and entertainment.<br/>
								</p>

								<p>
									<strong>Serious Entertainment</strong>
								</p>

								<p>

									We are completing more than two decades of operations in the entertainment market. Our highlight is the agency of artists and artistic production, logistics and technique. During our career, we had the pleasure of serving private clients to the largest companies in the world, delivering thousands of projects with great quality and professionalism.
								</p>

								<p>
									With over 20 years in the market, <strong>AVANT GARDE TALENTS</strong> has become a reference in artistic agency.
								</p>
								<p>
									<strong>A acquis of respect</strong>
								</p>
								<p>
									<br/>We have an exclusive cast of artists, models, actors and celebrities that meet the current market demand with versatility and high quality.
								</p>
								<p>
									In addition, we have permanent partnerships with the most renowned artistic attractions in the country, which accredit privileged negotiated conditions for our clients.<br/>
								</p>
								<p>
									<strong>Relax and enjoy the show</strong>
								</p>
								<p>
									<br/>We'll be running your project with a team that specializes in event production, resource management, attraction hiring, structure optimization and general logistics management. All this for you to reduce your event and not have to worry about technical or bureaucratic details. With an <strong>AVANT GARDE TALENTS</strong> your idea comes out of paper.
								</p>

								<p>
									<strong>AVANT GARDE TALENTS</strong> mission is clear: 100 % of all Artists, models and actors aren’t represented by an agency and we are helping them take over the entertainment industry.  <strong>AVANT GARDE TALENTS</strong> provides the 100% with the tools, opportunities and support to begin and advance their careers. 
								</p>

								<p>
									When we started <strong>AVANT GARDE TALENTS</strong> in 2000 our mission was to provide tools and resources for all aspiring talent to find success.  Over the last 5 years as our company grew to become a recognized leader in castings nationwide.   An agency can tell you, the 100%, “no” and tell you they aren’t willing to invest in developing you, but they can’t tell you that you can’t be successful.
								</p>

								<p>
									Come to <strong>AVANT GARDE TALENTS</strong> and be whatever you want.
									<br/>
									AVANT GARDE TALENTS The Un Agency uses the latest technology and an energized staff with over five years of experience in bookings, advice and top client relationships.  We pride ourselves on a staff that treats everyone with respect and kindness.  At <strong>AVANT GARDE TALENTS</strong> we work nonstop to ensure each of our talent receives castings and opportunities in real time from our database of industry contacts.  The opportunities range from television and film to commercial, music video, runway, print and promotional castings and gigs.
								</p>

								<p>
									Industry Clients of <strong>AVANT GARDE TALENTS</strong> Enjoy a searchable database of talent with detailed characteristics, a commission free service, a detailed talent evaluation process to ensure they are getting dedicated professionals and a corporate staff with years of industry knowledge and contacts.<br/>
									At <strong>AVANT GARDE TALENTS</strong> we believe a client and talent’s relationship is built on teamwork – both sides must be willing to invest in time and the hard work that is needed for success and treat each other with a professional attitude.<br/>
									<strong>AVANT GARDE TALENTS</strong> is excited to announce we will continue to operate in major markets nationwide including New York, Miami, San Francisco, (Corporate) and, of course, Los Angeles.
								</p>

								<p>
									The Benefits of <strong>AVANT GARDE TALENTS</strong><br/>
									<br/>Because we will give you and your business the peace of mind and security you need!
									<br/>• We produce shows of all styles for all types of Event.<br/>
									• Fair price with professional and experienced service.<br/>
									• The most complete and innovative service package on the market for you!<br/>
									• We have expertise in Artistic Contract Management.<br/>
									• Logistics Management and Technical Production with specialized team, minimizing unforeseen events.<br/>
									• We have proven excellence in our service.<br/>
									• We deliver complete Entertainment solutions:<br/>the curator.<br/>. Advice for Artistic Contracting.<br/>.  Tailored productions (creation of exclusive attractions).<br/>
									• Cost Optimization (adequacy to available budget).<br/>
									• Contractor Time Optimization (Field and In-house Production Team).<br/>
									• Relationship Management Artist X Contracting.
									•   Real-time access and submissions to nationwide castings and auditions
									•   An interactive composite card for all digital and traditional submissions
									•   A commission free environment
									•   A talent benefits program utilizing select businesses in each market
									•   Online 24/7 alerts and advanced casting recognition software matches you with the castings that suit you
									•   At <strong>AVANT GARDE TALENTS</strong> you have the opportunity to meet with Industry leaders at our monthly industry workshops<br/>
									If you feel you have the passion, the right attitude and the desire to be in the industry and you are ready to take the first step, click here to apply. Taking control of your career with <strong>AVANT GARDE TALENTS</strong> may be the move you’ve been looking for all along. So, what are you waiting for? Join The Un Agency and take control of your career today.


									</p>

								*/}


								{/*
								<p><strong>Specialists in Producing Dreams!</strong></p>
								
					
								<p>
									AVANT GARDE TALENTS positions itself in the market as an entertainment solutions Talents Agency Online specializing in serving companies across the World, deploying our expertise in making our customers perceive a difference from an exclusive show.<br/>
									AVANT GARDE TALENTS professionals have a great deal of experience in the art market and event production, as well as assessing the need for each show in full with the contractor, adding value to their event, from choosing the right attraction to performing on the day.<br/>
									We are not a supplier, but a partner interested in the end result differently.<br/>
									Here at AVANT GARDE TALENTS we love to turn ideas into reality. Therefore, we reduce the size or complexity of the project, we embrace as proposals of our customers with passion and total commitment. We put all our energy and experience into delivering efficient, modern solutions that can deliver great results. More than producing a show, our mission is to connect people, mark events and convey great ideas through art and entertainment.<br/>
									We are completing more than two decades of operations in the entertainment market. Our highlight is the agency of artists and artistic production, logistics and technique. During our career, we had the pleasure of serving private clients to the largest companies in the world, delivering thousands of projects with great quality and professionalism.<br/>
									With years in the market, AVANT GARDE TALENTS has become a reference in artistic talents agency.<br/>
									We have an exclusive cast of artists, models, actors and celebrities that meet the current market demand with versatility and high quality.<br/>
									In addition, we have permanent partnerships with the most renowned artistic attractions in the country, which accredit privileged negotiated conditions for our clients.<br/>
									We'll be running your project with a team that specializes in event production, resource management, attraction hiring, structure optimization and general logistics management. All this for you to reduce your event and not have to worry about technical or bureaucratic details. With an AVANT GARDE TALENTS your idea comes out of paper. AVANT GARDE TALENTS mission is clear: 100 % of all Artists, models and actors aren’t represented by an agency and we are helping them take over the entertainment industry. AVANT GARDE TALENTS provides the 100% with the tools, opportunities and support to begin and advance their careers.<br/>
									When we started AVANT GARDE TALENTS in 2000 our mission was to provide tools and resources for all aspiring talent to find success. Over years as our company grew to become a recognized leader in castings nationwide. An agency can tell you, the 100%, “no” and tell you they aren’t willing to invest in developing you, but they can’t tell you that you can’t be successful.<br/>
									Come to AVANT GARDE TALENTS and be whatever you want. AVANT GARDE TALENTS The Talents Agency uses the latest technology and an energized staff with over years of experience in bookings, advice and top client relationships. We pride ourselves on a staff that treats everyone with respect and kindness. At AVANT GARDE TALENTS we work nonstop to ensure each of our talent receives castings and opportunities in real time from our database of industry contacts. The opportunities range from television and film to commercial, music video, runway, print and promotional castings and gigs.<br/>
									Industry Clients of AVANT GARDE TALENTS Enjoy a searchable database of talent with detailed characteristics, a commission free service, a detailed talent evaluation process to ensure they are getting dedicated professionals and a corporate staff with years of industry knowledge and contacts.<br/>
									At AVANT GARDE TALENTS we believe a client and talent’s relationship is built on teamwork – both sides must be willing to invest in time and the hard work that is needed for success and treat each other with a professional attitude.<br/>
									AVANT GARDE TALENTS is excited to announce we will continue to operate in major markets nationwide including New York, Miami, San Francisco, (Corporate) and, of course, Los Angeles.<br/>
									The Benefits of AVANT GARDE TALENTS<br/>
									Because we will give you and your business the peace of mind and security you need!<br/>
									• We produce shows of all styles for all types of Event.<br/>
									• Fair price with professional and experienced service.<br/>
									• The most complete and innovative service package on the market for you!<br/>
									• We have expertise in Artistic Contract Management.<br/>
									• Logistics Management and Technical Production with specialized team, minimizing unforeseen events.<br/>
									• We have proven excellence in our service.<br/>
									• We deliver complete Entertainment solutions: the curator... Advice for Artistic Contracting. Tailored productions (creation of exclusive attractions).<br/>
									• Cost Optimization (adequacy to available budget).<br/>
									• Contractor Time Optimization (Field and In-house Production Team).<br/>
									• Relationship Management Artist X Contracting.<br/>
									• Real-time access and submissions to nationwide castings and auditions<br/>
									• An interactive composite card for all digital and traditional submissions<br/>
									• A commission free environment<br/>
									• A talent benefits program utilizing select businesses in each market<br/>
									• Online 24/7 alerts and advanced casting recognition software matches you with the castings that suit you<br/>
									• At AVANT GARDE TALENTS you have the opportunity to meet with Industry leaders at our monthly industry workshops<br/>
									If you feel you have the passion, the right attitude and the desire to be in the industry and you are ready to take the first step, click here to apply. Taking control of your career with AVANT GARDE TALENTS may be the move you’ve been looking for all along. So, what are you waiting for? Join Avant Garde Talents and take control of your career today.<br/>
								</p>*/}


								<p><strong>We Produce Dreams!</strong></p>
								<p>
									AVANT GARDE TALENTS positions itself in the market as a Full Service Online Talent Agency specializing in serving companies across the World. We supply top of the notch talent in the most diverse areas, such as models, actors, DJs, musicians, celebrities and more.<br/>
									AVANT GARDE TALENTS professionals have a great deal of experience in event production and casting, as well as assessing the specific needs of each client.<br/>
									<br/>
									Here at AVANT GARDE TALENTS we love to turn ideas into reality. Regardless of the size or complexity of your project, we embrace it with passion and total commitment.<br/>
									<br/>
									With over two decades of experience in the entertainment market, we had the pleasure of  delivering thousands of projects with great quality and professionalism.<br/>
								</p>
								










							</div>
						</div>

						

					</div>
				
				</section>

				



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(about);