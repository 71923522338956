import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';

import '../../css/style.css';
import '../../css/bootstrap-grid.css';
import '../../css/model.css';
import '../../css/rv.css';

import api from "../../services/api";

import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

import { translate } from "../../services/translate";

import img1 from '../../views/RVS/Betty Lou/1.jpg';
import img2 from '../../views/RVS/Betty Lou/2.jpg';
import img3 from '../../views/RVS/Betty Lou/3.jpg';
import img4 from '../../views/RVS/Betty Lou/4.jpg';
import img5 from '../../views/RVS/Betty Lou/5.jpg';
import img6 from '../../views/RVS/Betty Lou/6.jpg';
import img7 from '../../views/RVS/Betty Lou/7.jpg';
import img8 from '../../views/RVS/Betty Lou/8.jpg';


class model extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: `RV's - Betty Lou` });
	};

	

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'Betty Lou'}/>

			 	<section className="modelcontainer">

					<div className="row name">
						<div className="col-12" style={{marginBottom: 10}}>
							RV Betty Lou
						</div>
					</div>


					<div className="row">

						<div className="col-md-6 col-sm-12">

						</div>

						<div className="col-md-6 col-sm-12">

							<Carousel autoplay={true} heightMode={'max'} initialSlideHeight={500} 
				 					renderCenterLeftControls={({ previousSlide }) => (
							    		<button onClick={previousSlide} className="btnslider">{'<'}</button>
							  		)}
							  		renderCenterRightControls={({ nextSlide }) => (
							    		<button onClick={nextSlide} className="btnslider">{'>'}</button>
							  		)}

				 				>
							        
							        <div style={{height: 500, width: '100%'}}>
                        				<img className="fotomeio" src={img1} />
                        			</div>

                        			<div style={{height: 500, width: '100%'}}>
                        				<img className="fotomeio" src={img2} />
                        			</div>

                        			<div style={{height: 500, width: '100%'}}>
                        				<img className="fotomeio" src={img3} />
                        			</div>

                        			<div style={{height: 500, width: '100%'}}>
                        				<img className="fotomeio" src={img4} />
                        			</div>

                        			<div style={{height: 500, width: '100%'}}>
                        				<img className="fotomeio" src={img5} />
                        			</div>

                        			<div style={{height: 500, width: '100%'}}>
                        				<img className="fotomeio" src={img6} />
                        			</div>


                        			<div style={{height: 500, width: '100%'}}>
                        				<img className="fotomeio" src={img7} />
                        			</div>

                        			<div style={{height: 500, width: '100%'}}>
                        				<img className="fotomeio" src={img8} />
                        			</div>

                        			

							</Carousel>
				 			
							
						</div>

						<div className="col-12 measurements about">
							<div className="hvzio"/>
							<button className="btnhire" style={{marginRight: 10, marginTop: 20}} onClick={()=> (this.props.history.push(`/rent/2`))}>RENT BETTY LOU</button>
						</div>


			
					</div>


				</section>



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(model);