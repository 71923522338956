import React, {Component} from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import api from "../services/api";

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/about.css';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { translate } from "../services/translate";


class faq extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Privacy Policy' });
	};

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={``}/>

				
			 	<section className="modelcontainer">

			 		<div className="row name">
						<div className="col-12">
							<div className="form-title">PRIVACY POLICY</div>
						</div>
					</div>

					<div className="row">
					

						<div className="col-12">
							<div className="textbox_a">
								<p>
									Welcome to www.avantgardetalents.com (the “Website”) operated by Avant Garde Talents, LLC. (“Avant Garde Talents”, “We” or “Us”). This Privacy Policy is intended to inform you about how your personally identifiable information (“Personal Information”) and other information is collected and used by us in operating the Website.
								</p>
								<p>
									How does the Website Work?<br/>
									The Website offers anonymous browsers (“Visitors”) the opportunity to learn more about us. Visitors who register with the Website (“Registrants”) have the opportunity to submit an application to us, through the Website, to try to become a model who is represented by Avant Garde Talents  (“Application”). By using the Website, you acknowledge your acceptance of this Privacy Policy and your consent to the collection, use, disclosure, management and storage of your Personal Information by Avant Garde Talents as described herein. Avant Garde Talents reserves the right to change, at any time, the handling practices for information we collect through the Website.
								</p>
								<p>
									We will update this Privacy Policy to reflect any changes in our data collection and handling practices, and will indicate that changes to this Privacy Policy have been made by listing a new date under “Last Updated” at the end of this Privacy Policy. Such changes will take effect immediately once posted to the Website.
								</p>
								<p>
									What type of Personal Information does Avant Garde Talents collect and how do we use it?<br/>
									Avant Garde Talents does not require you to provide us with any Personal Information in order to browse the Website. Avant Garde Talents does not collect Personal Information from individuals except when it is specifically and knowingly provided. For example, in order to submit an Application to Avant Garde Talents, Registrants must provide us with certain Personal Information, including without limitation (i) a Registrant’s name, address, telephone number and email address, (ii) a detailed description of the Registrant’s physical attributes, and photographs/videos of a Registrant (“Content”). All Personal Information that is collected is for a specified and legitimate purpose and is considered adequate, relevant and limited to what is necessary for such purpose.
								</p>
								<p>
									Unless you provide your Personal Information to Avant Garde Talents through the Website, we will not collect any Personal Information from you. In the event you submit your Personal Information to us, we will use such Personal Information for internal purposes, for contacting you about events and media related to Avant Garde Talents’ activities and we may share such Personal Information with third parties who may contact you as well with news and offers. You may opt-out of such communications if they do not interest you. We may also release such Personal Information to third parties if we believe such release is necessary to comply with the law (e.g., a legal process or a statutory authorization), to apply or enforce our customer agreements, to protect our rights or property, if we reasonably believe that the physical safety of a person is threatened, or as part of a corporate reorganization, consolidation, merger, or sale of substantially all of the assets of Avant Garde Talents.
								</p>
								<p>
									Please keep in mind that, if you are accepted as a model by Avant Garde Talents, portions of the Content contained in your Application may be made publicly viewable on the Website by us. Do not submit any Content to the Website that you are not authorized to distribute to the public or that you don’t want others to see.
								</p>
								<p>
									What other information does Avant Garde Talents collect?<br/>
									We also may collect anonymous and aggregate information from you about your preferences in navigating the Website by using cookies, action tags and other methods. Cookies are small text files that identify your computer. We may use cookies to, among other things, monitor Website usage, customize Website features, identify your computer’s operating system and browser, and complete transactions. An action tag is a small, graphic image on a webpage that is designed to allow the website owner or a third party to monitor who is visiting a website by collecting the IP address of the computer to which the tag is sent, the URL of the webpage that the tag comes from and the time that such webpage was viewed. Tags may be associated with cookie information. We may also use such anonymous and aggregate information for promotional purposes.
								</p>
								<p>
									If you do not want us to track such information about you, you can set your browser to warn you before accepting cookies and refuse cookies when your browser alerts you to their presence. You can also adjust the settings in your browser to refuse all cookies.
								</p>
								<p>
									You can be assured that Personal Information collected through the Website is maintained in a manner consistent with current industry standards. Your Personal information is protected in several ways. Your personal information resides on a secure server that only selected Avant Garde Talents, employees and contractors have access to via password. We encrypt your Personal Information and thereby prevent unauthorized parties from viewing such information when it is transmitted to us. Unfortunately, no data transmission over the internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your Personal Information, you acknowledge that: (a) there are security and privacy limitations of the Internet which are beyond our control; (b) the security, integrity, and privacy of any and all information and data exchanged through the Website cannot be guaranteed; and (c) any such information and data may be viewed or tampered with in transit by a third party.
								</p>
								<p>
									While we take reasonable efforts to protect Personal Information that you provide to us, we cannot guarantee that such information will not be lost, disclosed or accessed by accidental circumstances or by the unauthorized acts of others.
								</p>
								<p>
									What other information does Avant Garde Talents share?<br/>
									We will not rent or sell your information to third parties without your consent, except as noted in this Privacy Policy. We may share your information as set forth above with businesses that are legally part of the same group of companies that Avant Garde Talents is part of, or that become part of that group ("Affiliates"). We also may share your information as set forth above to providers that help us provide the services to you ("Service Providers"). Our Service Providers will be given access to your information as is reasonably necessary to provide the service under reasonable confidentiality terms. We may remove parts of data that can identify you and share anonymized data with other parties. We may also combine your information with other information in a way that it is no longer associated with you and share that aggregated information.
								</p>
								<p>
									We may access, preserve and share your information in response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so. This may include responding to legal requests from jurisdictions outside of the United States where we have a good faith belief that the response is required by law in that jurisdiction, affects users in that jurisdiction, and is consistent with internationally recognized standards. We may also access, preserve and share information when we have a good faith belief it is necessary to: detect, prevent and address fraud and other illegal activity; to protect ourselves, you and others, including as part of investigations; and to prevent death or imminent bodily harm. Information we receive about you may be accessed, processed and retained for an extended period of time when it is the subject of a legal request or obligation, governmental investigation, or investigations concerning possible violations of our terms or policies, or otherwise to prevent harm.
								</p>
								<p>
									Legal Basis for processing personal information under the General Data Protection Regulation (“GDPR”)<br/>
									If you are located in the European Union (“EU”) Avant Garde Talents will only process your personal information when it has a lawful basis to do so. This includes:
								</p>
								<p>
									• You have given us consent to do so;<br/>
									• Such processing is necessary to Avant Garde Talents’ performance under a contract to which you are a party;<br/>
									• The processing is necessary for compliance with a legal obligation to which Avant Garde Talents is subject;<br/>
									• Such processing is necessary in order to protect your vital interests; or<br/>
									• The processing is necessary for the performance of a task carried out in the public interest or our legitimate interests and is not overridden by your rights.
								</p>
								<p>
									All personal information that is processed is accurate based on what you have conveyed to us and will be kept up to date as we are informed of changes.
								</p>
								<p>
									Your rights under the GDPR<br/>
									Users residing in certain countries, including the EU, are afforded certain rights regarding their personal information under the GDPR. This includes the right to:
								</p>
								<p>
									• request a copy of their personal data;<br/>
									• request that their inaccurate personal data is corrected;<br/>
									• request that their personal data is deleted and destroyed under certain circumstances; <br/>
									• request that we restrict the processing of your personal information; <br/>
									• withdraw your consent at any time where Avant Garde Talents relies on your consent to process your personal information; and<br/>
									• opt out of receiving electronic communications from us.
								</p>
								<p>
									Consent to Transfer Information to the United States<br/>
									If you are located anywhere outside of the United States, please be aware that information we collect through this website, including your personal information, will be transferred to, processed, and stored in the United States. The data protection laws in the United States may differ from those of the country in which you are located, and your personal information may be subject to access requests from governments, courts or law enforcement in the United States according to laws of the United States. By providing us with any information you consent to this transfer, processing, and storage of your information in the United States.

								</p>
								<p>
									What are our policies regarding children?<br/>
									Registrants must be eighteen (18) years of age or older in order to independently submit an Application to us. Registrants may be under eighteen (18) years of age if their parent/legal guardian registers on their behalf; however, Registrants may in no case be under thirteen (13) years of age. Avant Garde Talents does not knowingly collect Personal Information from anyone under thirteen (13) years of age and no part of our website is designed to attract anyone under age thirteen (13). Please do not communicate with or contact us if you are under age thirteen (13).
								</p>



							</div>
						</div>

						

					</div>

					<div className="row name">
						<div className="col-12">
							<div className="form-title">Avant Garde Talents Website Terms of Service</div>
						</div>
					</div>

					<div className="row">
					

						<div className="col-12">
							<div className="textbox_a">
								<p>
									1.	Services Provided.<br/>
									According to these Terms of Service, Avant Garde Talent is permitted, among other things, (a) to digitally upload and manage their portfolio content; (b) to apply for castings; (c) to upload their previous work in which they have the right and license to do so to other Talent and Talent Seekers; and (d) to get scouted by Talent Seekers. According to these Terms of Service, Talent Seekers are permitted, among other things, (a) to host a casting; (b) to browse and view previous work uploaded by the Talent; (c) to search Talent content using advanced filtering; and (d) to video chat to scout talent. The services provided to the Talent and Talent Seekers are collectively referred to herein as the “Services.”
								</p>
								<p>
									2.	Age Requirement.<br/>
									You agree to comply with the following minimum age requirement: (a) 13 years old if using and / or accessing the Avant Garde Talents mobile application and website in the United States; or (b) if any law, foreign or national, requires that you are older to use and / or access the Services, then the older age. Our Services and the Avant Garde Talents mobile app and website are not intended for children under 13. If you find that you or someone you know has provided us with personal information for children under 13, please contact us immediately. We do not knowingly collect personal information from children under the age of 13. If we become aware that a child under the age of 13 has provided us with personal information, we will take the necessary steps to remove that information and close any accounts created by children under the age of 13.
								</p>
								<p>
									3.	 Accuracy.<br/>
									You represent and warrant that all information, content, photographs, videos, posts and works submitted to the Avant Garde Talents mobile application and website at the time of registration and will subsequently be true, accurate, current and complete and agree to update such information. content as needed to maintain its truth and accuracy.
								</p>
								<p>
									4.	Talent Invites.<br/>
									As part of the Services, Talent is permitted to instruct the Avant Garde Talents mobile app and website to send a digital invite to bookers and agencies. You, as Talent, acknowledge that other talent may trigger the transmission of digital invitations to the same bookers and agencies. You, as Talent, represent and warrant that you will not use this feature to transmit illegitimate digital invites to bookers and agencies, such as spamming these entities, and You will only send digital invites in connection with the use of the Services. To the fullest extent permitted by law, Avant Garde Talents Digital disclaims and disavows any responsibility or liability for any claims that any booker or agency may raise or assert associated with the transmission of digital invites sent on behalf of you.
								</p>
								<p>
									5.	Third-Party Casting.<br/>
									The Terms of Service are a legally binding agreement between you and Avant Garde Talents Digital, and nowhere else. You acknowledge that certain third party companies that host castings that use and access the Avant Garde Talents mobile application and website may upload and / or publish their own terms of service on the Avant Garde Talents mobile application and website. These terms of service in no way govern your use or access of the Avant Garde Talents website and mobile application by you, and such use and access are completely governed by the Terms of Service.
								</p>
								<p>
									6.	Interactions.
									You are solely responsible for your interaction with other users of the Services, whether online or offline (e.g., face to face meetings). You agree that Avant Garde Talents Digital is not responsible or liable for the conduct of any User. Avant Garde Digital reserves the right, but has no obligation, to monitor or become involved in disputes between you and other users. Exercise common sense and your best judgment when interacting with others, including when you submit or post Content or any personal or other information.
								</p>
								<p>
									7.	Legal Purposes.<br/>
									You agree not to use the Services for any illegal or unauthorized purpose. You agree to comply with all laws, rules and regulations applicable to your use of and access to the Services.
								</p>
								<p>
									8.	Content.<br/>
									You are solely responsible for your conduct and any data, text, files, information, usernames, images, graphics, photos, profiles, audio and video clips, sounds, musical works, works of authorship, applications, links, job listings, and other content or materials (collectively, "Content") that you submit, post or display on or via the Services. The Service may record the location of any device from which the Content is uploaded to the Service and You consent to Us transmitting, collecting and processing and use of Your location Content.
								</p>
								<p>
									9.	Third-Party Content.<br/>
									By using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Ford Digital does not and is not required to review this content. You agree that Avant Garde Talents Digital is not responsible for any content of third-parties or for any damages as a result of your use of or reliance on it.
								</p>
								<p>
									10.	No Responsibility.<br/>
									You agree that Avant Garde Talents Digital is not responsible for, and does not endorse any content posted or uploaded. Avant Garde Talents Digital does not have any obligation to prescreen, monitor, edit, or remove any content. If your Content violates these Terms of Service or other laws or regulations, you may bear legal responsibility for the Content.	
								</p>
							</div>
						</div>
					</div>
				
				</section>

				



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(faq);