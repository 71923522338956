var translates = require('../config/languages.json');

export const getLanguage = () => localStorage.getItem('language');
export const changeLanguage = (language) => {
	localStorage.setItem('language', language);
}

export const translate = (label) => {
	var language = getLanguage();
	if (language === null)
		language = 'en';
	return translates[language][label];
}	