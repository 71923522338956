import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import { uniqueId } from 'loadsh';
import filesize from 'filesize';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/getscouted.css';
import '../css/schedule.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';


import { translate } from "../services/translate";

import { vet_period } from "../util/medidas";


class schedule extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	rv: '',
	    };
	}

	componentDidMount() {
	    this.pageView();

	    if (this.props.match.params.id !== null || this.props.match.params.id !== '') {
	    	this._downloadModels();
	    }
	}

	_downloadModels = async () => {
	    if (this.props.match.params.id === '1') {
	    	this.setState({
	    		rv: 'RV Anna Belle'
	    	});
	    }
	    if (this.props.match.params.id === '2') {
	    	this.setState({
	    		rv: 'RV Betty Lou'
	    	});
	    }
	    if (this.props.match.params.id === '3') {
	    	this.setState({
	    		rv: 'RV The Retro Classic'
	    	});
	    }
	    if (this.props.match.params.id === '4') {
	    	this.setState({
	    		rv: 'RV Walter'
	    	});
	    }

	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Alugar RV' });
	};


	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}


	handleSubmit = async e => {
	    e.preventDefault();

	    const form = {
	    	name: this.state.name,
	    	email: this.state.email,
	    	company: this.state.company,
			tel: this.state.tel,
			city: this.state.city,
			state: this.state.state,
			rv: this.state.rv,
			date: this.state.date,
	
	    }

	    try {
	      await api.post('/contact/rv', form)
	      .then(response => { 
	        //cadastrou!
	        alert('Request sent successfully!');
			this.props.history.push("/");
	      })
	      .catch(error => { 
	       	alert('Oops! Something went wrong, try again.');
	      });

	    } catch (err) {
	      alert('Oops! Something went wrong, try again.');
	    }

	};


 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={''}/>


				<section className="centered">

					<div className="row">
						<div className="col-6">
							<div className="form-title">
								RENT AN RV<br/>
							</div>
							<div className="row texto">
								<div className="col-12 hiresc">
									<p>
										Are you looking to rent an RV? Avant Garde RV offers our customers a wide variety of different types of motorhome rentals. With all the options we offer, you are sure to find round-trip RV rentals and RV rentals in a way that works best for you. <br/>
										Our RVs come in a variety of sizes and styles to accommodate your work. <br/>
										If you are not sure which type of RV will work best for you and your plans, and you need professional advice, one of our representatives will be happy to assist you. Just contact us at 1.212.920.6110 to get started.
									</p>
									
								</div>



							</div>
						</div>

						<div className="col-6">

							<div className="form-title">RV RENTAL QUOTE FORM</div>
							<form className="row form" onSubmit={this.handleSubmit}>
								
								
								<div className="col-12">
									NAME<br/>
									<input type="text" name="name" onChange={e => this.handleChange(e)}/><br/>
								</div>

								<div className="col-12">
					  				E-MAIL<br/>
					  				<input type="text" name="email" onChange={e => this.handleChange(e)}/>
					  			</div>

					  			<div className="col-12">
									COMPANY NAME<br/>
									<input type="text" name="company" onChange={e => this.handleChange(e)}/><br/>
								</div>

								
								<div className="col-12">
									PHONE<br/>
					  				<input type="text" name="tel" onChange={e => this.handleChange(e)}/><br/>
					  			</div>

					  			<div className="col-12">
									CITY<br/>
					  				<input type="text" name="city" onChange={e => this.handleChange(e)}/><br/>
					  			</div>

					  			<div className="col-12">
									STATE<br/>
					  				<input type="text" name="state" onChange={e => this.handleChange(e)}/><br/>
					  			</div>

					  			<div className="col-12">
					  				DATE
					  				<input type="text" name="date" onChange={e => this.handleChange(e)}/>
					  			</div>

					  			
					  			<div className="col-12">
					  				RV
					  				<input type="text" name="rv" value={this.state.rv} onChange={e => this.handleChange(e)}/>
					  			</div>

	

								<div className="col-12">
									<input className="sendbtn" type="submit" value="SEND"/>
								</div>


							</form>



						</div>

					</div>



					
			

				</section>




			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(schedule);
