import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import { uniqueId } from 'loadsh';
import filesize from 'filesize';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/getscouted.css';
import '../css/schedule.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';


import { translate } from "../services/translate";

import { vet_period } from "../util/medidas";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class schedule extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	models: null,
	    	artist: '',
	    	info: null,
	    	videos: null,
	    	startDate: new Date(),
	    	logo: null,
	    };
	}

	handleChangeDate = date => {
	    this.setState({
	      startDate: date
	    });
	  };

	componentDidMount() {
	    this.pageView();

	    if (this.props.match.params.id !== null || this.props.match.params.id !== '') {
	    	this._downloadModels();
	    }
	}

	_downloadModels = async () => {
	    api.get(`/models/${this.props.match.params.id}`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.models;
	        this.setState({
	          models: dados,
	          artist: `${dados.firstname} ${dados.lastname}`,
	          info: dados.schedule,
	          videos: dados.videos,
	        });
	        this.pageView();
	        try {
	        	if (String(this.state.models.logo).length > 0)
	        	this.setState({
	        		logo: this.state.models.logo
	        	});
	        } catch (err) {

	        }
	      }
	      else {
	        //this.props.history.push("/404");
	      
	      }
	    }).catch((error) => {
	      //this.props.history.push("/404");
	    });

	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Solicitar agendamento' });
	};


	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}


	handleSubmit = async e => {
	    e.preventDefault();

	    const form = {
	    	name: this.state.name,
	    	email: this.state.email,
			tel: this.state.tel,
			city: this.state.city,
			state: this.state.state,
			artist: this.state.artist,
			date: this.state.startDate,
			type: this.state.type,
			period: this.state.period
	    }

	    try {
	      await api.post('/contact/schedule', form)
	      .then(response => { 
	        //cadastrou!
	        alert('Request sent successfully!');
			this.props.history.push("/");
	      })
	      .catch(error => { 
	       	alert('Oops! Something went wrong, try again.');
	      });

	    } catch (err) {
	      alert('Oops! Something went wrong, try again.');
	    }

	};


 	render() {
 		const { uploadedFiles } = this.state;
 		let years = []
	    for(let i=0; i < 70; i++){
	      years.push(<option value={2019-i}>{2019-i}</option>)
	    }
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={''}/>


				<section className="centered">

					<div className="row">
						<div className="col-6">
							<div className="form-title">
								SCHEDULE ATTENDANCE WITH OUR ARTISTS<br/>
							</div>
							<div className="row texto">
								<div className="col-12 hiresc">
									<p>
										Want to know how to book {this.state.artist} for your event? Avant Garde Talents is the main Agent for {this.state.artist} around the world.
									</p>
									<p>
										Our agent will be happy to assist you in obtaining the availability and booking fee of {this.state.artist}, or the price and availability of any other Talent-List Artist you wish for your corporate event, private party, wedding, birthday party or public show.
										Do you want to talk about your event now? Call: 212.920.6110 and one of our booking agents will be happy to assist you immediately.
										Monday - Friday 9:00 - 19:00 EST

									</p>
									<p>
										At Avant Garde Talents, we put our 20 years on the market to help you get the best price for any artist, comedian or celebrity at your event.
										Our team of experienced industry professionals can also handle all aspects of the entertainment part of your event to ensure that the entertainment runs smoothly.

									</p>
								</div>


								{this.state.videos !== null &&
									<div style={{paddingTop: 15}}>

										{this.state.videos.map((i, index) =>
											<a href={i.url}><i className="fa fa-youtube iconesmodel" aria-hidden="true"></i></a>
										)}

									</div>
								}


								{(this.state.logo !== null) &&
									<div className='col-12 quebra5'>
										<img className="logosc text-center" width="260" heigth="120" src={this.state.logo} />
									</div>
								}								


								{(this.state.info !== null && this.state.info !== '') &&
									<div className="col-12">
										<div className="quebra5" dangerouslySetInnerHTML={{ __html: this.state.info }}/>
									</div>
								}


								

							</div>
						</div>

						<div className="col-6">

							<div className="form-title">SCHEDULE REQUEST FORM</div>
							<form className="row form" onSubmit={this.handleSubmit}>
								
								<div className="col-12 hiresc">
									<p>
										Fill out the form below to request an appointment with one of our artists, choose a date and time of your choice within the period of availability of the artist, if available we will return confirming and passing more information. If the period you requested is no longer available, we will contact you looking for a new time.
									</p>
								</div>


								<div className="col-12">
									NAME<br/>
									<input type="text" name="name" onChange={e => this.handleChange(e)}/><br/>
								</div>

								<div className="col-12">
					  				E-MAIL<br/>
					  				<input type="text" name="email" onChange={e => this.handleChange(e)}/>
					  			</div>
								
								<div className="col-12">
									PHONE<br/>
					  				<input type="text" name="tel" onChange={e => this.handleChange(e)}/><br/>
					  			</div>

					  			<div className="col-12">
									CITY<br/>
					  				<input type="text" name="city" onChange={e => this.handleChange(e)}/><br/>
					  			</div>

					  			<div className="col-12">
									STATE<br/>
					  				<input type="text" name="state" onChange={e => this.handleChange(e)}/><br/>
					  			</div>

					  			
					  			<div className="col-12">
					  				REQUESTED ARTIST
					  				<input type="text" name="artist" value={this.state.artist} onChange={e => this.handleChange(e)}/>
					  			</div>

					  			<div className="col-12">
					  				DESIRED SERVICE<br/>
					  				<input type="text" name="type" onChange={e => this.handleChange(e)}/>
					  			</div>

					  			{/*<div className="col-12">
					  				DESIRED DATE<br/>
					  				<input type="text" name="date" onChange={e => this.handleChange(e)}/>
					  			</div>*/}

					  			<div className="col-12 fullwidth">
					  				DESIRED DATE<br/>
					  				<DatePicker className="pickerdata" selected={this.state.startDate} name="datepicker" onChange={this.handleChangeDate} />
					  			</div>

					  		
					  			<div className="col-12 fullwidth">
					  				PREFERENCE PERIOD FOR ATTENDANCE<br/>
					  				<div className="divselect fullwidth">
					  					<select name="period" className="selectavant" onChange={e => this.handleChange(e)}>
											<option value=""></option>
											{vet_period.map((m, index) =>
						                        <option value={m}>{m}</option>
						                    )}
										</select>
					  				</div>
					  			</div>

					  	

								<div className="col-12">
									<input className="sendbtn" type="submit" value="SEND"/>
								</div>


							</form>



						</div>

					</div>



					
			

				</section>




			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(schedule);
