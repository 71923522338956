import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import '../css/style.css';
import '../css/bootstrap-grid.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import Cardpet from '../components/cardpet';

import { translate } from "../services/translate";


class pets extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      pets: [],
	    };
	}

	componentDidMount() {
	    this._downloadModels();
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: `Pets : ${this.props.match.params.category} - ${this.props.match.params.city}` });
	};

	_downloadModels = async () => {
	    axios.get(`/pets/approved/${this.props.match.params.category}/${this.props.match.params.city}`)
	    .then(res => {
	      //console.log(res.status);
	      //console.log(res.data);
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.pets;
	        this.setState({
	          pets: dados,
	        });
	      }
	      else {
	        /*this.setState({
	          redirect: true,
	        });*/
	      }
	    }).catch(() => {
	      /*this.setState({
	        redirect: true,
	      });*/
	    });

	  }

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={this.props.match.params.category}/>


			 	<section className="modelcontainer">

					<div className="menuciteis">
						<ul>
							<li><a href={`/models/${this.props.match.params.category}/ALL`} className={this.props.match.params.city === 'ALL' ? 'active' : ''}>{translate('all')}</a></li>
							<li><a href={`/models/${this.props.match.params.category}/MIAMI`} className={this.props.match.params.city === 'MIAMI' ? 'active' : ''}>MIAMI</a></li>
							<li><a href={`/models/${this.props.match.params.category}/NEW YORK`} className={this.props.match.params.city === 'NEW YORK' ? 'active' : ''}>NEW YORK</a></li>
							<li><a href={`/models/${this.props.match.params.category}/SÃO PAULO`} className={this.props.match.params.city === 'LOS ANGELES' ? 'active' : ''}>LOS ANGELES</a></li>
							<li><a href={`/models/${this.props.match.params.category}/MILAN`} className={this.props.match.params.city === 'MILAN' ? 'active' : ''}>MILAN</a></li>
						</ul>
					</div>
					
					<div className="row d-flex justify-content-start">


						{this.state.pets.map((m, index) =>
	                        <Cardpet data={m}/>
	                    )}


					</div>

				</section>




			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(pets);
