import React, {Component} from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import api from "../services/api";

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/contact.css';

import photo from '../images/1.jpeg';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { translate } from "../services/translate";


class contact extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Contact / Contato' });
	};

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'CONTACT'}/>

				
			 	<section className="modelcontainer">

					<div className="row">
						
						<div className="col-8">
							<div className="textbox_c">
								<div className="form-title_c">CONTACT</div>
								<p>
									<br/>
									
									<strong>New York City</strong><br/>
									218 East 27th Street Suite 10<br/>
									New York, NY 10016<br/>
									T: 212.920.6110<br/>
									Email: cast@avantgardetalents.com  <br/>
									
									<br/>
									<strong>Milan</strong><br/>
									Via Valtellina, 40<br/>
									20159 Milano MI, Italy<br/>
									Email: cast@avantgardetalents.com<br/>
									<br/>
									<br/>
									Please note:<br/>
									We do not hold open calls.

									
									


								</p>
							</div>
						</div>

						<div className="col-4">
							<div className="hvzio"/>
							<div className="photobox_c">
								<img className="photo_c" src={photo} />
							</div>
						</div>

					</div>
				
				</section>

				



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(contact);