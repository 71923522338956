import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { login, logout, isAuthenticated, getToken } from "../services/auth";

import { uniqueId } from 'loadsh';
import filesize from 'filesize';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/getscouted.css';
import '../css/dados.css';

import api from "../services/api";

import Admnav from '../components/admnav';

import Upload from '../components/upload';
import FileList from '../components/FileList';


import { translate } from "../services/translate";

import { vet_medidas, vet_shoes, vet_bust, vet_waist, vet_hips, vet_hair, vet_eyes, vet_category, vet_day, vet_city, vet_nationality } from "../util/medidas";


class Dados extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	uploadedFiles: [],
	    	model: null,
	    	video: '',
	    	videos: [],
	    };
	}

	componentDidMount() {
	    this._downloadModels();
		this._checkLogin();
	}

	handleChangeFileLogo = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.files[0]
		})
	}

	handleSubmitLogo = async e => {
		e.preventDefault();
		const data = new FormData();
		data.append('file', this.state.file);

		api.post(`/models/logo/${this.state.model._id}`, data)
		.then(response => {
			alert('Logo adicionada');
		}).catch((error) => {
			alert('Falhou');
		});

	};

	handleChangeFilePDF = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.files[0]
		})
	}

	handleSubmitPDF = async e => {
		e.preventDefault();
		const data = new FormData();
		data.append('file', this.state.file);

		api.post(`/models/pdf/${this.state.model._id}`, data)
		.then(response => {
			alert('PDF adicionado');
		}).catch((error) => {
			alert('Falhou '+error);
		});

	};

	_checkLogin = async () => {
	    api.get(`/staff/check`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        //Logado
	      }
	      else {
	        //Erro no login
	        logout();
	        this.props.history.push("/login");
	      }
	    }).catch((err) => {
	    	logout();
	        this.props.history.push("/login");
	    });

	}

	_downloadModels = async () => {
	    api.get(`/models/${this.props.match.params.id}`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.models;
	        this.setState({
	          model: dados,
	          	approved: dados.approved,
		    	firstname: dados.firstname,
		    	lastname: dados.lastname,
				phone: dados.phone,
				email: dados.email,
				instagram: dados.instagram,
				birthday_day: dados.birthday_day,
				birthday_month: dados.birthday_month,
				birthday_year: dados.birthday_year,
				about: dados.about,
				city: dados.city,
				state: dados.state,
				skills: dados.skills,
				category: dados.category,
				height: dados.height,
				bust: dados.bust,
				waist: dados.waist,
				hips: dados.hips,
				hair: dados.hair,
				eyes: dados.eyes,
				shoes: dados.shoes,
				address: dados.address,
				imdb: dados.imdb,
				wiki: dados.wiki,
				nationality: dados.nationality,
				schedule: dados.schedule
	        });
	      }
	      else {
	      	this.props.history.push("/404");
	      }
	    }).catch((error) => {
	    	this.props.history.push("/404");
	    });

	}




	handleUpload = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
		});

		uploadedFiles.forEach(this.processUpload);

	};

	updateFile = (id, data) => {
		this.setState({
			uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
				return id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile;
			})
		});
	}

	processUpload = (uploadedFile) => {
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/models/insertphoto/${this.state.model._id}`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				this.updateFile(uploadedFile.id, {
					progress,
				});
			}
		}).then(response => {
			//console.log(response);
			this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});
		}).catch((error) => {
			//console.log(error);
			this.updateFile(uploadedFile.id, {
				error: true
			});
		});
	};

	handleDelete = async id => {
		await api.delete(`/models/photo/${id}`);
		this.setState({
			uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
		});
	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}


	handleSubmit = async e => {
	    e.preventDefault();

	    const form = {
	    	approved: this.state.model.approved,
	    	firstname: this.state.firstname,
	    	lastname: this.state.lastname,
			phone: this.state.phone,
			email: this.state.email,
			instagram: this.state.instagram,
			birthday_day: this.state.birthday_day,
			birthday_month: this.state.birthday_month,
			birthday_year: this.state.birthday_year,
			about: this.state.about,
			city: this.state.city,
			state: this.state.state,
			skills: this.state.skills,
			category: this.state.category,
			height: this.state.height,
			bust: this.state.bust,
			waist: this.state.waist,
			hips: this.state.hips,
			hair: this.state.hair,
			eyes: this.state.eyes,
			shoes: this.state.shoes,
			nationality: this.state.nationality,
			imdb: this.state.imdb,
			address: this.state.address,
			wiki: this.state.wiki,
			schedule: this.state.schedule
	    }

	    try {
	      await api.put(`/staff/model/${this.state.model._id}`, form)
	      .then(response => { 
	        this.setState({
	        	model: response.data.model,
	        })
	        alert("Salvo!");
	      })
	      .catch(error => {
	      	alert('Oops! Ocorreu uma falha!');
	      });
	    } catch (err) {
	      alert('Oops! Ocorreu uma falha.');
	    }

	};

	removerfoto = async (id) => {
		api.delete(`/models/photo/${id}`)
	    .then(res => {
	    	this._downloadModels();
	    }).catch((error) => {
	      alert('Falhou');
	    });
	};

	setcover = async (id) => {
		api.put(`/models/setcover/${this.state.model._id}/${id}`)
	    .then(res => {
	    	alert('Capa alterada');
	    }).catch((error) => {
	      alert('Falhou');
	    });
	};

	handleSubmitVideo = async e => {
	    e.preventDefault();

	    const form = {
	    	url: this.state.url
	    }

	    try {
	      await api.post(`/models/insertvideo/${this.state.model._id}`, form)
	      .then(response => { 
	      	this._downloadModels();
	        alert("Salvo!");
	      })
	      .catch(error => {
	      	alert('Oops! Ocorreu uma falha!');
	      });
	    } catch (err) {
	      alert('Oops! Ocorreu uma falha.');
	    }

	};

	

 	render() {
 		const { uploadedFiles } = this.state;
 		let years = []
	    for(let i=0; i < 70; i++){
	      years.push(<option value={2019-i}>{2019-i}</option>)
	    }
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Admnav page={'modelos'}/>


			 	

				{this.state.model !== null &&
					<section className="centered">
						<div className="form-title">Dados</div>
						<form className="row form" onSubmit={this.handleSubmit}>
							<div className="col-md-6 col-sm-12">
								FIRST NAME<br/>
								<input type="text" name="firstname" value={this.state.firstname} onChange={(e) => this.handleChange(e)}/><br/>
							</div>
							
							<div className="col-md-6 col-sm-12">
								LAST NAME<br/>
								<input type="text" name="lastname" value={this.state.lastname} onChange={(e) => this.handleChange(e)}/><br/>
							</div>

							<div className="col-md-6 col-sm-12">
								PHONE<br/>
				  				<input type="text" name="phone" value={this.state.phone} onChange={(e) => this.handleChange(e)}/><br/>
				  			</div>


				  			<div className="col-md-6 col-sm-12">
				  				E-MAIL<br/>
				  				<input type="text" name="email" value={this.state.email} onChange={(e) => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-12">
				  				ENDEREÇO<br/>
				  				<input type="text" name="address" value={this.state.address} onChange={(e) => this.handleChange(e)}/>
				  			</div>

				  			

				  			<div className="col-md-6 col-sm-12">
				  				CITY<br/>
				  				<input type="text" name="city" value={this.state.city} onChange={e => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-md-6 col-sm-12">
				  				STATE<br/>
				  				<input type="text" name="state" value={this.state.state} onChange={(e) => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-md-12 col-sm-12">
				  				INSTAGRAM<br/>
				  				<input type="text" name="instagram" value={this.state.instagram} onChange={(e) => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-12 form-birthday">
				  				BIRTHDAY
				  			</div>

				  			<div className="col-md-4 col-sm-12 fullwidth">
				  				DAY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_day" className="selectavant" value={this.state.birthday_day} onChange={(e) => this.handleChange(e)}>
										<option value=""></option>
										{vet_day.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-md-4 col-sm-12 fullwidth">
				  				MONTH<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_month" className="selectavant" value={this.state.birthday_month} onChange={(e) => this.handleChange(e)}>
										<option value="">---</option>
										<option value="1">January</option>
										<option value="2">February</option>
										<option value="3">March</option>
										<option value="4">April</option>
										<option value="5">May</option>
										<option value="6">June</option>
										<option value="7">July</option>
										<option value="8">August</option>
										<option value="9">September</option>
										<option value="10">October</option>
										<option value="11">November</option>
										<option value="12">December</option>
									</select>
				  				</div>
				  			</div>

				  			<div className="col-md-4 col-sm-12 fullwidth">
				  				YEAR<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_year" className="selectavant" value={this.state.birthday_year} onChange={(e) => this.handleChange(e)}>
										<option value="">---</option>
										{years}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-12 form-subtitle">
				  				PERSONAL INFORMATION
				  			</div>
				  			
				  			<div className="col-12">
				  				TELL US A LITTLE ABOUT YOURSELF (BIO)<br/>
				  				<textarea name="about" value={this.state.about}  onChange={(e) => this.handleChange(e)}></textarea>
				  			</div>


				  			<div className="col-md-6 col-sm-12">
				  				SKILLS<br/>
				  				<input type="text" name="skills" value={this.state.skills} onChange={(e) => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-md-6 col-sm-12 fullwidth">
				  				NATIONALITY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="nationality" className="selectavant" value={this.state.nationality} onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_nationality.map((m, index) =>
					                        <option value={m.code}>{m.name}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-md-6 col-sm-12 fullwidth">
				  				CATEGORY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="category" className="selectavant" value={this.state.category} onChange={(e) => this.handleChange(e)}>
										<option value=""></option>
										{vet_category.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-md-6 col-sm-12 fullwidth">
				  				HEIGHT<br/>
				  				<div className="divselect fullwidth">
				  					<select name="height" value={this.state.height} className="selectavant" onChange={(e) => this.handleChange(e)}>
										<option value=""></option>
										{vet_medidas.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-md-6 col-sm-12 fullwidth">
				  				BUST<br/>
				  				<div className="divselect fullwidth">
				  					<select name="bust" value={this.state.bust} className="selectavant" onChange={(e) => this.handleChange(e)}>
										<option value=""></option>
										{vet_bust.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-md-6 col-sm-12 fullwidth">
				  				WAIST<br/>
				  				<div className="divselect fullwidth">
				  					<select name="waist"  value={this.state.waist} className="selectavant" onChange={(e) => this.handleChange(e)}>
										<option value=""></option>
										{vet_waist.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>
				  		
				  			<div className="col-md-6 col-sm-12 fullwidth">
				  				HIPS<br/>
				  				<div className="divselect fullwidth">
				  					<select name="hips"  value={this.state.hips} className="selectavant" onChange={(e) => this.handleChange(e)}>
										<option value=""></option>
										{vet_hips.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-md-6 col-sm-12 fullwidth">
				  				HAIR<br/>
				  				<div className="divselect fullwidth">
				  					<select name="hair" value={this.state.hair} className="selectavant" onChange={(e) => this.handleChange(e)}>
										<option value=""></option>
										{vet_hair.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-md-6 col-sm-12 fullwidth">
				  				EYES<br/>
				  				<div className="divselect fullwidth">
				  					<select name="eyes" value={this.state.eyes} className="selectavant" onChange={(e) => this.handleChange(e)}>
										<option value=""></option>
										{vet_eyes.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>


				  			<div className="col-md-6 col-sm-12 fullwidth">
				  				SHOES<br/>
				  				<div className="divselect fullwidth">
				  					<select value={this.state.shoes} name="shoes" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_shoes.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-12">
				  				IMDB LINK<br/>
				  				<input type="text" name="imdb" value={this.state.imdb} onChange={(e) => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-12">
				  				WIKIPEDIA LINK<br/>
				  				<input type="text" name="wiki" value={this.state.wiki} onChange={(e) => this.handleChange(e)}/>
				  			</div>

				  			{(this.state.category === 'HAIR STYLES' || this.state.category === 'MAKE-UP') &&
				  				<div className="col-12">
					  				INFORMAÇÕES DE AGENDA<br/>
					  				<textarea name="schedule" value={this.state.schedule}  onChange={(e) => this.handleChange(e)}></textarea>
					  			</div>
				  			}
				  			

							<div className="col-12">
								<input className="sendbtn" type="submit" value="Alterar dados"/>
							</div>


						</form>
			
						<div className="form-title">Fotos</div>

						<div className="row d-flex justify-content-start">
							{this.state.model.images.map((i, index) =>
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={i.url} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={i.url} /><br/>
						            	</a>
						            	<div>
						            		<button onClick={() => {this.setcover(i._id)}}>Tornar capa</button>
						            		<button onClick={() => {this.removerfoto(i._id)}}>Remover</button>
						            	</div>
						          	</div>
						        </div>
							)}
						</div>

						

						{( (this.state.model.photo1 !== null) && (this.state.model.photo1 !== undefined) && (this.state.model.photo2 !== null) && (this.state.model.photo2 !== undefined) && (this.state.model.photo3 !== null) && (this.state.model.photo3 !== undefined) && (this.state.model.photo4 !== null) && (this.state.model.photo4 !== undefined) ) &&
							<div className="form-title">Fotos de inscrição</div>
						}

						<div className="row d-flex justify-content-start">

							{(this.state.model.photo1 !== null) && (this.state.model.photo1 !== undefined) &&
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={this.state.model.photo1} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={this.state.model.photo1} /><br/>
						            	</a>
						          	</div>
						        </div>
							}

							{(this.state.model.photo2 !== null) && (this.state.model.photo2 !== undefined) &&
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={this.state.model.photo2} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={this.state.model.photo2} /><br/>
						            	</a>
						          	</div>
						        </div>
							}

							{(this.state.model.photo3 !== null) && (this.state.model.photo3 !== undefined) &&
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={this.state.model.photo3} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={this.state.model.photo3} /><br/>
						            	</a>
						          	</div>
						        </div>
							}

							{(this.state.model.photo4 !== null) && (this.state.model.photo4 !== undefined) &&
								<div className="col-md-auto nodecorated">
						        	<div className="cardmodel">
						          		<a href={this.state.model.photo4} target="_blank" className="nodecorated">
						            		<img className="d_fotos" height="250" width="250" src={this.state.model.photo4} /><br/>
						            	</a>
						          	</div>
						        </div>
							}

							
						</div>

						<div className="form-title">Adicionar fotos</div>
						<p className="tips">
							Arraste ou clique para adicionar novas fotos
						</p>
						<Upload onUpload={this.handleUpload}/>
						{ !!uploadedFiles.length && (
							<FileList files={uploadedFiles} onDelete={this.handleDelete}/>
						)}
						<br/>







						<div className="form-title">Vídeos</div>
						<div className="row">
							{this.state.model.videos.map((i, index) =>
								<div className="col-12">
									{i.url}
								</div>
							)}
						</div>


						<div className="form-title">Adicionar Vídeos</div>
						<form className="row form" onSubmit={this.handleSubmitVideo}>
							<div className="col-12">
								LINK YOUTUBE<br/>
								<input type="text" name="url" value={this.state.url} onChange={(e) => this.handleChange(e)}/><br/>
							</div>

							<div className="col-12">
								<input className="sendbtn" type="submit" value="Adicionar vídeo"/>
							</div>
						</form>

						<div className="form-title">Adicionar Logo</div>
						<form className="row form" onSubmit={this.handleSubmitLogo}>
							<div className="col-md-12 col-sm-12">
				  				Logo<br/>
								<input type="file" name="file" id="file" onChange={e => this.handleChangeFileLogo(e)}/>  				
				  			</div>

							<div className="col-12">
								<input className="sendbtn" type="submit" value="SALVAR"/>
							</div>

						</form>


						<div className="form-title">Adicionar PDF</div>
						<form className="row form" onSubmit={this.handleSubmitPDF}>
							<div className="col-md-12 col-sm-12">
				  				PDF<br/>
								<input type="file" name="file" id="file" onChange={e => this.handleChangeFilePDF(e)}/>  				
				  			</div>

							<div className="col-12">
								<input className="sendbtn" type="submit" value="SALVAR"/>
							</div>

						</form>





					</section>
				}




			 </div>
			</div>
	    );
	}
}

export default withRouter(Dados);
