import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import '../css/bootstrap-grid.css';
import '../css/login.css';

import logo from '../images/logo.png';

import api from "../services/api";
import { login, logout  } from "../services/auth";

var crypto = require('crypto'),
    algorithm = 'aes-256-ctr',
    password = 'homile_simulacao_emails';


function decrypt(text){
  var decipher = crypto.createDecipher(algorithm,password)
  var dec = decipher.update(text,'hex','utf8')
  dec += decipher.final('utf8');
  return dec;
}


class Login extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	email: '',
		    password: '',
		    error: '',
	    };
	}

	componentDidMount() {
	    
	}

	handleLogin = async e => {
		e.preventDefault();
		const { email, password } = this.state;
		if (!email || !password) {
	      alert("Preencha e-mail e senha para continuar!");
	    } else {
	      try {
	        await api.post('/auth/login', {
	          email, password
	        })
	        .then(response => { 
	        	login(response.data.token, response.data.staff.email, response.data.staff.name);
	        	this.props.history.push("/adm");
	        })
	        .catch(error => {
	        	console.log(error);
	            alert('Falha no login!');
	        });
	      } catch (err) {
	        alert('Falha no login.');
	      }
	    }
	};


 	render() {
	    return (
	    	<div className="">

			 	<section className="modelcontainer">
			 		<div className="centeredlogin">
			 			<form className="formlogin" onSubmit={this.handleLogin}>
			 				<img className="logologin noselect" href="index.html" src={logo} alt="Logo"/>
				 			<label for="inputEmail" className="">E-mail</label>
				 			<input onChange={e => this.setState({ email: e.target.value })} type="email" id="inputEmail" className="" placeholder="E-mail" required autofocus/>

				 			<label for="inputPassword" className="">Senha</label>
				 			<input onChange={e => this.setState({ password: e.target.value })} type="password" id="inputPassword" className="" placeholder="Senha" required/>

				 			<button className="loginbtn" type="submit">Entrar</button>
						</form>
			 		</div>
				</section>

			</div>
	    );
	}
}

export default withRouter(Login);