import React, { Component } from 'react';
import ReactCountryFlag from "react-country-flag";
import '../css/style.css';


class cardmodel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }



  render() {
    return (
      <>
        <a className="col-md-auto nodecorated" href={`/model/${this.props.data._id}`}>
          <div className="cardmodel">
            <img className="cover" height="250" width="190" src={this.props.data.hasOwnProperty('cover') ? this.props.data.cover.url : ''} /><br/>
            {(this.props.data.hasOwnProperty('nationality') && this.props.data.nationality !== '' ) &&
              <ReactCountryFlag countryCode={this.props.data.nationality} svg style={{marginRight: 10, marginTop: -4}}/>
            }
            {`${this.props.data.firstname.toUpperCase()} ${this.props.data.lastname.toUpperCase()}`}
          </div>
        </a>
      </>
    );
  }
}




export default cardmodel;