import React from 'react';

import home from './views/home';
import models from './views/models';
import pets from './views/pets';
import model from './views/model';
import pet from './views/pet';
import getscouted from './views/getscouted';
import about from './views/about';
import contact from './views/contact';
import projects from './views/projects';
import workshop from './views/workshop';
import faq from './views/faq';
import error404 from './views/error404';
import hire from './views/hire';
import schedule from './views/schedule';
import getscoutedpet from './views/getscouted_pet';
import policy from './views/policy';
import model2 from './views/model2';
import model3 from './views/model3';
import model4 from './views/model4';

import rent from './views/rent';

import getscouted2 from './views/getscouted2';


import inicio from './admviews/inicio';
import login from './admviews/login';
import destaques from './admviews/destaques';
import modelos from './admviews/modelos';
import dados from './admviews/dados';
import dadospet from './admviews/dadospet';
import cadastrar from './admviews/cadastrar';
import comentarios from './admviews/comentarios';
import cidades from './admviews/cidades';
import adicionamodelo from './admviews/adicionamodelo';

import rv1 from './views/RVS/annabelle';
import rv2 from './views/RVS/bettylou';
import rv3 from './views/RVS/retroclassic';
import rv4 from './views/RVS/water';
import rvabout from './views/RVS/about';
import clients from './views/RVS/clients';

import { isAuthenticated } from "./services/auth";



import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={home} />
      <Route exact path="/women" component={models} />
      <Route exact path="/models/:category/:city" component={models} />
      <Route exact path="/models/:type/:category/:city" component={models} />
      <Route exact path="/pets/:category/:city" component={pets} />
      <Route exact path="/model/:id" component={model4} />
      <Route exact path="/pet/:id" component={pet} />
      <Route exact path="/about" component={about} />
      <Route exact path="/contactpage" component={contact} />
      <Route exact path="/projects" component={projects} />
      <Route exact path="/workshop" component={workshop} />
      <Route exact path="/getscouted" component={getscouted2} />
      <Route exact path="/getscoutedpet" component={getscoutedpet} />
      <Route exact path="/faq/:id" component={faq} />
      <Route exact path="/faq" component={faq} />
      <Route exact path="/hire/:type" component={hire} />
      <Route exact path="/hire/:type/:id" component={hire} />
      <Route exact path="/schedule/:id" component={schedule} />
      <Route exact path="/schedule" component={schedule} />
      <Route exact path="/policy" component={policy} />

      <Route exact path="/rent/:id" component={rent} />
      <Route exact path="/rent" component={rent} />
      

      <Route exact path="/rv/1" component={rv1} />
      <Route exact path="/rv/2" component={rv2} />
      <Route exact path="/rv/3" component={rv3} />
      <Route exact path="/rv/4" component={rv4} />
      <Route exact path="/rv" component={rvabout} />
      <Route exact path="/rv/clients" component={clients} />
      

      <Route exact path="/getscouted2" component={getscouted} />


      <Route exact path="/login" component={login} />      
      <PrivateRoute exact path="/adm" component={inicio} />
      <PrivateRoute exact path="/adm/destaques" component={destaques} />
      <PrivateRoute exact path="/adm/modelos" component={modelos} />
      <PrivateRoute exact path="/adm/dados/:id" component={dados} />
      <PrivateRoute exact path="/adm/dadospet/:id" component={dadospet} />
      <PrivateRoute exact path="/adm/cadastrar" component={cadastrar} />
      <PrivateRoute exact path="/adm/comentarios" component={comentarios} />
      <PrivateRoute exact path="/adm/cidades" component={cidades} />
      <PrivateRoute exact path="/adm/cidades/:id" component={adicionamodelo} />
      
      <Route exact path="/404" component={error404} />
      
      <Route exact path="*" component={error404} />
    </Switch>
  </BrowserRouter>
);


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    exact
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

export default Routes;