import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/modelos.css';
import '../css/getscouted.css';



import api from "../services/api";
import { login, logout, isAuthenticated, getToken } from "../services/auth";

import Admnav from '../components/admnav';


class modelos extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      modelos: [],
	      pets: []
	    };
	    //this.handleChangeFile = this.handleChangeFile.bind(this);
	}

	componentDidMount() {
	    this._getModelos();
	    this._getPets();
	    this._checkLogin();
	}


	_getModelos = async () => {
	    api.get(`/models`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.models;
	        this.setState({
	          modelos: dados,
	        });
	      }
	      else {
	        /*this.setState({
	          redirect: true,
	        });*/
	      }
	    }).catch(() => {
	      /*this.setState({
	        redirect: true,
	      });*/
	    });

	  }

	  _getPets = async () => {
	    api.get(`/pets`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.pets;
	        this.setState({
	          pets: dados,
	        });
	      }
	      else {
	        /*this.setState({
	          redirect: true,
	        });*/
	      }
	    }).catch(() => {
	      /*this.setState({
	        redirect: true,
	      });*/
	    });

	  }

	  _checkLogin = async () => {
	    api.get(`/staff/check`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        //Logado
	      }
	      else {
	        //Erro no login
	        logout();
	        this.props.history.push("/login");
	      }
	    }).catch((err) => {
	    	logout();
	        this.props.history.push("/login");
	    });

	  }

	

	remover = async (id) => {
		api.delete(`/staff/${id}`)
	    .then(res => {
	    	this._getModelos();
	    }).catch((error) => {
	      alert('Falhou');
	      console.log(error);
	    });
	};

	aprovar = async (id) => {
		api.put(`/staff/approve/${id}`)
	    .then(res => {
	    	this._getModelos();
	    }).catch((error) => {
	      alert('Falhou');
	    });
	};



	removerpet = async (id) => {
		api.delete(`/staffpet/${id}`)
	    .then(res => {
	    	this._getPets();
	    }).catch((error) => {
	      alert('Falhou');
	      console.log(error);
	    });
	};

	aprovarpet = async (id) => {
		api.put(`/staffpet/approve/${id}`)
	    .then(res => {
	    	this._getPets();
	    }).catch((error) => {
	      alert('Falhou');
	    });
	};


 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Admnav page={'modelos'}/>

			 	<section className="modelcontainer">

			 		<div className="form-title">MODELOS</div>

			 		{this.state.modelos.map((m, index) =>
			 			<div className={m.approved ? "m_boxdestaques" : "m_boxnovomodelo"}>
			 				<div className="m_capadestaque">
			 					{m.hasOwnProperty('cover') &&
			 						<img className="m_destaquefoto" src={m.cover.url}  />
			 					}
			 				</div>
			 				<div className="m_destaquetxt">
			 					{m.firstname} {m.lastname}<br/>
			 					{m.category}<br/>
			 					{m.city}<br/>
			 					{m.phone}<br/>
			 					{m.email}<br/>


			 				</div>
			 				<div className="m_destaqueremove">
			 					<button className="m_destaqueremovebtn" onClick={() => {this.remover(m._id)}}>Remover</button>

			 					{!m.approved &&
			 						<button className="m_btns" onClick={() => {this.aprovar(m._id)}}>Aprovar</button>
			 					}
			 					<button className="m_btns" onClick={() => {this.props.history.push(`/adm/dados/${m._id}`);} }>Ver dados</button>
			 				</div>
			 			</div>
			 		)}


			 		{/*<div className="form-title">PETS</div>*/}

			 		{/*this.state.pets.map((m, index) =>
			 			<div className={m.approved ? "m_boxdestaques" : "m_boxnovomodelo"}>
			 				<div className="m_capadestaque">
			 					{m.hasOwnProperty('cover') &&
			 						<img className="m_destaquefoto" src={m.cover.url}  />
			 					}
			 				</div>
			 				<div className="m_destaquetxt">
			 					{m.petname} - {m.firstname} {m.lastname}<br/>
			 					{m.category}<br/>
			 					{m.city}<br/>
			 					{m.phone}<br/>
			 					{m.email}<br/>

			 				</div>
			 				<div className="m_destaqueremove">
			 					<button className="m_destaqueremovebtn" onClick={() => {this.removerpet(m._id)}}>Remover</button>

			 					{!m.approved &&
			 						<button className="m_btns" onClick={() => {this.aprovarpet(m._id)}}>Aprovar</button>
			 					}
			 					<button className="m_btns" onClick={() => {this.props.history.push(`/adm/dadospet/${m._id}`);} }>Ver dados</button>
			 				</div>
			 			</div>
			 		)*/}


				
				</section>

			 </div>
			</div>
	    );
	}
}

export default withRouter(modelos);