import React, { Component } from 'react';

import '../css/style.css';
import logo from '../images/logo.png';


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  
  }



  render() {
    return (
      <>
        <header>
          <img className="logo noselect" href="index.html" src={logo} alt="Logo"/>
          <div className="menu-toggle"></div>
          <nav>
            <ul>
              <li><a href="/adm" className={this.props.page === 'inicio' ? 'active' : ''}>INÍCIO</a></li>
              <li><a href="/adm/destaques" className={this.props.page === 'destaques' ? 'active' : ''}>DESTAQUES</a></li>
              <li><a href="/adm/comentarios" className={this.props.page === 'comentarios' ? 'active' : ''}>COMENTÁRIOS</a></li>
              <li><a href="/adm/cadastrar" className={this.props.page === 'cadastrar' ? 'active' : ''}>CADASTRAR</a></li>
              <li><a href="/adm/modelos" className={this.props.page === 'modelos' ? 'active' : ''}>MODELOS</a></li>
              <li><a href="/adm/cidades" className={this.props.page === 'cidades' ? 'active' : ''}>CIDADES</a></li>
              <li><a href="/">VER SITE</a></li>
            </ul>
          </nav>
          <div className="clearfix"></div>
            
        </header>
      </>
    );
  }
}




export default Navbar;