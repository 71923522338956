import React, { Component } from 'react';

import '../css/style.css';
import logo from '../images/logo.png';

import { translate, changeLanguage } from "../services/translate";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }



  render() {
    return (
      <>
        <footer className="footerfix">
			<div className="row">
				<div className="col-3">
					<div className="title">
						CONTACT
					</div>
					218 East 27th Street Suite 10  New York, NY 10016  <br/>
					T: 212.920.6110<br/>
					Email: cast@avantgardetalents.com  <br/>
				</div>
				<div className="col-2">
					<div className="title">
						ANOTHER LANGUAGE?
					</div>
					<a href="#" onClick={() => changeLanguage('en')} >English</a><br/>
					<a href="#" onClick={() => changeLanguage('pt')} >Português</a><br/>
					<a href="#" onClick={() => changeLanguage('it')} >italiano</a><br/>
					<a href="#" onClick={() => changeLanguage('es')} >espanol</a><br/>
				</div>
				<div className="col-2">
					<div className="title">
						CITIES
					</div>
					<a className="nodecorated" href="/models/0/ALL/MIAMI" >MIAMI</a><br/>
					<a className="nodecorated" href="/models/0/ALL/NEW YORK" >NEW YORK</a><br/>
					<a className="nodecorated" href="/models/0/ALL/LOS ANGELE" >LOS ANGELES</a><br/>
					<a className="nodecorated" href="/models/0/ALL/MILAN" >MILAN</a><br/>
				</div>
				<div className="col-5 footersocialbox noselect">
					<div>
						<a href="https://twitter.com/avantgardetale1"><i className="fa fa-twitter socialicons" aria-hidden="true"></i></a>
						<a href="https://www.facebook.com/avantgarde.talents.5"><i className="fa fa-facebook socialicons" aria-hidden="true"></i></a>
						<a href="http://instagram.com/avantgardetalents"><i className="fa fa-instagram socialicons" aria-hidden="true"></i></a>
						{/*<a href="#"><i className="fa fa-youtube socialicons" aria-hidden="true"></i></a>*/}
					</div>
					<br/>
					<a className="nodecorated faqlink" href="/faq/all" >Any questions?</a><br/>
					
				</div>
			</div>
		</footer>
      </>
    );
  }
}




export default Footer;












