import React, {Component} from 'react';


import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';


import { uniqueId } from 'loadsh';
import filesize from 'filesize';


import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/getscouted.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';


import { translate } from "../services/translate";

import { vet_show, vet_models } from "../util/medidas";


import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class models extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	type: '',
	    	models: null,
	    	artist: '',
	    	videos: null,
	    	startDate: new Date(),
	    };
	}

	componentDidMount() {
		this.setState({
			type: this.props.match.params.type
		});
	    this.pageView();

	    if (this.props.match.params.id !== null || this.props.match.params.id !== '') {
	    	this._downloadModels();
	    }
	}

	/*onChangeDate = (jsDate, dateString) => {
	  // ...
	}*/

	

	_downloadModels = async () => {
	    api.get(`/models/${this.props.match.params.id}`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.models;
	        this.setState({
	          models: dados,
	          artist: `${dados.firstname} ${dados.lastname}`,
	          videos: dados.videos,
	        });
	        this.pageView();
	      }
	      else {
	        //this.props.history.push("/404");
	      
	      }
	    }).catch((error) => {
	      //this.props.history.push("/404");
	    });

	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Solicitar orçamento' });
	};


	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	};

	//onChangeDate = date => this.setState({ date });




	handleSubmit = async e => {
	    e.preventDefault();

	    const form = {
	    	name: this.state.name,
	    	email: this.state.email,
			tel: this.state.tel,
			city: this.state.city,
			state: this.state.state,
			company: this.state.company,
			artist: this.state.artist,
			date: this.state.startDate,
			type: this.state.type,
			audience: this.state.audience,
			jobdescription: this.state.jobdescription
	    }

	    try {
	      await api.post('/contact/registro', form)
	      .then(response => { 
	        //cadastrou!
	        alert('Request sent successfully!');
			this.props.history.push("/");
	      })
	      .catch(error => { 
	       	alert('Oops! Something went wrong, try again.');
	      });

	    } catch (err) {
	      alert('Oops! Something went wrong, try again.');
	    }

	};


	handleChangeDate = date => {
    	this.setState({
    	startDate: date
    	});
  	};


 	render() {
 		const { uploadedFiles } = this.state;
 		let years = []
	    for(let i=0; i < 70; i++){
	      years.push(<option value={2019-i}>{2019-i}</option>)
	    }
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={''}/>


				<section className="centered">


					<div className="row">
						<div className="col-6">
							<div className="form-title">
								{this.state.type === 'show' &&
				  					<>HIRE ARTISTS<br/></>
				  				}
				  				{this.state.type === 'models' &&
				  					<>HIRE MODELS<br/></>
				  				}
				  				{this.state.type === 'actor' &&
				  					<>HIRE ACTOR<br/></>
				  				}
							</div>
							<div className="row texto">
								<div className="col-12 hiresc">

									{this.state.type === 'actor' &&
				  						<>
				  							<p>
				  								Hiring {this.state.artist} For Corporate Events, Private Parties And Public Concerts Is Easy With Avant Garde Talents. To book {this.state.artist} or any other A-List entertainer for your corporate event, private party, fundraiser or public event worldwide.
												Start by filling out our no-obligation Avant Garde Talents form to check pricing and availability for {this.state.artist} or whoever else you may want.

												Want to talk about your event now? Give us a call at 1.212.920.6110 and one of our booking agents will be happy to help you immediately.
												Monday - Friday 9:00 AM - 7:00 PM EST
				  							</p>

				  						<br/></>
					  				}
					  				{this.state.type === 'models' &&
					  					<>
					  						<p className="">
												Hire {this.state.artist} for the campaign, PhotoShoot Is Easy With Avant Garde talents make it easy to book {this.state.artist} or any other talents for campaigns, fundraising or public events around the world.
												Start filling out our Avant Garde talents with no obligation to check prices and availability at {this.state.artist} or whoever else you want.
											</p>
											<p>
												Do you want to talk about your event now? Call: 1.212.920.6110 and one of our booking agents will be happy to assist you immediately.
												Monday - Friday 9:00 - 19:00 EST
					  						</p>
					  						<br/>
					  					</>
					  				}
					  				{this.state.type === 'show' &&
					  					<>
					  						<p className="">
												Hire {this.state.artist} for the campaign, PhotoShoot Is Easy With Avant Garde talents make it easy to book {this.state.artist} or any other talents for campaigns, fundraising or public events around the world.
												Start filling out our Avant Garde talents with no obligation to check prices and availability at {this.state.artist} or whoever else you want.
											</p>
											<p>
												Do you want to talk about your event now? Call: 1.212.920.6110 and one of our booking agents will be happy to assist you immediately.
												Monday - Friday 9:00 - 19:00 EST
					  						</p>
					  						<br/>
					  					</>
					  				}

					  				{this.state.videos !== null &&
										<div style={{paddingTop: 15}}>

											{this.state.videos.map((i, index) =>
												<a href={i.url}><i className="fa fa-youtube iconesmodel" aria-hidden="true"></i></a>
											)}

										</div>
									}
									
								</div>

								

							</div>
						</div>

						<div className="col-6">

							<div className="form-title">CONTACT FORM</div>
							<form className="row form" onSubmit={this.handleSubmit}>
								
								<div className="col-12">
									<p className="hiresc">
										{this.state.type === 'show' &&
						  					<>Fill out the form below to hire artists, providing some details so we can submit a quote.</>
						  				}
						  				{this.state.type === 'models' &&
						  					<>Fill out the form below to hire models, providing some details so we can submit a quote.</>
						  				}
						  				{this.state.type === 'actor' &&
						  					<>Fill out the form below to hire actors, providing some details so we can submit a quote.<br/></>
						  				}
									</p>
								</div>


								<div className="col-12">
									NAME<br/>
									<input type="text" name="name" onChange={e => this.handleChange(e)}/><br/>
								</div>

								<div className="col-12">
					  				E-MAIL<br/>
					  				<input type="text" name="email" onChange={e => this.handleChange(e)}/>
					  			</div>
								
								<div className="col-12">
									PHONE<br/>
					  				<input type="text" name="tel" onChange={e => this.handleChange(e)}/><br/>
					  			</div>

					  			<div className="col-12">
									CITY<br/>
					  				<input type="text" name="city" onChange={e => this.handleChange(e)}/><br/>
					  			</div>

					  			<div className="col-12">
									STATE<br/>
					  				<input type="text" name="state" onChange={e => this.handleChange(e)}/><br/>
					  			</div>


					  			<div className="col-12">
									COMPANY<br/>
									<input type="text" name="company" onChange={e => this.handleChange(e)}/><br/>
								</div>
					  			
					  			<div className="col-12">
					  				{this.state.type === 'show' &&
					  					<>ARTIST(S) YOU WANT TO HIRE<br/></>
					  				}
					  				{this.state.type === 'models' &&
					  					<>MODEL(S) YOU WANT TO HIRE<br/></>
					  				}
					  				{this.state.type === 'actor' &&
					  					<>ACTOR(S) YOU WANT TO HIRE<br/></>
					  				}
					  				<input type="text" name="artist" value={this.state.artist} onChange={e => this.handleChange(e)}/>
					  			</div>

					  			<div className="col-12 fullwidth">
					  				{this.state.type === 'show' &&
					  					<>DATE OF THE EVENT<br/></>
					  				}
					  				{(this.state.type === 'models' || this.state.type === 'actor') &&
					  					<>DATE OF THE JOB<br/></>
					  				}
					  				{/*<input type="text" name="date" onChange={e => this.handleChange(e)}/>*/}
					  				<DatePicker className="pickerdata" selected={this.state.startDate} name="datepicker" onChange={this.handleChangeDate} />
					  			</div>

					  			{(this.state.type === 'models' || this.state.type === 'actor') &&
					  				<div className="col-12 fullwidth">
						  				TYPE OF JOB<br/>
						  				<div className="divselect fullwidth">
						  					<select name="type" className="selectavant" onChange={e => this.handleChange(e)}>
												<option value=""></option>
												{vet_models.map((m, index) =>
							                        <option value={m}>{m}</option>
							                    )}
											</select>
						  				</div>
						  			</div>
					  			}

					  			{this.state.type === 'show' &&
					  				<div className="col-12 fullwidth">
						  				TYPE OF EVENT<br/>
						  				<div className="divselect fullwidth">
						  					<select name="type" className="selectavant" onChange={e => this.handleChange(e)}>
												<option value=""></option>
												{vet_show.map((m, index) =>
							                        <option value={m}>{m}</option>
							                    )}
											</select>
						  				</div>
						  			</div>
					  			}

					  			{this.state.type === 'show' &&
						  			<div className="col-12">
						  				AUDIENCE FORECAST<br/>
						  				<input type="text" name="audience" onChange={e => this.handleChange(e)}/>
						  			</div>
					  			}
					  				  			
					  			<div className="col-12">
					  				{this.state.type === 'show' &&
					  					<>EVENT DESCRIPTION<br/></>
					  				}
					  				{(this.state.type === 'models' || this.state.type === 'actor') &&
					  					<>JOB DESCRIPTION<br/></>
					  				}
					  				<textarea name="jobdescription"  onChange={e => this.handleChange(e)}></textarea>
					  			</div>


								<div className="col-12">
									<input className="sendbtn" type="submit" value="SEND"/>
								</div>


							</form>



						</div>

					</div>



					
			

				</section>




			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(models);






/*
model
	Hire {this.state.artist} for the campaign, PhotoShoot Is Easy With
Avant Garde's talents make it easy to book {this.state.artist} or any other A list for campaigns, fundraising or public events around the world.<br/>
Start filling out our Avant Garde talents with no obligation to check prices and availability at {this.state.artist} or whoever else you want.<br/>
Do you want to talk about your event now? Call: 1.212.920.6110 and one of our booking agents will be happy to assist you immediately.<br/>
Monday - Friday 9:00 - 19:00 EST


//show
Hiring {this.state.artist} For Corporate Events, Private Parties And Public Concerts Is Easy With Booking Entertainment
Avant Garde Talents makes it easy to book {this.state.artist} or any other A-List entertainer for your corporate event, private party, fundraiser or public event worldwide.<br/>
Start by filling out our no-obligation Avant Garde Talents to check pricing and availability for {this.state.artist} or whoever else you may want.<br/>
Want to talk about your event now? Give us a call at: 1.212.920.6110 and one of our booking agents will be happy to help you immediately.<br/>
Monday - Friday 9:00 AM - 7:00 PM EST
											</p>
											<p>
											Wondering how to book {this.state.artist} for Corporate Events, Private Parties and Public Concerts Is Easy With Avant Garde Talents makes it easy to book {this.state.artist} or any other A-List entertainer for your corporate event, private party, fundraiser or public event worldwide. Start by filling out our no-obligation Avant Garde Talents to check pricing and availability for {this.state.artist} or whoever else you may want.<br/> 
											Want to talk about your event now?<br/>
											 Give us a call at: 1.212.920.6110 and one of our booking agents will be happy to help you immediately. <br/>
											Monday - Friday 9:00 AM - 7:00 PM EST




											//actor

											<p>
				  								Hiring {this.state.artist} For Corporate Events, Private Parties And Public Concerts Is Easy With Avant Garde Talents 
Avant Garde Talents makes it easy to book {this.state.artist} or any other A-List entertainer for your corporate event, private party, fundraiser or public event worldwide.<br/>
Start by filling out our no-obligation Avant Garde Talents form to check pricing and availability for {this.state.artist} or whoever else you may want.<br/>
Want to talk about your event now? Give us a call at 1.212.920.6110 and one of our booking agents will be happy to help you immediately.<br/>
Monday - Friday 9:00 AM - 7:00 PM EST

											</p>
											<p>

Wondering how to book {this.state.artist} for your event? Avant Garde Talents is the leading entertainment booking Talents for booking {this.state.artist} for events worldwide. <br/>
Our {this.state.artist} agent will be happy to help you get {this.state.artist} availability and booking fee, or the price and availability for any other A-List entertainer, band, comedian or celebrity you may want for your corporate event, private party, wedding, birthday party or public show.<br/>
At Avant Garde Talents, we put our 20 years in business behind you to help you get the best price for any entertainer, comedian, or celebrity for your event.<br/>
Our team of seasoned industry professionals can also handle every aspect of the entertainment portion of your event to make sure the entertainment runs flawlessly.<br/>

				  							</p>
*/