import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import { uniqueId } from 'loadsh';
import filesize from 'filesize';



import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/getscouted.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import Uploadbox from '../components/uploadbox';

import Upload from '../components/upload';
import FileList from '../components/FileList';


import { translate } from "../services/translate";

import { vet_medidas, vet_shoes, vet_bust, vet_waist, vet_hips, vet_hair, vet_eyes, vet_category, vet_day, vet_city, vet_nationality } from "../util/medidas";


class models extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	registred: false,
	    	uploadedFiles: [],
	    	model: null,
	    	photo1: null,
	    	photo2: null,
	    	photo3: null,
	    	photo4: null,
	    	photo1url: null,
	    	photo2url: null,
	    	photo3url: null,
	    	photo4url: null,
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Get Scouted / Inscrição' });
	};

	handleChangeFile = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.files[0]
		})
	}


	handleUpload = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
		});

		uploadedFiles.forEach(this.processUpload);

	};

	updateFile = (id, data) => {
		this.setState({
			uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
				return id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile;
			})
		});
	}

	processUpload = (uploadedFile) => {
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/models/insertphoto/${this.state.model._id}`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				this.updateFile(uploadedFile.id, {
					progress,
				});
			}
		}).then(response => {
			//console.log(response);
			this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});
		}).catch((error) => {
			//console.log(error);
			this.updateFile(uploadedFile.id, {
				error: true
			});
		});
	};

	handleDelete = async id => {
		await api.delete(`/models/photo/${id}`);
		this.setState({
			uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
		});
	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}

	//----------------

	handleUploadPhoto1 = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			photo1: uploadedFiles[0]
		});

		//uploadedFiles.forEach(this.processUpload);

		const uploadedFile = uploadedFiles[0];
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/models/uploadphoto`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				/*this.updateFile(uploadedFile.id, {
					progress,
				});*/
			}
		}).then(response => {
			//console.log(response);
			/*this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});*/

			this.setState({
				photo1url: response.data.url
			});

		}).catch((error) => {
			//console.log(error);
			/*this.updateFile(uploadedFile.id, {
				error: true
			});*/
		});

	};

	handleUploadPhoto2 = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			photo2: uploadedFiles[0]
		});

		//uploadedFiles.forEach(this.processUpload);

		const uploadedFile = uploadedFiles[0];
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/models/uploadphoto`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				/*this.updateFile(uploadedFile.id, {
					progress,
				});*/
			}
		}).then(response => {
			//console.log(response);
			/*this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});*/

			this.setState({
				photo2url: response.data.url
			});

		}).catch((error) => {
			//console.log(error);
			/*this.updateFile(uploadedFile.id, {
				error: true
			});*/
		});

	};

	handleUploadPhoto3 = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			photo3: uploadedFiles[0]
		});

		//uploadedFiles.forEach(this.processUpload);

		const uploadedFile = uploadedFiles[0];
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/models/uploadphoto`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				/*this.updateFile(uploadedFile.id, {
					progress,
				});*/
			}
		}).then(response => {
			//console.log(response);
			/*this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});*/

			this.setState({
				photo3url: response.data.url
			});

		}).catch((error) => {
			//console.log(error);
			/*this.updateFile(uploadedFile.id, {
				error: true
			});*/
		});

	};

	handleUploadPhoto4 = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			photo4: uploadedFiles[0]
		});

		//uploadedFiles.forEach(this.processUpload);

		const uploadedFile = uploadedFiles[0];
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/models/uploadphoto`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				/*this.updateFile(uploadedFile.id, {
					progress,
				});*/
			}
		}).then(response => {
			//console.log(response);
			/*this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});*/

			this.setState({
				photo4url: response.data.url
			});

		}).catch((error) => {
			//console.log(error);
			/*this.updateFile(uploadedFile.id, {
				error: true
			});*/
		});

	};




	handleSubmit = async e => {
	    e.preventDefault();
	    //alert(this.curriculum.current.files[0].name)
	    //console.log('ini');
	    //console.log(this.state.terms);

	    if (this.state.terms !== 'on') {
	    	alert('You must accept the terms.');
	    	return
	    }

	    

	    if ((this.state.photo1url === null) || (this.state.photo2url === null) || (this.state.photo3url === null) || (this.state.photo4url === null) ){
	    	if (this.state.category === "COMEDY" && this.state.category === "HAIR STYLES" && this.state.category === "MAKE-UP") {
	    		this.setState({
	    			photo1url: '',
	    			photo2url: '',
	    			photo3url: '',
	    			photo4url: '',
	    		});
	    	}
	    	else
	    	{
	    		alert('You need to add all the photos.');
	    		return
	    	}
	    }

	    const form = {
	    	firstname: this.state.firstname,
	    	lastname: this.state.lastname,
			phone: this.state.phone,
			email: this.state.email,
			instagram: this.state.instagram,
			birthday_day: this.state.birthday_day,
			birthday_month: this.state.birthday_month,
			birthday_year: this.state.birthday_year,
			about: this.state.about,
			city: this.state.city,
			state: this.state.state,
			skills: this.state.skills,
			category: this.state.category,
			height: this.state.height,
			bust: this.state.bust,
			waist: this.state.waist,
			hips: this.state.hips,
			hair: this.state.hair,
			eyes: this.state.eyes,
			shoes: this.state.shoes,
			photo1: this.state.photo1url,
			photo2: this.state.photo2url,
			photo3: this.state.photo3url,
			photo4: this.state.photo4url,
			nationality: this.state.nationality,
			imdb: this.state.imdb,
			address: this.state.address,
			wiki: this.state.wiki
	    }

	    try {
	      await api.post('/models/withphotos', form)
	      .then(response => { 
	        //cadastrou!
	        alert('Registration successfully completed!');
			this.props.history.push("/");
	      })
	      .catch(error => {
	        //console.log(error.response);
	        //console.log(error.response.status);
	          /*if (error.response.status === 400) {
	            //const error = error.response.data.error;
	            
	          }
	          else {
	            alert('Oops! Something went wrong, try again.');
	          }*/
	          alert('Oops! Something went wrong, try again.');
	        
	      });

	     //-----



	    } catch (err) {
	      //console.log(err);
	      alert('Oops! Something went wrong, try again.');
	    }

	};





	/*handleSubmit = async e => {
	    e.preventDefault();
	    //alert(this.curriculum.current.files[0].name)
	    //console.log('ini');
	    //console.log(this.state.terms);

	    if (this.state.terms !== 'on') {
	    	alert('You must accept the terms.');
	    	return
	    }

	    const form = {
	    	firstname: this.state.firstname,
	    	lastname: this.state.lastname,
			phone: this.state.phone,
			email: this.state.email,
			instagram: this.state.instagram,
			birthday_day: this.state.birthday_day,
			birthday_month: this.state.birthday_month,
			birthday_year: this.state.birthday_year,
			about: this.state.about,
			city: this.state.city,
			skills: this.state.skills,
			category: this.state.category,
			height: this.state.height,
			bust: this.state.bust,
			waist: this.state.waist,
			hips: this.state.hips,
			hair: this.state.hair,
			eyes: this.state.eyes,
			shoes: this.state.shoes,
	    }

	    try {
	      await api.post('/models', form)
	      .then(response => { 
	        //cadastrou!
	        //this.props.history.push("/dashboard");
	        this.setState({
	        	registred: true,
	        	model: response.data.model,
	        })
	      })
	      .catch(error => {
	        console.log(error.response);
	        //console.log(error.response.status);
	          if (error.response.status === 400) {
	            //const error = error.response.data.error;
	            
	          }
	          else {
	            alert('Oops! Something went wrong, try again.');
	          }
	        
	      });

	     //-----



	    } catch (err) {
	      //console.log(err);
	      alert('Oops! Something went wrong, try again.');
	    }

	};*/

	

 	render() {
 		const { uploadedFiles } = this.state;
 		let years = []
	    for(let i=0; i < 70; i++){
	      years.push(<option value={2019-i}>{2019-i}</option>)
	    }
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'GET SCOUTED'}/>


			 	

				{this.state.registred === false &&
					<section className="centered">
						<div className="form-title" style={{marginLeft: 0}}>CANDIDACY FORM</div>
						<form className="row form" onSubmit={this.handleSubmit}>

							<div className="row col-md-4 col-sm-12 wtotal">
								<div style={{paddingLeft: 15}} className="infotext2">
									Avant Garde Talent's Tips to Getting Scouted:<br/>
 									<br/>
									Professional model scouts are looking for something different than you might expect.<br/> 
									We don’t want to see you in your fanciest outfit with lots of makeup on. <br/>
									We are looking for you at your most natural.<br/>
								</div>
							</div>

							<div className="row col-md-8 col-sm-12">
								<div className="col-6">
									FIRST NAME<br/>
									<input type="text" name="firstname" onChange={e => this.handleChange(e)}/><br/>
								</div>
								
								<div className="col-6">
									LAST NAME<br/>
									<input type="text" name="lastname" onChange={e => this.handleChange(e)}/><br/>
								</div>

								<div className="col-6">
									PHONE<br/>
					  				<input type="text" name="phone" onChange={e => this.handleChange(e)}/><br/>
					  			</div>


					  			<div className="col-6">
					  				E-MAIL<br/>
					  				<input type="text" name="email" onChange={e => this.handleChange(e)}/>
					  			</div>

					  			
					  			<div className="col-12">
					  				ADDRESS<br/>
					  				<input type="text" name="address" onChange={e => this.handleChange(e)}/>
					  			</div>


					  			<div className="col-6">
					  				CITY<br/>
					  				<input type="text" name="city" onChange={e => this.handleChange(e)}/>
					  			</div>

					  			<div className="col-6">
					  				STATE<br/>
					  				<input type="text" name="state" onChange={e => this.handleChange(e)}/>
					  			</div>

					  			<div className="col-12">
					  				INSTAGRAM<br/>
					  				<input type="text" name="instagram" onChange={e => this.handleChange(e)}/>
					  			</div>


					  		</div>

					  		<div className="col-12" style={{paddingLeft: 15}}>
					  			<div className="form-title" style={{fontSize: 15}} >PERSONAL INFORMATION</div>
					  		</div>

					  		<div className="row col-md-4 col-sm-0">

					  		</div>

					  		<div className="row col-md-8 col-sm-12">

					  			

					  			<div className="col-12 form-birthday">
					  				BIRTHDAY
					  			</div>

					  			<div className="col-4 fullwidth">
					  				DAY<br/>
					  				<div className="divselect fullwidth">
					  					<select name="birthday_day" className="selectavant" onChange={e => this.handleChange(e)}>
											<option value=""></option>
											{vet_day.map((m, index) =>
						                        <option value={m}>{m}</option>
						                    )}
										</select>
					  				</div>
					  			</div>

					  			<div className="col-4 fullwidth">
					  				MONTH<br/>
					  				<div className="divselect fullwidth">
					  					<select name="birthday_month" className="selectavant" onChange={e => this.handleChange(e)}>
											<option value=""></option>
											<option value="1">January</option>
											<option value="2">February</option>
											<option value="3">March</option>
											<option value="4">April</option>
											<option value="5">May</option>
											<option value="6">June</option>
											<option value="7">July</option>
											<option value="8">August</option>
											<option value="9">September</option>
											<option value="10">October</option>
											<option value="11">November</option>
											<option value="12">December</option>
										</select>
					  				</div>
					  			</div>

					  			<div className="col-4 fullwidth">
					  				YEAR<br/>
					  				<div className="divselect fullwidth">
					  					<select name="birthday_year" className="selectavant" onChange={e => this.handleChange(e)}>
											<option value=""></option>
											{years}
										</select>
					  				</div>
					  			</div>

					  			{/*
					  			<div className="col-12 form-subtitle">
					  				PERSONAL INFORMATION
					  			</div>
					  			*/}

					  			<div className="col-12">
					  				TELL US A LITTLE ABOUT YOURSELF (BIO)<br/>
					  				<textarea name="about"  onChange={e => this.handleChange(e)}></textarea>
					  			</div>

					  			<div className="col-6 fullwidth">
					  				CATEGORY<br/>
					  				<div className="divselect fullwidth">
					  					<select name="category" className="selectavant" onChange={e => this.handleChange(e)}>
											<option value=""></option>
											{vet_category.map((m, index) =>
						                        <option value={m}>{m}</option>
						                    )}
										</select>
					  				</div>
					  			</div>

					  			<div className="col-6 fullwidth">
					  				NATIONALITY<br/>
					  				<div className="divselect fullwidth">
					  					<select name="nationality" className="selectavant" onChange={e => this.handleChange(e)}>
											<option value=""></option>
											{vet_nationality.map((m, index) =>
						                        <option value={m.code}>{m.name}</option>
						                    )}
										</select>
					  				</div>
					  			</div>



					  			<div className="col-12">
					  				SKILLS<br/>
					  				<input type="text" name="skills" onChange={e => this.handleChange(e)}/>
					  			</div>

					  			{this.state.category === "ACTOR" &&
					  				<div className="col-12">
						  				IMDB LINK<br/>
						  				<input type="text" name="imdb" onChange={e => this.handleChange(e)}/>
						  			</div>

					  			}

					  			{this.state.category === "SINGER" &&
					  				<div className="col-12">
						  				WIKIPEDIA LINK<br/>
						  				<input type="text" name="wiki" onChange={e => this.handleChange(e)}/>
						  			</div>

					  			}
					  			
					  			
					  		</div>

					  		
					  			

					  			

				  			{(this.state.category !== "ARTISTS" && this.state.category !== "COMEDY" && this.state.category !== "HAIR STYLES" && this.state.category !== "MAKE-UP") &&
				  				<>
				  					<div className="col-12" style={{paddingLeft: 15}}>
							  			<div className="form-title" style={{fontSize: 15}} >MEASUREMENTS</div>
							  		</div>

							  		<div className="row col-md-4 col-sm-0">

							  		</div>

							  		<div className="row col-md-8 col-sm-12">
				  				
							  			<div className="col-6 fullwidth">
							  				HEIGHT<br/>
							  				<div className="divselect fullwidth">
							  					<select name="height" className="selectavant" onChange={e => this.handleChange(e)}>
													<option value=""></option>
													{vet_medidas.map((m, index) =>
								                        <option value={m}>{m}</option>
								                    )}
												</select>
							  				</div>
							  			</div>

							  			<div className="col-6 fullwidth">
							  				BUST<br/>
							  				<div className="divselect fullwidth">
							  					<select name="bust" className="selectavant" onChange={e => this.handleChange(e)}>
													<option value=""></option>
													{vet_bust.map((m, index) =>
								                        <option value={m}>{m}</option>
								                    )}
												</select>
							  				</div>
							  			</div>

							  			<div className="col-6 fullwidth">
							  				WAIST<br/>
							  				<div className="divselect fullwidth">
							  					<select name="waist" className="selectavant" onChange={e => this.handleChange(e)}>
													<option value=""></option>
													{vet_waist.map((m, index) =>
								                        <option value={m}>{m}</option>
								                    )}
												</select>
							  				</div>
							  			</div>
							  		
							  			<div className="col-6 fullwidth">
							  				HIPS<br/>
							  				<div className="divselect fullwidth">
							  					<select name="hips" className="selectavant" onChange={e => this.handleChange(e)}>
													<option value=""></option>
													{vet_hips.map((m, index) =>
								                        <option value={m}>{m}</option>
								                    )}
												</select>
							  				</div>
							  			</div>

							  			<div className="col-6 fullwidth">
							  				HAIR<br/>
							  				<div className="divselect fullwidth">
							  					<select name="hair" className="selectavant" onChange={e => this.handleChange(e)}>
													<option value=""></option>
													{vet_hair.map((m, index) =>
								                        <option value={m}>{m}</option>
								                    )}
												</select>
							  				</div>
							  			</div>

							  			<div className="col-6 fullwidth">
							  				EYES<br/>
							  				<div className="divselect fullwidth">
							  					<select name="eyes" className="selectavant" onChange={e => this.handleChange(e)}>
													<option value=""></option>
													{vet_eyes.map((m, index) =>
								                        <option value={m}>{m}</option>
								                    )}
												</select>
							  				</div>
							  			</div>

							  			<div className="col-12 fullwidth">
							  				SHOES<br/>
							  				<div className="divselect fullwidth">
							  					<select name="shoes" className="selectavant" onChange={e => this.handleChange(e)}>
													<option value=""></option>
													{vet_shoes.map((m, index) =>
								                        <option value={m}>{m}</option>
								                    )}
												</select>
							  				</div>
							  			</div>


							  		


						  			

						  			</div>
				  				</>
				  			}

					  			
					  			
							



							

				  			{true &&
				  				<>
				  					<div className="col-12" style={{paddingLeft: 15}}>
							  			<div className="form-title" style={{fontSize: 15}} >PHOTO SUBMISSIONS</div>
							  		</div>

				  					

						  			<div className="row col-md-4 col-sm-12" style={{paddingLeft: 15}}>
						  				<div style={{paddingLeft: 15}}  className="infotext2">
						  					
											Avant Garde Talent’s Tips for Being Scouted: <br/>
											<br/>
											• Have a clean face with absolutely no makeup.<br/>
											• Pull your hair back.<br/>
											• Wear a form fitting outfit like skinny jeans and a tank top. We need to see the shape of your body.<br/>
											<br/>
											Please note the following: <br/>
											<br/>
											• You should never pay to attend a casting.<br/>
											• You should never share photos in the nude or in lingerie.<br/>
											• All Avant Garde Talent representatives correspond via email domains that end with avantgardetalents.com.<br/>
											 If you are contacted by anyone claiming to be a representative of Avant Garde Talent, do not respond without first verifying their identity by promptly calling us directly at 1.212.920.6110 and we’ll be happy to assist you.<br/>
											 <br/>
											FEATURE PHOTO* <br/>
											For all photos, we require that the files are no larger than, jpg, .jpeg, .png, or .bmp formats. <br/>
											DIGITALS.<br/>

						  				</div>
						  			</div>

						  			<div className="row col-md-8 col-sm-12" style={{marginTop: 15}}>

						  				<div className="col-6 boxupload">
							  				{this.state.photo1url === null && (
							  					<Uploadbox className="boxupload" onUpload={this.handleUploadPhoto1} text={"FULL LENGTH"}/>
							  				)}
											{this.state.photo1url !== null && (
												<div className="boxphoto">
								  					<img className="photoinbox" src={this.state.photo1url}/>
								  				</div>
											)}				
							  			</div>

							  			<div className="col-6 boxupload">
							  				{this.state.photo2url === null && (
							  					<Uploadbox className="boxupload" onUpload={this.handleUploadPhoto2} text={"WAIST UP"}/>
							  				)}
											{this.state.photo2url !== null && (
												<div className="boxphoto">
								  					<img className="photoinbox" src={this.state.photo2url}/>
								  				</div>
											)}				
							  			</div>

							  			<div className="col-6 boxupload">
							  				{this.state.photo3url === null && (
							  					<Uploadbox className="boxupload" onUpload={this.handleUploadPhoto3} text={"SHOULDER UP FORWARD"}/>
							  				)}
											{this.state.photo3url !== null && (
												<div className="boxphoto">
								  					<img className="photoinbox" src={this.state.photo3url}/>
								  				</div>
											)}				
							  			</div>

							  			<div className="col-6 boxupload">
							  				{this.state.photo4url === null && (
							  					<Uploadbox className="boxupload" onUpload={this.handleUploadPhoto4} text={"SHOULDER UP 3/4 PROFILE"}/>
							  				)}
											{this.state.photo4url !== null && (
												<div className="boxphoto">
								  					<img className="photoinbox" src={this.state.photo4url}/>
								  				</div>
											)}				
							  			</div>

						  			</div>

						  			
						  			

				  				</>
				  			}

				  			<div className="col-12" style={{paddingLeft: 15, paddingTop: 25}}>
					  			<div className="form-title" style={{fontSize: 15}} ></div>
					  		</div>


				  			<div className="col-12" style={{marginTop: 10}}>
							  	<label className="labelcheck" for="terms"><input className="inputcheck" type="checkbox" id="terms" name="terms"  onChange={e => this.handleChange(e)}/> I have read and accept the <a className="policy" href="/policy">Application Process</a> and Rules and the <a className="policy" href="/policy">Privacy Policy</a>.</label>
								
							</div>

							<div className="col-12 textcenter"  style={{marginTop: 10}}>
								<input className="sendbtn" type="submit" value="SUBMIT"/>
							</div>


						</form>
				

					</section>
				}







				

				{this.state.registred &&
					<section className="centered">
						<div className="form-title">PHOTO SUBMISSIONS</div>
						<p className="tips">
							{translate('photo_tips')}
						</p>
						<Upload onUpload={this.handleUpload}/>
						{ !!uploadedFiles.length && (
							<FileList files={uploadedFiles} onDelete={this.handleDelete}/>
						)}
						<button className="finishbtn" onClick={() => { this.props.history.push("/"); }}>FINISH</button>
						<br/>
					</section>
				}




			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(models);