import React, {Component} from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import api from "../services/api";

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/about.css';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { translate } from "../services/translate";


class workshop extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Workshop' });
	};

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'WORKSHOP'}/>

				
			 	<section className="modelcontainer">

			 		<div className="row name">
						<div className="col-12">
							<div className="form-title">WORKSHOP</div>
						</div>
					</div>

					<div className="row">
					

						<div className="col-12">
							<div className="textbox_a">

								<p>
									

								</p>

							</div>
						</div>

						

					</div>
				
				</section>

				



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(workshop);