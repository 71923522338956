import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import { uniqueId } from 'loadsh';
import filesize from 'filesize';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/getscouted.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import Uploadbox from '../components/uploadbox';

import Upload from '../components/upload';
import FileList from '../components/FileList';

import { translate } from "../services/translate";

import { vet_medidas, vet_bust, vet_waist, vet_hips, vet_hair, vet_eyes, vet_category, vet_day, vet_city, vet_gender, vet_pettype } from "../util/medidas";


class models extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	registred: false,
	    	uploadedFiles: [],
	    	model: null,
	    	photo1: null,
	    	photo2: null,
	    	photo3: null,
	    	photo4: null,
	    	photo1url: null,
	    	photo2url: null,
	    	photo3url: null,
	    	photo4url: null,
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Get Scouted - Pet / Inscrição Pet' });
	};

	handleChangeFile = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.files[0]
		})
	}


	handleUpload = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
		});

		uploadedFiles.forEach(this.processUpload);

	};

	updateFile = (id, data) => {
		this.setState({
			uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
				return id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile;
			})
		});
	}

	processUpload = (uploadedFile) => {
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/pets/insertphoto/${this.state.model._id}`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				this.updateFile(uploadedFile.id, {
					progress,
				});
			}
		}).then(response => {
			//console.log(response);
			this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});
		}).catch((error) => {
			//console.log(error);
			this.updateFile(uploadedFile.id, {
				error: true
			});
		});
	};

	handleDelete = async id => {
		await api.delete(`/pets/photo/${id}`);
		this.setState({
			uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
		});
	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}

	//----------------

	handleUploadPhoto1 = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			photo1: uploadedFiles[0]
		});

		//uploadedFiles.forEach(this.processUpload);

		const uploadedFile = uploadedFiles[0];
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/pets/uploadphoto`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				/*this.updateFile(uploadedFile.id, {
					progress,
				});*/
			}
		}).then(response => {
			//console.log(response);
			/*this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});*/

			this.setState({
				photo1url: response.data.url
			});

		}).catch((error) => {
			//console.log(error);
			/*this.updateFile(uploadedFile.id, {
				error: true
			});*/
		});

	};

	handleUploadPhoto2 = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			photo2: uploadedFiles[0]
		});

		//uploadedFiles.forEach(this.processUpload);

		const uploadedFile = uploadedFiles[0];
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/pets/uploadphoto`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				/*this.updateFile(uploadedFile.id, {
					progress,
				});*/
			}
		}).then(response => {
			//console.log(response);
			/*this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});*/

			this.setState({
				photo2url: response.data.url
			});

		}).catch((error) => {
			//console.log(error);
			/*this.updateFile(uploadedFile.id, {
				error: true
			});*/
		});

	};

	handleUploadPhoto3 = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			photo3: uploadedFiles[0]
		});

		//uploadedFiles.forEach(this.processUpload);

		const uploadedFile = uploadedFiles[0];
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/pets/uploadphoto`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				/*this.updateFile(uploadedFile.id, {
					progress,
				});*/
			}
		}).then(response => {
			//console.log(response);
			/*this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});*/

			this.setState({
				photo3url: response.data.url
			});

		}).catch((error) => {
			//console.log(error);
			/*this.updateFile(uploadedFile.id, {
				error: true
			});*/
		});

	};

	handleUploadPhoto4 = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			photo4: uploadedFiles[0]
		});

		//uploadedFiles.forEach(this.processUpload);

		const uploadedFile = uploadedFiles[0];
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/pets/uploadphoto`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				/*this.updateFile(uploadedFile.id, {
					progress,
				});*/
			}
		}).then(response => {
			//console.log(response);
			/*this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});*/

			this.setState({
				photo4url: response.data.url
			});

		}).catch((error) => {
			//console.log(error);
			/*this.updateFile(uploadedFile.id, {
				error: true
			});*/
		});

	};




	handleSubmit = async e => {
	    e.preventDefault();
	    //alert(this.curriculum.current.files[0].name)
	    //console.log('ini');
	    //console.log(this.state.terms);

	    if (this.state.terms !== 'on') {
	    	alert('You must accept the terms.');
	    	return
	    }

	    if ((this.state.photo1url === null) || (this.state.photo2url === null) || (this.state.photo3url === null) || (this.state.photo4url === null) ){
	    	alert('You need to add all the photos.');
	    	return
	    }

	    const form = {
	    	firstname: this.state.firstname,
	    	lastname: this.state.lastname,
	    	petname: this.state.petname,
			phone: this.state.phone,
			email: this.state.email,
			birthday_day: this.state.birthday_day,
			birthday_month: this.state.birthday_month,
			birthday_year: this.state.birthday_year,
			about: this.state.about,
			city: this.state.city,
			skills: this.state.skills,
			breed: this.state.breed,
			category: this.state.category,
			gender: this.state.gender,
			photo1: this.state.photo1url,
			photo2: this.state.photo2url,
			photo3: this.state.photo3url,
			photo4: this.state.photo4url,
	    }

	    try {
	      await api.post('/pets', form)
	      .then(response => { 
	        //cadastrou!
	        alert('Registration successfully completed!');
			this.props.history.push("/");
	      })
	      .catch(error => {
	          alert('Oops! Something went wrong, try again!');
	          console.log(error);
	        
	      });

	    } catch (err) {
	      //console.log(err);
	      alert('Oops! Something went wrong, try again.');
	      console.log(err);
	    }

	};

 	render() {
 		const { uploadedFiles } = this.state;
 		let years = []
	    for(let i=0; i < 70; i++){
	      years.push(<option value={2019-i}>{2019-i}</option>)
	    }
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'GET SCOUTED PET'}/>


			 	

				{this.state.registred === false &&
					<section className="centered">
						<div className="form-title">CANDIDACY FORM</div>
						<form className="row form" onSubmit={this.handleSubmit}>
							<div className="col-6">
								FIRST NAME<br/>
								<input type="text" name="firstname" onChange={e => this.handleChange(e)}/><br/>
							</div>
							
							<div className="col-6">
								LAST NAME<br/>
								<input type="text" name="lastname" onChange={e => this.handleChange(e)}/><br/>
							</div>

							<div className="col-4">
								PHONE<br/>
				  				<input type="text" name="phone" onChange={e => this.handleChange(e)}/><br/>
				  			</div>


				  			<div className="col-4">
				  				E-MAIL<br/>
				  				<input type="text" name="email" onChange={e => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-4 fullwidth">
				  				CITY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="city" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_city.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>


				  			<div className="col-12 form-birthday">
				  				BIRTHDAY
				  			</div>

				  			<div className="col-4 fullwidth">
				  				DAY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_day" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_day.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-4 fullwidth">
				  				MONTH<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_month" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										<option value="1">January</option>
										<option value="2">February</option>
										<option value="3">March</option>
										<option value="4">April</option>
										<option value="5">May</option>
										<option value="6">June</option>
										<option value="7">July</option>
										<option value="8">August</option>
										<option value="9">September</option>
										<option value="10">October</option>
										<option value="11">November</option>
										<option value="12">December</option>
									</select>
				  				</div>
				  			</div>

				  			<div className="col-4 fullwidth">
				  				YEAR<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_year" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{years}
									</select>
				  				</div>
				  			</div>



				  			<div className="col-12 form-subtitle">
				  				PET INFORMATION
				  			</div>

				  			<div className="col-6 fullwidth">
				  				CATEGORY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="category" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_pettype.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-6">
								BREED<br/>
								<input type="text" name="breed" onChange={e => this.handleChange(e)}/><br/>
							</div>

							<div className="col-6">
								PET NAME<br/>
								<input type="text" name="petname" onChange={e => this.handleChange(e)}/><br/>
							</div>

							<div className="col-6 fullwidth">
				  				GENDER<br/>
				  				<div className="divselect fullwidth">
				  					<select name="category" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_gender.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>
				  			
				  			<div className="col-12">
				  				TELL US A LITTLE ABOUT YOUR PET<br/>
				  				<textarea name="about"  onChange={e => this.handleChange(e)}></textarea>
				  			</div>


				  			<div className="col-12">
				  				SKILLS<br/>
				  				<input type="text" name="skills" onChange={e => this.handleChange(e)}/>
				  			</div>

				  			
				  			

				  			

				  			


				  			<div className="col-12 form-subtitle">
				  				PHOTO SUBMISSIONS
				  			</div>


				  			<div className="col-3 boxupload">
				  				{this.state.photo1url === null && (
				  					<Uploadbox className="boxupload" onUpload={this.handleUploadPhoto1} text={"FEATURED PHOTO"}/>
				  				)}
								{this.state.photo1url !== null && (
									<div className="boxphoto">
					  					<img className="photoinbox" src={this.state.photo1url}/>
					  				</div>
								)}				
				  			</div>

				  			<div className="col-3 boxupload">
				  				{this.state.photo2url === null && (
				  					<Uploadbox className="boxupload" onUpload={this.handleUploadPhoto2} text={"RECENT PHOTO"}/>
				  				)}
								{this.state.photo2url !== null && (
									<div className="boxphoto">
					  					<img className="photoinbox" src={this.state.photo2url}/>
					  				</div>
								)}				
				  			</div>

				  			<div className="col-3 boxupload">
				  				{this.state.photo3url === null && (
				  					<Uploadbox className="boxupload" onUpload={this.handleUploadPhoto3} text={"FULL LENGTH"}/>
				  				)}
								{this.state.photo3url !== null && (
									<div className="boxphoto">
					  					<img className="photoinbox" src={this.state.photo3url}/>
					  				</div>
								)}				
				  			</div>

				  			<div className="col-3 boxupload">
				  				{this.state.photo4url === null && (
				  					<Uploadbox className="boxupload" onUpload={this.handleUploadPhoto4} text={"PROFILE"}/>
				  				)}
								{this.state.photo4url !== null && (
									<div className="boxphoto">
					  					<img className="photoinbox" src={this.state.photo4url}/>
					  				</div>
								)}				
				  			</div>

						  			


				  			<div className="col-12 ">
							  	<label className="labelcheck" for="terms"><input className="inputcheck" type="checkbox" id="terms" name="terms"  onChange={e => this.handleChange(e)}/> I have read and accept the Application Process and Rules and the Privacy Policy.</label>
							</div>

							<div className="col-12">
								<input className="sendbtn" type="submit" value="SUBMIT"/>
							</div>


						</form>
				

					</section>
				}


			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(models);