import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/modelos.css';
import '../css/getscouted.css';



import api from "../services/api";
import { login, logout, isAuthenticated, getToken } from "../services/auth";

import Admnav from '../components/admnav';


class Comentarios extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      comments: [],
	      pets: []
	    };
	    //this.handleChangeFile = this.handleChangeFile.bind(this);
	}

	componentDidMount() {
	    this._get();
	    this._checkLogin();
	}


	_get = async () => {
	    api.get(`/staff/comments`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.comments;
	        this.setState({
	          comments: dados,
	        });
	      }
	      else {
	        /*this.setState({
	          redirect: true,
	        });*/
	      }
	    }).catch(() => {
	      /*this.setState({
	        redirect: true,
	      });*/
	    });

	  }

	  _checkLogin = async () => {
	    api.get(`/staff/check`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        //Logado
	      }
	      else {
	        //Erro no login
	        logout();
	        this.props.history.push("/login");
	      }
	    }).catch((err) => {
	    	logout();
	        this.props.history.push("/login");
	    });

	  }

	remover = async (id) => {
		api.delete(`/staff/comment/${id}`)
	    .then(res => {
	    	this._get();
	    }).catch((error) => {
	      alert('Falhou');
	      console.log(error);
	    });
	};

	aprovar = async (id) => {
		api.put(`/staff/approvecomment/${id}`)
	    .then(res => {
	    	this._get();
	    }).catch((error) => {
	      alert('Falhou');
	    });
	};

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Admnav page={'comentarios'}/>

			 	<section className="modelcontainer">

			 		<div className="form-title">COMENTÁRIOS</div>

			 		{this.state.comments.map((m, index) =>
			 			<div className={m.approved ? "m_boxdestaques" : "m_boxnovomodelo"}>
			 				<div className="m_destaquetxt">
			 					MODELO: {m.model.firstname} {m.model.lastname} - {m.category}<br/>
			 					<br/>
			 					<strong>{m.author}</strong><br/>
			 					{m.comments}<br/>
			 				</div>
			 				<div className="m_destaqueremove">
			 					<button className="m_destaqueremovebtn" onClick={() => {this.remover(m._id)}}>Remover</button>
			 					{!m.approved &&
			 						<button className="m_btns" onClick={() => {this.aprovar(m._id)}}>Aprovar</button>
			 					}
			 				</div>
			 			</div>
			 		)}

				</section>

			 </div>
			</div>
	    );
	}
}

export default withRouter(Comentarios);
