import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';

import '../../css/style.css';
import '../../css/bootstrap-grid.css';
import '../../css/model.css';
import '../../css/rv.css';

import api from "../../services/api";

import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

import { translate } from "../../services/translate";

import img1 from '../../views/RVS/RV Anna Belle/1.jpg';
import img2 from '../../views/RVS/RV Anna Belle/2.png';
import img3 from '../../views/RVS/RV Anna Belle/3.jpg';
import img4 from '../../views/RVS/RV Anna Belle/4.jpg';
import img5 from '../../views/RVS/RV Anna Belle/5.jpg';
import img6 from '../../views/RVS/RV Anna Belle/6.jpg';
import img7 from '../../views/RVS/RV Anna Belle/7.jpg';
import img8 from '../../views/RVS/RV Anna Belle/8.jpg';
import img9 from '../../views/RVS/RV Anna Belle/9.JPG';
import img10 from '../../views/RVS/RV Anna Belle/10.JPG';
import img11 from '../../views/RVS/RV Anna Belle/11.jpg';
import img12 from '../../views/RVS/RV Anna Belle/12.jpg';


class model extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: `RV's - Anna Belle` });
	};

	

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'Anna Belle'}/>

			 	<section className="modelcontainer">

					<div className="row name">
						<div className="col-12" style={{marginBottom: 10}}>
							RV Anna Belle
						</div>
					</div>

					<div className="row">

						<div className="col-md-6 col-sm-12 about" style={{marginTop: 10}}>
							This vehicle is available in all of our locations.<br/>
							- Mobile High speed Internet 5MB upon request<br/>
							- HD TV with Direct TV<br/>
							- 8K Built in Diesel Generator<br/>
							- Central heat and air conditioning<br/>
							- Large fridge for kraft and catering needs<br/>
							- Lounge area with seating for 11<br/>
							- 1 double makeup station with skylight and vanity lighting<br/>
							- 1 single makeup station with vanity lighting<br/>
							- Large dressing room with 15" of built in wardrobe racks<br/>
							- Rear door<br/>
							- Electric Awning<br/>
							- Expresso Machine<br/>
							Height - 12"6<br/>
							Length - 39"<br/>
							Width - 8"<br/>
							Opened - 15"<br/>
						</div>


						<div className="col-md-6 col-sm-12">

							<Carousel autoplay={true} heightMode={'max'} initialSlideHeight={400} 
				 					renderCenterLeftControls={({ previousSlide }) => (
							    		<button onClick={previousSlide} className="btnslider">{'<'}</button>
							  		)}
							  		renderCenterRightControls={({ nextSlide }) => (
							    		<button onClick={nextSlide} className="btnslider">{'>'}</button>
							  		)}

				 				>
							        
							        <div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img1} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img2} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img3} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img4} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img5} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img6} />
                        			</div>


                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img7} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img8} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img9} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img10} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img11} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img12} />
                        			</div>



							</Carousel>
				 			
							
						</div>


						<div className="col-12 measurements about">
							<div className="hvzio"/>
							<button className="btnhire" style={{marginRight: 10, marginTop: 20}} onClick={()=> (this.props.history.push(`/rent/1`))}>RENT ANNA BELLE</button>
						</div>



					</div>


				</section>



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(model);