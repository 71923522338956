import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/model.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { translate } from "../services/translate";


class pet extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      pets: null,
	    };
	}

	componentDidMount() {
	    this._downloadModels();
	}

	pageView = async () => {
		//api.post(`/pageview/model`, { model: this.state.models._id });
	};

	_downloadModels = async () => {
	    api.get(`/pets/${this.props.match.params.id}`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.pets;
	        this.setState({
	          pets: dados,
	        });
	        console.log(res);
	        //this.pageView();
	      }
	      else {
	        this.props.history.push("/404");
	      
	      }
	    }).catch((error) => {
	      this.props.history.push("/404");
	    });

	}

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'PETS'}/>

			 	{this.state.pets === null &&
			 		<section className="modelcontainer">

						<div className="row name">
							<div className="col-12">
								LOADING...
							</div>
						</div>

					</section>
			 	}

			 	{this.state.pets !== null &&
			 		<section className="modelcontainer">

						<div className="row name">
							<div className="col-12">
								{`${this.state.pets.petname.toUpperCase()}`}
							</div>
						</div>



						<div className="row">

							{this.state.pets.images.map((i, index) =>
								<div className={index === 0 ? "col-12" : "col-6"}>
									<div className="photobox">
										<img className="photo" src={i.url}/>
									</div>
								</div>
							)}

							<div className="col-6">
								<div className="photobox">
									<img className="photo" src={this.state.pets.photo1}/>
								</div>
							</div>

							<div className="col-6">
								<div className="photobox">
									<img className="photo" src={this.state.pets.photo2}/>
								</div>
							</div>

							<div className="col-6">
								<div className="photobox">
									<img className="photo" src={this.state.pets.photo3}/>
								</div>
							</div>

							<div className="col-6">
								<div className="photobox">
									<img className="photo" src={this.state.pets.photo4}/>
								</div>
							</div>




						</div>


						<div className="row">
							<div className="col-12 about">
								<p>
									{this.state.pets.about}
								</p>
							</div>

							<div className="col-12 measurements about">
								<p>
									{this.state.pets.skills}
								</p>
							</div>

							
							
						</div>
					
					</section>
			 	}



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(pet);