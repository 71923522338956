import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { login, logout, isAuthenticated, getToken } from "../services/auth";

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/destaques.css';
import '../css/getscouted.css';



import api from "../services/api";
//import { login, logout, isAuthenticated, getToken } from "../services/auth";

import Admnav from '../components/admnav';


class cidades extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      cidades: null,
	      modelos: []
	    };
	}

	componentDidMount() {
	    this._getFeatured();
		this._checkLogin();
	}

	_checkLogin = async () => {
	    api.get(`/staff/check`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        //Logado
	      }
	      else {
	        //Erro no login
	        logout();
	        this.props.history.push("/login");
	      }
	    }).catch((err) => {
	    	logout();
	        this.props.history.push("/login");
	    });

	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}


	_getFeatured = async () => {
	    axios.get(`/city/${this.props.match.params.id}`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.city;
	        this.setState({
	          cidades: dados,
	        });
	      }
	      else {
	        
	      }
	    }).catch(() => {
	      
	    });

	  }



	handleSubmit = async e => {
		e.preventDefault();

		axios.post(`/getmodels/buscar`, { name: this.state.name})
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.models;
	        this.setState({
	          modelos: dados,
	        });
	      }
	      else {
	        
	      }
	    }).catch(() => {
	      
	    });

	};

	add = async (id) => {
		api.post(`/city/${this.props.match.params.id}/${id}`)
	    .then(res => {
	    	this._getFeatured();
	    }).catch((error) => {
	      alert('Falhou');
	    });
	};


 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Admnav page={'cidades'}/>

			 	<section className="modelcontainer">

			 		{this.state.cidades !== null && 
			 			<>
			 				<div className="form-title">{this.state.cidades.name}</div>

						 		{this.state.cidades.models.map((f, index) =>
						 			<div className="boxdestaques">
						 				<div className="capadestaque">
						 					<img className="destaquefoto" src={f.cover.url} />
						 				</div>
						 				<div className="destaquetxt">
						 					{f.firstname} {f.lastname}<br/>
						 					{f.category}<br/>
						 				</div>
						 				
						 			</div>
						 		)}

						 		<div className="form-title">INSERIR MODELO</div>
						 		<form className="row form" onSubmit={this.handleSubmit}>
									<div className="col-md-9 col-sm-12">
										Buscar por nome<br/>
										<input type="text" name="name" value={this.state.name} onChange={e => this.handleChange(e)}/><br/>
									</div>

									<div className="col-md-3 col-sm-12">
										<input style={{marginTop: 15}} className="sendbtn" type="submit" value="Procurar"/>
									</div>

								</form>

								{this.state.modelos.map((f, index) =>
						 			<div className="boxdestaques">
						 				<div className="capadestaque">
						 					<img className="destaquefoto" src={f.cover.url} />
						 				</div>
						 				<div className="destaquetxt">
						 					{f.firstname} {f.lastname}<br/>
						 					{f.category}<br/>
						 				</div>
						 				<div className="destaqueremove">
						 					<button className="destaquebtn" onClick={() => {this.add(f._id)}}>Adicionar em {this.state.cidades.name}</button>
						 				</div>
						 			</div>
						 		)}

			 			</>
			 		}

			 		

				
				</section>

			 </div>
			</div>
	    );
	}
}

export default withRouter(cidades);