import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { login, logout, isAuthenticated, getToken } from "../services/auth";

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/destaques.css';
import '../css/getscouted.css';



import api from "../services/api";
//import { login, logout, isAuthenticated, getToken } from "../services/auth";

import Admnav from '../components/admnav';


class destaques extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      featured: []
	    };
	    //this.handleChangeFile = this.handleChangeFile.bind(this);
	}

	componentDidMount() {
	    this._getFeatured();
		this._checkLogin();
	}

	_checkLogin = async () => {
	    api.get(`/staff/check`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        //Logado
	      }
	      else {
	        //Erro no login
	        logout();
	        this.props.history.push("/login");
	      }
	    }).catch((err) => {
	    	logout();
	        this.props.history.push("/login");
	    });

	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}

	handleChangeFile = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.files[0]
		})
		console.log(e.target.files[0]);
	}

	/*handleChangeFile(selectorFiles: FileList)
    {
        console.log(selectorFiles);
    }*/

	_getFeatured = async () => {
	    axios.get(`/featured`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.featured;
	        this.setState({
	          featured: dados,
	        });
	      }
	      else {
	        /*this.setState({
	          redirect: true,
	        });*/
	      }
	    }).catch(() => {
	      /*this.setState({
	        redirect: true,
	      });*/
	    });

	  }

	handleSubmit = async e => {
		e.preventDefault();
		const data = new FormData();
		data.append('file', this.state.file);
		data.append('link', this.state.link);
		data.append('type', this.state.type);

		api.post(`/featured`, data)
		.then(response => {
			//console.log(response);
			this._getFeatured();
		}).catch((error) => {
			alert('Falhou');
		});

	};

	remover = async (id) => {
		api.delete(`/featured/${id}`)
	    .then(res => {
	    	this._getFeatured();
	    }).catch((error) => {
	      alert('Falhou');
	      console.log(error);
	    });
	};

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Admnav page={'destaques'}/>

			 	<section className="modelcontainer">

			 		<div className="form-title">DESTAQUES</div>

			 		{this.state.featured.map((f, index) =>
			 			<div className="boxdestaques">
			 				<div className="capadestaque">
			 					<img className="destaquefoto" src={f.url} />
			 				</div>
			 				<div className="destaquetxt">
			 					{f.type === '1' &&
			 						<>{'CARROSSEL - '}</>
			 					}

			 					{f.type === '2' &&
			 						<>{'COLUNAS - '}</>
			 					}

			 					{f.link}
			 				</div>
			 				<div className="destaqueremove">
			 					<button className="destaqueremovebtn" onClick={() => {this.remover(f._id)}}>Remover</button>
			 				</div>
			 			</div>
			 		)}

			 		<div className="form-title">INSERIR DESTAQUES</div>
			 		<form className="row form" onSubmit={this.handleSubmit}>
						<div className="col-md-6 col-sm-12">
							LINK<br/>
							<input type="text" name="link" onChange={e => this.handleChange(e)}/><br/>
						</div>

						<div className="col-md-3 col-sm-12 fullwidth">
			  				POSIÇÃO<br/>
			  				<div className="divselect fullwidth">
			  					<select name="type" className="selectavant" onChange={(e) => this.handleChange(e)}>
									<option value="">---</option>
									<option value="1">Carrossel</option>
									<option value="2">Colunas</option>
									
								</select>
			  				</div>
			  			</div>

						<div className="col-md-3 col-sm-12">
			  				IMAGEM<br/>
							<input type="file" name="file" id="file" onChange={e => this.handleChangeFile(e)}/>  				
			  			</div>

						<div className="col-12">
							<input className="sendbtn" type="submit" value="SALVAR"/>
						</div>

					</form>

				
				</section>

			 </div>
			</div>
	    );
	}
}

export default withRouter(destaques);