import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';

import '../../css/style.css';
import '../../css/bootstrap-grid.css';
import '../../css/model.css';
import '../../css/rv.css';

import api from "../../services/api";

import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

import { translate } from "../../services/translate";

import img1 from '../../views/RVS/The Retro Classic/1.jpg';
import img2 from '../../views/RVS/The Retro Classic/2.jpg';
import img3 from '../../views/RVS/The Retro Classic/3.jpg';
import img4 from '../../views/RVS/The Retro Classic/4.jpg';
import img5 from '../../views/RVS/The Retro Classic/5.jpg';
import img6 from '../../views/RVS/The Retro Classic/6.jpg';
import img7 from '../../views/RVS/The Retro Classic/7.jpg';
import img8 from '../../views/RVS/The Retro Classic/8.jpg';
import img9 from '../../views/RVS/The Retro Classic/9.jpg';
import img10 from '../../views/RVS/The Retro Classic/10.jpg';


class model extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: `RV's - The Retro Classic` });
	};

	

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'Retro Classic'}/>

			 	<section className="modelcontainer">

					<div className="row name">
						<div className="col-12" style={{marginBottom: 10}}>
							The Retro Classic
						</div>
					</div>


					<div className="row">

					
						<div className="col-md-6 col-sm-12 about" style={{marginTop: 10}}>
							- Mobile High speed Internet 3G/4G<br/>
							- HD TV with Direct TV<br/>
							- Wireless Digital Media Environment<br/>
							- Built in 8K Diesel Generator<br/>
							- Portable 4K Generator<br/>
							- 3 air conditioning units<br/>
							- Diesel heater<br/>
							- 8" counter with small built in fridge<br/>
							- Front lounge Area with 8" built in wardrobe Rack<br/>
							- Large retro rear dressing room with slide out<br/>
							- Retro rear dressing room with slide out<br/>
							- Retro rear dressing room with 15" built in wardrobe racks<br/>
							- Expresso Machine<br/>
							Height 12"1<br/>
							Length - 38"<br/>
							width - 8"<br/>
							Opened - 13"
						</div>


						<div className="col-md-6 col-sm-12">

							<Carousel autoplay={true} heightMode={'max'} initialSlideHeight={400} 
				 					renderCenterLeftControls={({ previousSlide }) => (
							    		<button onClick={previousSlide} className="btnslider">{'<'}</button>
							  		)}
							  		renderCenterRightControls={({ nextSlide }) => (
							    		<button onClick={nextSlide} className="btnslider">{'>'}</button>
							  		)}

				 				>
							        
							        <div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img1} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img2} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img3} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img4} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img5} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img6} />
                        			</div>


                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img7} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img8} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img9} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img10} />
                        			</div>

                        		


							</Carousel>
				 			
							
						</div>

						<div className="col-12 measurements about">
							<div className="hvzio"/>
							<button className="btnhire" style={{marginRight: 10, marginTop: 20}} onClick={()=> (this.props.history.push(`/rent/3`))}>RENT THE RETRO CLASSIC</button>
						</div>



					</div>


				</section>



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(model);