import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import '../css/style.css';
import '../css/bootstrap-grid.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import Cardmodel from '../components/cardmodel';

import { translate } from "../services/translate";


class models extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      models: [],
	      citys: null
	    };
	}

	componentDidMount() {
	    this._downloadModels();
	    this._downloadCitys();
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: `Models / Modelos : ${this.props.match.params.category} - ${this.props.match.params.city}` });
	};

	_downloadModels = async () => {
	    //axios.get(`/models/approved/${this.props.match.params.category}/${this.props.match.params.city}`)
	    axios.get(`/getmodels/${this.props.match.params.type}/${this.props.match.params.category}/${this.props.match.params.city}`)
	    .then(res => {
	      //console.log(res.status);
	      //console.log(res.data);
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.models;
	        this.setState({
	          models: dados,
	        });
	      }
	      else {
	        /*this.setState({
	          redirect: true,
	        });*/
	      }
	    }).catch(() => {
	      /*this.setState({
	        redirect: true,
	      });*/
	    });

	}




	_downloadCitys = async () => {
	    axios.get(`/city/`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.city;
	        this.setState({
	          citys: dados,
	        });
	      }
	      else {
	        
	      }
	    }).catch(() => {
	      
	    });

	}



 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={this.props.match.params.category}/>


			 	<section className="modelcontainer">

					<div className="menuciteis">
						<ul>
							<li><a href={`/models/${this.props.match.params.type}/${this.props.match.params.category}/ALL`} className={this.props.match.params.city === 'ALL' ? 'active' : ''}>{translate('all')}</a></li>
							{ this.state.citys !== null &&
								<>
									{this.state.citys.map((c, index) =>
				                        <li><a href={`/models/${this.props.match.params.type}/${this.props.match.params.category}/${c.name}`} className={this.props.match.params.city === c.name ? 'active' : ''}>{c.name}</a></li>
				                    )}
			                    </>
							}
						
							
							{/*
							<li><a href={`/models/${this.props.match.params.category}/NEW YORK`} className={this.props.match.params.city === 'NEW YORK' ? 'active' : ''}>NEW YORK</a></li>
							<li><a href={`/models/${this.props.match.params.category}/SÃO PAULO`} className={this.props.match.params.city === 'LOS ANGELES' ? 'active' : ''}>LOS ANGELES</a></li>
							<li><a href={`/models/${this.props.match.params.category}/MILAN`} className={this.props.match.params.city === 'MILAN' ? 'active' : ''}>MILAN</a></li>
							*/}
						</ul>
					</div>
					
					<div className="row d-flex justify-content-start">


						{this.state.models.map((m, index) =>
	                        <Cardmodel data={m}/>
	                    )}


					</div>

				</section>




			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(models);