export const vet_medidas = [
	`173CM / 5' 8"`,
	`174CM / 5' 8½"`,
	`175CM / 5' 9"`,
	`176CM / 5' 9½"`,
	`177CM / 5' 9½"`,
	`178CM / 5' 10"`,
	`179CM / 5' 10½"`,
	`180CM / 5' 11"`,
	`181CM / 5' 11½"`,
	`182CM / 5' 11½"`,
	`183CM / 6' 0"`,
	`184CM / 6' 0½"`,
	`185CM / 6' 1"`,
	`186CM / 6' 1"`,
	`187CM / 6' 1½"`,
	`188CM / 6' 2"`,
	`189CM / 6' 2½"`,
	`190CM / 6' 3"`,
	`191CM / 6' 3"`,
	`192CM / 6' 3½"`,
	`193CM / 6' 4"`,
	`194CM / 6' 4½"`,
	`195CM / 6' 5"`,
	`196CM / 6' 5"`,
	`197CM / 6' 5½"`,
	`198CM / 6' 6"`,
	`199CM / 6' 6½"`,
	`200CM / 6' 6½"`
];

export const vet_bust = [
	`70 cm / 27½"`,
	`71 cm / 28"`,
	`72 cm / 28½"`,
	`73 cm / 28½"`,
	`74 cm / 29"`,
	`75 cm / 29½"`,
	`76 cm / 30"`,
	`77 cm / 30½"`,
	`78 cm / 30½"`,
	`79 cm / 31"`,
	`80 cm / 31½"`,
	`81 cm / 32"`,
	`82 cm / 32½"`,
	`83 cm / 32½"`,
	`84 cm / 33"`,
	`85 cm / 33½"`,
	`86 cm / 34"`,
	`87 cm / 34½"`,
	`88 cm / 34½"`,
	`89 cm / 35"`,
	`90 cm / 35½"`,
	`91 cm / 36"`,
	`92 cm / 36"`,
	`93 cm / 36½"`,
	`94 cm / 37"`,
	`95 cm / 37½"`,
	`96 cm / 38"`,
	`97 cm / 38"`,
	`98 cm / 38½"`,
	`99 cm / 39"`,
	`100 cm / 39½"`,
	`101 cm / 40"`,
	`102 cm / 40"`,
	`103 cm / 40½"`,
	`104 cm / 41"`,
	`105 cm / 41½"`,
	`106 cm / 41½"`,
	`107 cm / 42"`,
	`108 cm / 42½"`,
	`109 cm / 43"`,
	`110 cm / 43½"`,
	`111 cm / 43½"`,
	`112 cm / 44"`,
	`113 cm / 44½"`,
	`114 cm / 45"`,
	`115 cm / 45½"`,
	`116 cm / 45½"`,
	`117 cm / 46"`,
	`118 cm / 46½"`,
	`119 cm / 47"`,
	`120 cm / 47"`,
];

export const vet_shoes = [
    `19 / 4`,
    `20 / 5`,
    `21 / 6`,
    `22 / 7`,
    `23 / 7½`,
    `24 / 8½`,
    `25 / 9`,
    `26 / 10`,
    `27 / 11`,
    `28 / 11½`,
    `29 / 12½`,
    `33 / 4½`,
    `34 / 5½`,
    `35 / 6`,
    `36 / 7`,
    `37 / 7½`,
    `38 / 8`,
    `39 / 8`,
    `39 / 9`,
    `40 / 8½`,
    `40 / 10`,
    `41 / 9`,
    `41 / 10½`,
    `42 / 10½`,
    `43 / 11½`,
    `44 / 12`,
    `45 / 13`,
    `46 / 14`
];



export const vet_waist = [`50 cm / 19½"`,`51 cm / 20"`,`52 cm / 20½"`,`53 cm / 21"`,`54 cm / 21½"`,`55 cm / 21½"`,`56 cm / 22"`,`57 cm / 22½"`,`58 cm / 23"`,`59 cm / 23"`,`60 cm / 23½"`,`61 cm / 24"`,`62 cm / 24½"`,`63 cm / 25"`,`64 cm / 25"`,`65 cm / 25½"`,`66 cm / 26"`,`67 cm / 26½"`,`68 cm / 27"`,`69 cm / 27"`,`70 cm / 27½"`,`71 cm / 28"`,`72 cm / 28½"`,`73 cm / 28½"`,`74 cm / 29"`,`75 cm / 29½"`,`76 cm / 30"`,`77 cm / 30½"`,`78 cm / 30½"`,`79 cm / 31"`,`80 cm / 31½"`,`81 cm / 32"`,`82 cm / 32½"`,`83 cm / 32½"`,`84 cm / 33"`,`85 cm / 33½"`,`86 cm / 34"`,`87 cm / 34½"`,`88 cm / 34½"`,`89 cm / 35"`,`90 cm / 35½"`,`91 cm / 36"`,`92 cm / 36"`,`93 cm / 36½"`,`94 cm / 37"`,`95 cm / 37½"`,`96 cm / 38"`,`97 cm / 38"`,`98 cm / 38½"`,`99 cm / 39"`,`100 cm / 39½"`,`101 cm / 40"`,`102 cm / 40"`,`103 cm / 40½"`,`104 cm / 41"`,`105 cm / 41½"`,`106 cm / 41½"`,`107 cm / 42"`,`108 cm / 42½"`,`109 cm / 43"`,`110 cm / 43½"`,`111 cm / 43½"`,`112 cm / 44"`,`113 cm / 44½"`,`114 cm / 45"`,`115 cm / 45½"`,`116 cm / 45½"`,`117 cm / 46"`,`118 cm / 46½"`,`119 cm / 47"`,`120 cm / 47`];
export const vet_hips = [`70 cm / 27½"`,`71 cm / 28"`,`72 cm / 28½"`,`73 cm / 28½"`,`74 cm / 29"`,`75 cm / 29½"`,`76 cm / 30"`,`77 cm / 30½"`,`78 cm / 30½"`,`79 cm / 31"`,`80 cm / 31½"`,`81 cm / 32"`,`82 cm / 32½"`,`83 cm / 32½"`,`84 cm / 33"`,`85 cm / 33½"`,`86 cm / 34"`,`87 cm / 34½"`,`88 cm / 34½"`,`89 cm / 35"`,`90 cm / 35½"`,`91 cm / 36"`,`92 cm / 36"`,`93 cm / 36½"`,`94 cm / 37"`,`95 cm / 37½"`,`96 cm / 38"`,`97 cm / 38"`,`98 cm / 38½"`,`99 cm / 39"`,`100 cm / 39½"`,`101 cm / 40"`,`102 cm / 40"`,`103 cm / 40½"`,`104 cm / 41"`,`105 cm / 41½"`,`106 cm / 41½"`,`1107 cm / 42"`,`108 cm / 42½"`,`109 cm / 43"`,`110 cm / 43½"`,`111 cm / 43½"`,`112 cm / 44"`,`113 cm / 44½"`,`114 cm / 45"`,`115 cm / 45½"`,`116 cm / 45½"`,`117 cm / 46"`,`118 cm / 46½"`,`119 cm / 47"`,`120 cm / 47''`];
export const vet_hair = [`Auburn`,`Blonde`,`Light blonde`,`Dark blonde`,`Platinum blonde`,`Brown`,`Light brown`,`Dark brown`,`Black`,`Red`,`White`,`Bald`,`Grey`];
export const vet_eyes = [`Blue`,`Blue green`,`Blue grey`,`Grey`,`Brown`,`Black`,`Hazel`,`Green`,`Green grey`,`Green brown`];

export const vet_category = ['MEN', 'WOMEN', 'KIDS', 'INFLUENCERS', 'PROMOGIRL', 'ACTOR', 'SINGER', 'DJ', 'COMEDY', 'TV HOST', 'HAIR STYLES', 'MAKE-UP'];

export const vet_day = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31' ];

export const vet_city = ['LOS ANGELES', 'MIAMI', 'NEW YORK', 'MILAN'];

export const vet_pettype = ['CAT', 'DOG', 'BIRD', 'OTHER'];

export const vet_gender = ['MALE', 'FEMALE'];

export const vet_show = ['Corporate', 'Private', 'Graduation', 'With ticket', 'Free', 'Others'];

export const vet_models = ['Advertising campaign', 'Fashion show', 'Photo essay', 'Others'];

export const vet_period = ['Morning', 'Afternoon', 'Night', 'Any'];

export const vet_nationality = [
    {
        e164_cc: "55",
        code: "BR",
        name: "Brazil - BR"
    },
    {
        e164_cc: "1",
        code: "US",
        name: "United States - US"
    },
    {
        e164_cc: "93",
        code: "AF",
        name: "Afghanistan - AF"
    },
    {
        e164_cc: "358",
        code: "AX",
        name: "Åland Islands - AX"
    },
    {
        e164_cc: "355",
        code: "AL",
        name: "Albania - AL"
    },
    {
        e164_cc: "213",
        code: "DZ",
        name: "Algeria - DZ"
    },
    {
        e164_cc: "1",
        code: "AS",
        name: "American Samoa - AS"
    },
    {
        e164_cc: "376",
        code: "AD",
        name: "Andorra - AD"
    },
    {
        e164_cc: "244",
        code: "AO",
        name: "Angola - AO"
    },
    {
        e164_cc: "1",
        code: "AI",
        name: "Anguilla - AI"
    },
    {
        e164_cc: "1",
        code: "AG",
        name: "Antigua and Barbuda - AG"
    },
    {
        e164_cc: "54",
        code: "AR",
        name: "Argentina - AR"
    },
    {
        e164_cc: "374",
        code: "AM",
        name: "Armenia - AM"
    },
    {
        e164_cc: "297",
        code: "AW",
        name: "Aruba - AW"
    },
    {
        e164_cc: "61",
        code: "AU",
        name: "Australia - AU"
    },
    {
        e164_cc: "43",
        code: "AT",
        name: "Austria - AT"
    },
    {
        e164_cc: "994",
        code: "AZ",
        name: "Azerbaijan - AZ"
    },
    {
        e164_cc: "1",
        code: "BS",
        name: "Bahamas - BS"
    },
    {
        e164_cc: "973",
        code: "BH",
        name: "Bahrain - BH"
    },
    {
        e164_cc: "880",
        code: "BD",
        name: "Bangladesh - BD"
    },
    {
        e164_cc: "1",
        code: "BB",
        name: "Barbados - BB"
    },
    {
        e164_cc: "375",
        code: "BY",
        name: "Belarus - BY"
    },
    {
        e164_cc: "32",
        code: "BE",
        name: "Belgium - BE"
    },
    {
        e164_cc: "501",
        code: "BZ",
        name: "Belize - BZ"
    },
    {
        e164_cc: "229",
        code: "BJ",
        name: "Benin - BJ"
    },
    {
        e164_cc: "1",
        code: "BM",
        name: "Bermuda - BM"
    },
    {
        e164_cc: "975",
        code: "BT",
        name: "Bhutan - BT"
    },
    {
        e164_cc: "591",
        code: "BO",
        name: "Bolivia - BO"
    },
    {
        e164_cc: "387",
        code: "BA",
        name: "Bosnia and Herzegovina - BA"
    },
    {
        e164_cc: "267",
        code: "BW",
        name: "Botswana - BW"
    },
    
    {
        e164_cc: "246",
        code: "IO",
        name: "British Indian Ocean Territory - IO"
    },
    {
        e164_cc: "1",
        code: "VG",
        name: "British Virgin Islands - VG"
    },
    {
        e164_cc: "673",
        code: "BN",
        name: "Brunei - BN"
    },
    {
        e164_cc: "359",
        code: "BG",
        name: "Bulgaria - BG"
    },
    {
        e164_cc: "226",
        code: "BF",
        name: "Burkina Faso - BF"
    },
    {
        e164_cc: "257",
        code: "BI",
        name: "Burundi - BI"
    },
    {
        e164_cc: "855",
        code: "KH",
        name: "Cambodia - KH"
    },
    {
        e164_cc: "237",
        code: "CM",
        name: "Cameroon - CM"
    },
    {
        e164_cc: "1",
        code: "CA",
        name: "Canada - CA"
    },
    {
        e164_cc: "238",
        code: "CV",
        name: "Cape Verde - CV"
    },
    {
        e164_cc: "599",
        code: "BQ",
        name: "Caribbean Netherlands - BQ"
    },
    {
        e164_cc: "1",
        code: "KY",
        name: "Cayman Islands - KY"
    },
    {
        e164_cc: "236",
        code: "CF",
        name: "Central African Republic - CF"
    },
    {
        e164_cc: "235",
        code: "TD",
        name: "Chad - TD"
    },
    {
        e164_cc: "56",
        code: "CL",
        name: "Chile - CL"
    },
    {
        e164_cc: "86",
        code: "CN",
        name: "China - CN"
    },
    {
        e164_cc: "61",
        code: "CX",
        name: "Christmas Island - CX"
    },
    {
        e164_cc: "61",
        code: "CC",
        name: "Cocos [Keeling] Islands - CC"
    },
    {
        e164_cc: "57",
        code: "CO",
        name: "Colombia - CO"
    },
    {
        e164_cc: "269",
        code: "KM",
        name: "Comoros - KM"
    },
    {
        e164_cc: "243",
        code: "CD",
        name: "Democratic Republic Congo - CD"
    },
    {
        e164_cc: "242",
        code: "CG",
        name: "Republic of Congo - CG"
    },
    {
        e164_cc: "682",
        code: "CK",
        name: "Cook Islands - CK"
    },
    {
        e164_cc: "506",
        code: "CR",
        name: "Costa Rica - CR"
    },
    {
        e164_cc: "225",
        code: "CI",
        name: "Côte d'Ivoire - CI"
    },
    {
        e164_cc: "385",
        code: "HR",
        name: "Croatia - HR"
    },
    {
        e164_cc: "53",
        code: "CU",
        name: "Cuba - CU"
    },
    {
        e164_cc: "599",
        code: "CW",
        name: "Curaçao - CW"
    },
    {
        e164_cc: "357",
        code: "CY",
        name: "Cyprus - CY"
    },
    {
        e164_cc: "420",
        code: "CZ",
        name: "Czech Republic - CZ"
    },
    {
        e164_cc: "45",
        code: "DK",
        name: "Denmark - DK"
    },
    {
        e164_cc: "253",
        code: "DJ",
        name: "Djibouti - DJ"
    },
    {
        e164_cc: "1",
        code: "DM",
        name: "Dominica - DM"
    },
    {
        e164_cc: "1",
        code: "DO",
        name: "Dominican Republic - DO"
    },
    {
        e164_cc: "670",
        code: "TL",
        name: "East Timor - TL"
    },
    {
        e164_cc: "593",
        code: "EC",
        name: "Ecuador - EC"
    },
    {
        e164_cc: "20",
        code: "EG",
        name: "Egypt - EG"
    },
    {
        e164_cc: "503",
        code: "SV",
        name: "El Salvador - SV"
    },
    {
        e164_cc: "240",
        code: "GQ",
        name: "Equatorial Guinea - GQ"
    },
    {
        e164_cc: "291",
        code: "ER",
        name: "Eritrea - ER"
    },
    {
        e164_cc: "372",
        code: "EE",
        name: "Estonia - EE"
    },
    {
        e164_cc: "251",
        code: "ET",
        name: "Ethiopia - ET"
    },
    {
        e164_cc: "500",
        code: "FK",
        name: "Falkland Islands [Islas Malvinas] - FK"
    },
    {
        e164_cc: "298",
        code: "FO",
        name: "Faroe Islands - FO"
    },
    {
        e164_cc: "679",
        code: "FJ",
        name: "Fiji - FJ"
    },
    {
        e164_cc: "358",
        code: "FI",
        name: "Finland - FI"
    },
    {
        e164_cc: "33",
        code: "FR",
        name: "France - FR"
    },
    {
        e164_cc: "594",
        code: "GF",
        name: "French Guiana - GF"
    },
    {
        e164_cc: "689",
        code: "PF",
        name: "French Polynesia - PF"
    },
    {
        e164_cc: "241",
        code: "GA",
        name: "Gabon - GA"
    },
    {
        e164_cc: "220",
        code: "GM",
        name: "Gambia - GM"
    },
    {
        e164_cc: "995",
        code: "GE",
        name: "Georgia - GE"
    },
    {
        e164_cc: "49",
        code: "DE",
        name: "Germany - DE"
    },
    {
        e164_cc: "233",
        code: "GH",
        name: "Ghana - GH"
    },
    {
        e164_cc: "350",
        code: "GI",
        name: "Gibraltar - GI"
    },
    {
        e164_cc: "30",
        code: "GR",
        name: "Greece - GR"
    },
    {
        e164_cc: "299",
        code: "GL",
        name: "Greenland - GL"
    },
    {
        e164_cc: "1",
        code: "GD",
        name: "Grenada - GD"
    },
    {
        e164_cc: "590",
        code: "GP",
        name: "Guadeloupe - GP"
    },
    {
        e164_cc: "1",
        code: "GU",
        name: "Guam - GU"
    },
    {
        e164_cc: "502",
        code: "GT",
        name: "Guatemala - GT"
    },
    {
        e164_cc: "44",
        code: "GG",
        name: "Guernsey - GG"
    },
    {
        e164_cc: "224",
        code: "GN",
        name: "Guinea Conakry - GN"
    },
    {
        e164_cc: "245",
        code: "GW",
        name: "Guinea-Bissau - GW"
    },
    {
        e164_cc: "592",
        code: "GY",
        name: "Guyana - GY"
    },
    {
        e164_cc: "509",
        code: "HT",
        name: "Haiti - HT"
    },
    {
        e164_cc: "672",
        code: "HM",
        name: "Heard Island and McDonald Islands - HM"
    },
    {
        e164_cc: "504",
        code: "HN",
        name: "Honduras - HN"
    },
    {
        e164_cc: "852",
        code: "HK",
        name: "Hong Kong - HK"
    },
    {
        e164_cc: "36",
        code: "HU",
        name: "Hungary - HU"
    },
    {
        e164_cc: "354",
        code: "IS",
        name: "Iceland - IS"
    },
    {
        e164_cc: "91",
        code: "IN",
        name: "India - IN"
    },
    {
        e164_cc: "62",
        code: "ID",
        name: "Indonesia - ID"
    },
    {
        e164_cc: "98",
        code: "IR",
        name: "Iran - IR"
    },
    {
        e164_cc: "964",
        code: "IQ",
        name: "Iraq - IQ"
    },
    {
        e164_cc: "353",
        code: "IE",
        name: "Ireland - IE"
    },
    {
        e164_cc: "44",
        code: "IM",
        name: "Isle of Man - IM"
    },
    {
        e164_cc: "972",
        code: "IL",
        name: "Israel - IL"
    },
    {
        e164_cc: "39",
        code: "IT",
        name: "Italy - IT"
    },
    {
        e164_cc: "1",
        code: "JM",
        name: "Jamaica - JM"
    },
    {
        e164_cc: "81",
        code: "JP",
        name: "Japan - JP"
    },
    {
        e164_cc: "44",
        code: "JE",
        name: "Jersey - JE"
    },
    {
        e164_cc: "962",
        code: "JO",
        name: "Jordan - JO"
    },
    {
        e164_cc: "7",
        code: "KZ",
        name: "Kazakhstan - KZ"
    },
    {
        e164_cc: "254",
        code: "KE",
        name: "Kenya - KE"
    },
    {
        e164_cc: "686",
        code: "KI",
        name: "Kiribati - KI"
    },
    {
        e164_cc: "965",
        code: "KW",
        name: "Kuwait - KW"
    },
    {
        e164_cc: "996",
        code: "KG",
        name: "Kyrgyzstan - KG"
    },
    {
        e164_cc: "856",
        code: "LA",
        name: "Laos - LA"
    },
    {
        e164_cc: "371",
        code: "LV",
        name: "Latvia - LV"
    },
    {
        e164_cc: "961",
        code: "LB",
        name: "Lebanon - LB"
    },
    {
        e164_cc: "266",
        code: "LS",
        name: "Lesotho - LS"
    },
    {
        e164_cc: "231",
        code: "LR",
        name: "Liberia - LR"
    },
    {
        e164_cc: "218",
        code: "LY",
        name: "Libya - LY"
    },
    {
        e164_cc: "423",
        code: "LI",
        name: "Liechtenstein - LI"
    },
    {
        e164_cc: "370",
        code: "LT",
        name: "Lithuania - LT"
    },
    {
        e164_cc: "352",
        code: "LU",
        name: "Luxembourg - LU"
    },
    {
        e164_cc: "853",
        code: "MO",
        name: "Macau - MO"
    },
    {
        e164_cc: "389",
        code: "MK",
        name: "Macedonia - MK"
    },
    {
        e164_cc: "261",
        code: "MG",
        name: "Madagascar - MG"
    },
    {
        e164_cc: "265",
        code: "MW",
        name: "Malawi - MW"
    },
    {
        e164_cc: "60",
        code: "MY",
        name: "Malaysia - MY"
    },
    {
        e164_cc: "960",
        code: "MV",
        name: "Maldives - MV"
    },
    {
        e164_cc: "223",
        code: "ML",
        name: "Mali - ML"
    },
    {
        e164_cc: "356",
        code: "MT",
        name: "Malta - MT"
    },
    {
        e164_cc: "692",
        code: "MH",
        name: "Marshall Islands - MH"
    },
    {
        e164_cc: "596",
        code: "MQ",
        name: "Martinique - MQ"
    },
    {
        e164_cc: "222",
        code: "MR",
        name: "Mauritania - MR"
    },
    {
        e164_cc: "230",
        code: "MU",
        name: "Mauritius - MU"
    },
    {
        e164_cc: "262",
        code: "YT",
        name: "Mayotte - YT"
    },
    {
        e164_cc: "52",
        code: "MX",
        name: "Mexico - MX"
    },
    {
        e164_cc: "691",
        code: "FM",
        name: "Micronesia - FM"
    },
    {
        e164_cc: "373",
        code: "MD",
        name: "Moldova - MD"
    },
    {
        e164_cc: "377",
        code: "MC",
        name: "Monaco - MC"
    },
    {
        e164_cc: "976",
        code: "MN",
        name: "Mongolia - MN"
    },
    {
        e164_cc: "382",
        code: "ME",
        name: "Montenegro - ME"
    },
    {
        e164_cc: "1",
        code: "MS",
        name: "Montserrat - MS"
    },
    {
        e164_cc: "212",
        code: "MA",
        name: "Morocco - MA"
    },
    {
        e164_cc: "258",
        code: "MZ",
        name: "Mozambique - MZ"
    },
    {
        e164_cc: "95",
        code: "MM",
        name: "Myanmar [Burma] - MM"
    },
    {
        e164_cc: "264",
        code: "NA",
        name: "Namibia - NA"
    },
    {
        e164_cc: "674",
        code: "NR",
        name: "Nauru - NR"
    },
    {
        e164_cc: "977",
        code: "NP",
        name: "Nepal - NP"
    },
    {
        e164_cc: "31",
        code: "NL",
        name: "Netherlands - NL"
    },
    {
        e164_cc: "687",
        code: "NC",
        name: "New Caledonia - NC"
    },
    {
        e164_cc: "64",
        code: "NZ",
        name: "New Zealand - NZ"
    },
    {
        e164_cc: "505",
        code: "NI",
        name: "Nicaragua - NI"
    },
    {
        e164_cc: "227",
        code: "NE",
        name: "Niger - NE"
    },
    {
        e164_cc: "234",
        code: "NG",
        name: "Nigeria - NG"
    },
    {
        e164_cc: "683",
        code: "NU",
        name: "Niue - NU"
    },
    {
        e164_cc: "672",
        code: "NF",
        name: "Norfolk Island - NF"
    },
    {
        e164_cc: "850",
        code: "KP",
        name: "North Korea - KP"
    },
    {
        e164_cc: "1",
        code: "MP",
        name: "Northern Mariana Islands - MP"
    },
    {
        e164_cc: "47",
        code: "NO",
        name: "Norway - NO"
    },
    {
        e164_cc: "968",
        code: "OM",
        name: "Oman - OM"
    },
    {
        e164_cc: "92",
        code: "PK",
        name: "Pakistan - PK"
    },
    {
        e164_cc: "680",
        code: "PW",
        name: "Palau - PW"
    },
    {
        e164_cc: "970",
        code: "PS",
        name: "Palestinian Territories - PS"
    },
    {
        e164_cc: "507",
        code: "PA",
        name: "Panama - PA"
    },
    {
        e164_cc: "675",
        code: "PG",
        name: "Papua New Guinea - PG"
    },
    {
        e164_cc: "595",
        code: "PY",
        name: "Paraguay - PY"
    },
    {
        e164_cc: "51",
        code: "PE",
        name: "Peru - PE"
    },
    {
        e164_cc: "63",
        code: "PH",
        name: "Philippines - PH"
    },
    {
        e164_cc: "48",
        code: "PL",
        name: "Poland - PL"
    },
    {
        e164_cc: "351",
        code: "PT",
        name: "Portugal - PT"
    },
    {
        e164_cc: "1",
        code: "PR",
        name: "Puerto Rico - PR"
    },
    {
        e164_cc: "974",
        code: "QA",
        name: "Qatar - QA"
    },
    {
        e164_cc: "262",
        code: "RE",
        name: "Réunion - RE"
    },
    {
        e164_cc: "40",
        code: "RO",
        name: "Romania - RO"
    },
    {
        e164_cc: "7",
        code: "RU",
        name: "Russia - RU"
    },
    {
        e164_cc: "250",
        code: "RW",
        name: "Rwanda - RW"
    },
    {
        e164_cc: "590",
        code: "BL",
        name: "Saint Barthélemy - BL"
    },
    {
        e164_cc: "290",
        code: "SH",
        name: "Saint Helena - SH"
    },
    {
        e164_cc: "1",
        code: "KN",
        name: "St. Kitts - KN"
    },
    {
        e164_cc: "1",
        code: "LC",
        name: "St. Lucia - LC"
    },
    {
        e164_cc: "590",
        code: "MF",
        name: "Saint Martin - MF"
    },
    {
        e164_cc: "508",
        code: "PM",
        name: "Saint Pierre and Miquelon - PM"
    },
    {
        e164_cc: "1",
        code: "VC",
        name: "St. Vincent - VC"
    },
    {
        e164_cc: "685",
        code: "WS",
        name: "Samoa - WS"
    },
    {
        e164_cc: "378",
        code: "SM",
        name: "San Marino - SM"
    },
    {
        e164_cc: "239",
        code: "ST",
        name: "São Tomé and Príncipe - ST"
    },
    {
        e164_cc: "966",
        code: "SA",
        name: "Saudi Arabia - SA"
    },
    {
        e164_cc: "221",
        code: "SN",
        name: "Senegal - SN"
    },
    {
        e164_cc: "381",
        code: "RS",
        name: "Serbia - RS"
    },
    {
        e164_cc: "248",
        code: "SC",
        name: "Seychelles - SC"
    },
    {
        e164_cc: "232",
        code: "SL",
        name: "Sierra Leone - SL"
    },
    {
        e164_cc: "65",
        code: "SG",
        name: "Singapore - SG"
    },
    {
        e164_cc: "1",
        code: "SX",
        name: "Sint Maarten - SX"
    },
    {
        e164_cc: "421",
        code: "SK",
        name: "Slovakia - SK"
    },
    {
        e164_cc: "386",
        code: "SI",
        name: "Slovenia - SI"
    },
    {
        e164_cc: "677",
        code: "SB",
        name: "Solomon Islands - SB"
    },
    {
        e164_cc: "252",
        code: "SO",
        name: "Somalia - SO"
    },
    {
        e164_cc: "27",
        code: "ZA",
        name: "South Africa - ZA"
    },
    {
        e164_cc: "500",
        code: "GS",
        name: "South Georgia and the South Sandwich Islands - GS"
    },
    {
        e164_cc: "82",
        code: "KR",
        name: "South Korea - KR"
    },
    {
        e164_cc: "211",
        code: "SS",
        name: "South Sudan - SS"
    },
    {
        e164_cc: "34",
        code: "ES",
        name: "Spain - ES"
    },
    {
        e164_cc: "94",
        code: "LK",
        name: "Sri Lanka - LK"
    },
    {
        e164_cc: "249",
        code: "SD",
        name: "Sudan - SD"
    },
    {
        e164_cc: "597",
        code: "SR",
        name: "Suriname - SR"
    },
    {
        e164_cc: "47",
        code: "SJ",
        name: "Svalbard and Jan Mayen - SJ"
    },
    {
        e164_cc: "268",
        code: "SZ",
        name: "Swaziland - SZ"
    },
    {
        e164_cc: "46",
        code: "SE",
        name: "Sweden - SE"
    },
    {
        e164_cc: "41",
        code: "CH",
        name: "Switzerland - CH"
    },
    {
        e164_cc: "963",
        code: "SY",
        name: "Syria - SY"
    },
    {
        e164_cc: "886",
        code: "TW",
        name: "Taiwan - TW"
    },
    {
        e164_cc: "992",
        code: "TJ",
        name: "Tajikistan - TJ"
    },
    {
        e164_cc: "255",
        code: "TZ",
        name: "Tanzania - TZ"
    },
    {
        e164_cc: "66",
        code: "TH",
        name: "Thailand - TH"
    },
    {
        e164_cc: "228",
        code: "TG",
        name: "Togo - TG"
    },
    {
        e164_cc: "690",
        code: "TK",
        name: "Tokelau - TK"
    },
    {
        e164_cc: "676",
        code: "TO",
        name: "Tonga - TO"
    },
    {
        e164_cc: "1",
        code: "TT",
        name: "Trinidad/Tobago - TT"
    },
    {
        e164_cc: "216",
        code: "TN",
        name: "Tunisia - TN"
    },
    {
        e164_cc: "90",
        code: "TR",
        name: "Turkey - TR"
    },
    {
        e164_cc: "993",
        code: "TM",
        name: "Turkmenistan - TM"
    },
    {
        e164_cc: "1",
        code: "TC",
        name: "Turks and Caicos Islands - TC"
    },
    {
        e164_cc: "688",
        code: "TV",
        name: "Tuvalu - TV"
    },
    {
        e164_cc: "1",
        code: "VI",
        name: "U.S. Virgin Islands - VI"
    },
    {
        e164_cc: "256",
        code: "UG",
        name: "Uganda - UG"
    },
    {
        e164_cc: "380",
        code: "UA",
        name: "Ukraine - UA"
    },
    {
        e164_cc: "971",
        code: "AE",
        name: "United Arab Emirates - AE"
    },
    {
        e164_cc: "44",
        code: "GB",
        name: "United Kingdom - GB"
    },
   
    {
        e164_cc: "598",
        code: "UY",
        name: "Uruguay - UY"
    },
    {
        e164_cc: "998",
        code: "UZ",
        name: "Uzbekistan - UZ"
    },
    {
        e164_cc: "678",
        code: "VU",
        name: "Vanuatu - VU"
    },
    {
        e164_cc: "379",
        code: "VA",
        name: "Vatican City - VA"
    },
    {
        e164_cc: "58",
        code: "VE",
        name: "Venezuela - VE"
    },
    {
        e164_cc: "84",
        code: "VN",
        name: "Vietnam - VN"
    },
    {
        e164_cc: "681",
        code: "WF",
        name: "Wallis and Futuna - WF"
    },
    {
        e164_cc: "212",
        code: "EH",
        name: "Western Sahara - EH"
    },
    {
        e164_cc: "967",
        code: "YE",
        name: "Yemen - YE"
    },
    {
        e164_cc: "260",
        code: "ZM",
        name: "Zambia - ZM"
    },
    {
        e164_cc: "263",
        code: "ZW",
        name: "Zimbabwe - ZW"
    }
];

	    