import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { login, logout, isAuthenticated, getToken } from "../services/auth";

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/destaques.css';
import '../css/getscouted.css';



import api from "../services/api";
//import { login, logout, isAuthenticated, getToken } from "../services/auth";

import Admnav from '../components/admnav';


class cidades extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      cidades: []
	    };
	}

	componentDidMount() {
	    this._getFeatured();
		this._checkLogin();
	}

	_checkLogin = async () => {
	    api.get(`/staff/check`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        //Logado
	      }
	      else {
	        //Erro no login
	        logout();
	        this.props.history.push("/login");
	      }
	    }).catch((err) => {
	    	logout();
	        this.props.history.push("/login");
	    });

	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}


	_getFeatured = async () => {
	    axios.get(`/city`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.city;
	        this.setState({
	          cidades: dados,
	        });
	      }
	      else {
	        
	      }
	    }).catch(() => {
	      
	    });

	  }

	handleSubmit = async e => {
		e.preventDefault();

		api.post(`/city`, { name: this.state.name})
		.then(response => {
			this.setState({
				name: ''
			});
			this._getFeatured();
		}).catch((error) => {
			alert('Falhou');
		});

	};


 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Admnav page={'cidades'}/>

			 	<section className="modelcontainer">

			 		<div className="form-title">CIDADES</div>

			 		{this.state.cidades.map((f, index) =>
			 			<div className="boxdestaques">
			 				<div className="destaquetxt">
			 					{f.name}
			 				</div>
			 				<div className="destaqueremove">
			 					<button className="destaquebtn" onClick={() => {this.props.history.push(`/adm/cidades/${f._id}`);} }>Adicionar modelos</button>
			 				</div>
			 			</div>
			 		)}

			 		<div className="form-title">INSERIR CIDADE</div>
			 		<form className="row form" onSubmit={this.handleSubmit}>
						<div className="col-12">
							Nome<br/>
							<input type="text" name="name" value={this.state.name} onChange={e => this.handleChange(e)}/><br/>
						</div>

						<div className="col-12">
							<input className="sendbtn" type="submit" value="SALVAR"/>
						</div>

					</form>

				
				</section>

			 </div>
			</div>
	    );
	}
}

export default withRouter(cidades);