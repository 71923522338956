import React, {Component} from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import api from "../services/api";

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/about.css';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { translate } from "../services/translate";


class faq extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'FAQ' });
	};

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={`FAQ${this.props.match.params.id}`}/>

				
			 	<section className="modelcontainer">

			 		<div className="row name">
						<div className="col-12">
							<div className="form-title">FAQ</div>
						</div>
					</div>

					<div className="row">
					

						<div className="col-12">
							<div className="textbox_a">

								{ (this.props.match.params.id === '1' || this.props.match.params.id === 'all')  &&
									<>
										<h1>AVANT GARDE TALENT’S</h1>
										<p>
											<strong>What is the difference between a casting company and an agency?</strong><br/>
											A casting company is hired by a production company to cast talent for a project such as a film, commercial, music video, runway show or print.  They audition and/or book the talent they find best suited for the project.  An agency acts as a representative on behalf of a model, actor, or personality).  Most agencies represent talent in their local areas only.<br/>
										</p>
										<p>
											<strong>	How do agencies make money?</strong><br/>
										</p>
										<p>
											<strong>	Is it true that if I sign with an agency I don’t’ have to pay for anything?</strong><br/>
											Agencies typically charge talent 15-25% commission on any work they book.  
											Acting agencies do not upfront any marketing material costs or training for actors.  Actor marketing materials include a photographer, makeup artist and hairstylist for headshots (and printing of the headshots).  They also do not pay for actors to attend workshops, classes, training, courses or specialty training.
										</p>
										<p>
											<strong>	What is AVANT GARDE TALENTS?</strong><br/>
											We are an Talents Company who helps models, actor and artists get started and further their careers in the industry.  We also help casting directors, directors, production companies and industry professionals find talent for their projects.
										</p>
										<p>
											<strong>	What does AVANT GARDE TALENTS</strong><br/>
											The 100% of models and actors aren’t represented by an agency and we are helping them take over the entertainment industry.  AVANT GARDE TALENTS provides the 100% with the tools, opportunities and support to begin and advance their careers.
										</p>
										<p>
											<strong>	What does “Avant Garde Talents” mean?</strong><br/>
											We are not an agency. We help provide talent opportunities by connecting them with the industry professionals who are looking for models and actors for their projects.  We also provide talent with the tools and support they need.  Unlike an agency, we do not charge any commissions to talent or casting directors and industry professionals who use our talent.
										</p>
										<p>
											<strong>	If you’re not an agency, then what is AVANT GARDE TALENTS?</strong><br/>
											We are an Talents and marketing company that provides paid and non-paid opportunities, exposure and knowledge development to aspiring models and actors in the mainstream entertainment industry.  We also offer completely free services to casting directors, directors, production companies, agencies and other industry professionals. 
										</p>
										<p>
											<strong>	How is AVANT GARDE TALENTS different from other online casting companies?</strong><br/>
											We are not only online, we have numerous locations across the country. Each office has a dedicated staff who are willing and able to sit down with any talent and help them go through castings, upload photos, take new measurements, and offer guidance on the industry.  Not only do we have a database of public castings our talent can submit themselves to, we also have a Castings Department located in our corporate office that helps casting directors and industry professionals find talent for their projects.  We not only help talent find opportunities in their area but nationwide.  We cross-market our talent to any area they are interested and able to attend bookings in. 
										</p>
										<p>
											<strong>	
											Are you a modeling/acting school?</strong><br/>
											No, AVANT GARDE TALENTS is a talent listing service though we do offer our talent the opportunity to attend various events in their market to improve their knowledge, skills and experience.
										</p>
		
									</>
								}



								{ (this.props.match.params.id === '3' || this.props.match.params.id === 'all') &&

									<>
										<h1>BECOMING Avant Garde Talent</h1>
										
										<p>
											<strong>Does it cost to attend my first appointment?</strong><br/>
											No. You are only coming in to see if we see potential in signing you.
										</p>
										<p>
											<strong>	What do I need to bring with me?</strong><br/>
											If you have ever gotten professional comp cards or head shots done bring them in. Other than that nothing.
										</p>
										<p>
											<strong>	What should I wear?</strong><br/>
											Fashionably casual is recommended.
										</p>
										<p>
											<strong>	How long does an evaluation appointment last?</strong><br/>
											It will vary person to person. It can be anywhere between 5 minutes to an hour.
										</p>
										<p>
											<strong>	What happens after the evaluation?</strong><br/>
											We will either decide you are a right fit for the company and invite you back for a final one on one call back or ask you to reapply when you are ready and have taken our notes into consideration.
										</p>
										<p>
											<strong>	I don’t have any experience in the industry, do I qualify?</strong><br/>
											AVANT GARDE TALENTS works with talent who both have no experience and lots of experience in the industry.
										</p>
										<p>
											<strong>	I don’t think I have the right measurements to model, do I qualify?</strong><br/>
											Models are definitely marketed out by their body type and their look  but there are many types of modeling that do not require a specific body type.
										</p>
										<p>
											<strong>	AVANT GARDE TALENTS contacted me, how did you get my information?</strong><br/>
											We reach out to individuals who have applied to be evaluated through our website or other online location where we have a presence.
										</p>
										<p>
											<strong>	I might be 10 minutes late, is that okay?</strong><br/>
											If you know you will not be able to make your appointment on time, it’s best to reschedule to a day where you can arrive 15-20 minutes early.
										</p>
										<p>
											<strong>	Do I have to pay for the pictures you take at the initial evaluation?</strong><br/>
											No, these are part of the evaluation.

										</p>
										<p>
											<strong>I’m underage so can I bring a sibling or aunt/uncle with me toe Open Call instead of my parent?</strong><br/>
											If you are a minor a parent must be present at the Open Call.
										</p>
										<p>
											<strong>	I don’t have anyone to watch my child, can I bring him/her to the appointment?</strong><br/>
											It would be best if you attended the evaluation alone, space is limited.
										</p>
										<p>
											<strong>	Do I need to have experience?</strong><br/>
											No, we work with all levels of experience.
										</p>
										<p>
											<strong>	What ages do you work with?</strong><br/>
											Anyone 3 and up.
										</p>
										<p>
											<strong>	How long does it take to find out if I’m accepted?</strong><br/>
											We will let know during your final interview.
										</p>
										<p>
											<strong>	Do I have to sign a contract?</strong><br/>
											No, out talent do not have to sign contracts with AVANT GARDE TALENTS.
										</p>
										<p>
											<strong>	What do you mean by finalizing everything at the final appointment?</strong><br/>
											At this meeting we will discuss how we can help you while learning how to market you.
										</p>
										<p>
											<strong>	Who will help me set up my comp card and profile?</strong><br/>
											Our staff members- it could be our Office Director or a Talent Specialist on their team
										</p>
										<p>
											<strong>	Should I quit my job?</strong><br/>
											No, we don’t know when or what types of castings you will book.
										</p>
										<p>
											<strong>	I am already with an Agency, is that a problem?</strong><br/>
											No, we can help supplement the work your agency is providing you. We are not affiliated with any unions.
										</p>
										<p>
											<strong>	Can I call the office if I have questions?</strong><br/>
											Yes, we are available to answer any questions you may have.
										</p>

									</>

								}


								{ (this.props.match.params.id === '8' || this.props.match.params.id === 'all') &&
									<>
										<h1>CELEBRITY</h1>

										<p>
											<strong>Why should we hire Avant Garde Talents if our company already knows how to reach celebrities?</strong><br/>
											The bottom line is that we can save you time and money. We are professionals in a very specialized field. Celebrity procurement and coordination is the total focus of our job on a day-to-day basis. Since celebrity contact information constantly changes, we continuously update our database in order to maintain the highest standard of accuracy. In addition to maintaining our sizable database, we are successful because we have developed many relationships within the celebrity community. In an industry where this is so important, our relationships enable us to get quicker and more positive responses. Large corporations, public relations firms, and ad agencies hire us even when they know how to access the celebrity community because they know that in the end, it’s more cost and time effective to utilize the services of Avant Garde Talents.
										</p>
										<p>
											<strong>Can you save us time and money?</strong><br/>
											Our principal objective is to match the right celebrities for you, our client, while saving you time and money. To that end we make it our business to know what will motivate a celebrity to say “yes.” Since celebrity procurement and coordination is the total focus of our day-to-day operation, we have become very efficient in our ability to reach the most celebrities in the shortest amount of time.
										</p>
										<p>
											<strong>	How much will it cost for a Celebrity?</strong><br/>
											Factors that affect how much a celebrity is paid depend on the celebrity, the level of their popularity and the nature of the request. Generally speaking, a celebrity is paid a higher fee for endorsement deals and commercial requests than for most appearances or public relations campaigns.<br/>
											Avant Garde Talents fee (separate from the celebrity fee), is determined by the number of celebrities requested, whether there is a cause or charity involved, how complicated the coordination logistics are and generally, how much time we believe the request will take to fulfill. To obtain an estimate of cost for your project or event call Avant Garde Talents at 1.212.920.6110 or e-mail Info@avantgardetalents.com.
										</p>
										<p>
											<strong>	Are celebrities always paid?</strong><br/>
											Celebrities are usually not paid for most non-profit requests. However, perks and/or gifts are often a motivating factor to entice celebrity participation. An honorarium (which is usually lower than their standard fee) is often in order when a celebrity is asked to perform or participate in an activity that requires a great deal of preparation or rehearsal.
										</p>
										<p>
											<strong>	Which aspects of celebrity procurement and coordination do you handle?</strong><br/>
											We are a full service company that handles all the details concerning a celebrity’s participation, including, but not limited to: researching and developing a realistic list of potential celebrities that will stay within budget and meet our client’s objectives; contacting and confirming the celebrity; and negotiating and acting as the liaison for contract fulfillment.<br/>
											Once a celebrity is confirmed, we coordinate the myriad of details and logistics necessary in order to have everything run smoothly. This may include coordinating ground transportation, travel, and accommodations; setting up briefing sessions when required; and acting as the liaison for content, script or photo approval. Furthermore, a Avant Garde Talents staff executive is always on site to make sure that client and the celebrity needs are met.<br/>
											You can rely on us to handle every detail — from identifying the right celebrity to the thank you notes at the end. Our job is to take the frustration and guesswork out of the celebrity procurement and coordination process, so that you can spend your time more productively.
										</p>
										<p>
											<strong>	How are you able to determine which celebrity is right for my project?</strong><br/>
											We maintain a database of information on each celebrity that includes special interests, hobbies, hometown, favorite causes, sports skills, unique talents, and whether or not they have children and pets, among other things.<br/>
											Of ten we get this information directly from the celebrities themselves after they have filled out our questionnaire. All of this information helps us make the right matches for our clients – matches that are meaningful, relevant and credible.
										</p>
										<p>
											<strong>	Can you guarantee that you will find the right celebrity for my project or event?</strong><br/>
											When you are dealing with celebrities, who are human beings like the rest of us, life happens. There are no guarantees; however, we do not accept an account unless we feel that we can be successful for the client. Because of this, Avant Garde Talents has a 96% success rate.
										</p>
										<p>
											<strong>	Can you reach anyone?</strong><br/>
											We define “celebrity” as someone who is not only very well known, but also is in the public eye. Under this definition, we can contact anyone.
										</p>
										<p>
											<strong>	What do we need to know before we request a celebrity’s participation?</strong><br/>
											Some of the questions that you should consider are: What exactly do you want the celebrity to do? Who do you want to appeal to by having a celebrity? Is it the media, the public, the attendees or sponsors? What do you want to accomplish by having a celebrity participate? Is it to add glitz and glamour, sell tickets, grab the public’s attention, separate your campaign from the pack, or add credibility to the project? What are the demographics of your audience or attendees? What is your budget? What is the maximum that you are willing to spend for the right celebrity? Are you prepared to cover first class expenses for the celebrity and at least one guest? Do you have access to any perks or gifts that will help motivate a celebrity to say “yes”? These are some of the questions to which you should know the answers.
										</p>
										<p>
											<strong>	Do we have to tell you who we want, or do you suggest names to us?</strong><br/>
											We start by asking you, our client, for a short wish list of names. This helps us understand what kind of star you are looking for. It also helps us make sure that there are realistic expectations. We then research additional names and make recommendations based on your objectives, budget and need.
										</p>
										<p>
											<strong>	We are a corporation with public relations, advertising and marketing agencies at our disposal. They handle our needs, including celebrity acquisition. Why would we need Avant Garde Talents?</strong><br/>
											We work with your agencies as an additional resource at their disposal. Because we are celebrity specialists, we can free them up to do the work that you originally contracted them to do in the first place, such as P.R., advertising, marketing or event production.
										</p>
										<p>
											<strong>	How do we know that you will invite the celebrities that we want?</strong><br/>
											All celebrity names are approved by our clients before an invitation is extended or an offer is made.
										</p>
										<p>
											<strong>	How important are the perks and gifts that celebrities get?</strong><br/>
											Everyone enjoys receiving gifts, even celebrities. Sometimes perks, gifts and/or products can provide an extra incentive for celebrities to say “yes” to a request. We often recommend that our clients build these items into a request or offer.
										</p>
										<p>
											<strong>	Do you know which celebrities are associated with specific causes?</strong><br/>
											Yes we do. We maintain a database of personal information on each celebrity, which includes if they have a favorite cause.
										</p>
										<p>
											<strong>	What are some of the “dos and don’ts” of working with celebrities?</strong><br/>
											1. DON’T surprise celebrities with things you want them to do that were not part of the original request or contract. DO state everything you need from the celebrity up front and make sure that they are well briefed as to what to expect and the purpose of their involvement.<br/>
											2. DON’T ask celebrities to arrive too early, when there’s no one around to show enthusiasm for their arrival.<br/>
											3. DO set limits on the expenses that you are covering and let the celebrity know these limits in advance. DON’T state that all expenses are covered unless you are prepared to cover any amount.<br/>
											4. DO provide a limousine if your budget allows. The easier you make it for a celebrity to attend, the more likely they will say “yes.”<br/>
											5. DON’T make any promises that you can’t keep and DO keep all of the promises you make.	<br/>
										</p>

									</>
								}


								{ (this.props.match.params.id === '4' || this.props.match.params.id === 'all') &&

									<>
										<h1>CASTINGS AND AUDITIONS </h1>

										<p>
											<strong>What types of opportunities does AVANT GARDE TALENTS help provide?</strong><br/>
											We work with a wide array of castings directors and clients. A little bit of everything: print, editorials, ads, commercials, runway, theater, TV, film, music videos, extra work.
										</p>
										<p>
											<strong>How do you help me get auditions?</strong><br/>
											There are several ways our talent received opportunities.  Over the past years we have built strong relationships with thousands of casting companies, directors, agencies, producers and other clients.  These clients come to us directly looking for talent.  They can either request to receive digital package of our talent from our Castings Department.  Clients also have the ability to post casting calls on our website for our talent to submit themselves to directly.  In addition, our Castings Department staff aggressively seeks out opportunities for our talent and new clients who are looking for models and actors for their projects.  The benefit of an industry professional to use our talent over other casting companies and agencies is unlike them.
										</p>
										<p>
											<strong>
											How much money can I make?</strong><br/>
											How much money you make in this industry will vary and may depend on a multitude of factors including but not limited to: your geographic location, your talent or ability, your look, your flexibility in schedule in order to take gigs, casting directors or agents being interested in you, your perseverance, your willingness to take rejection and keep going, the jobs out there available for your look/ability/location, and also a little bit of luck. Since there are so many factors that go into the success of any individual in this industry we can not give anyone an estimate of how much money they would be making.
										</p>
										<p>
											<strong>	
											Will you pay for my travel to a job or casting?</strong><br/>
											No, we do not provide compensation for travel, nor do we provide transportation.
										</p>
										<p>
											<strong>	
											What does BG Stand for?</strong><br/>
											BG Stands for Back Ground in the industry.
										</p>
										<p>
											<strong>	
											What is TFP? </strong><br/>
											TFP stands for Trade for Print, that’s when a photographer agrees to provide the model with a certain number of photos in exchange for the models time and willingness to take the photos.
										</p>
										<p>
											<strong>	
											How many Events can I attend?</strong><br/>
											You can attend any event that you fit the criteria for, just make sure to RSVP before attending.
										</p>
										<p>
											<strong>	
											How do I submit myself out to a casting?</strong><br/>
											To submit yourself out to castings you must follow the submission instructions held in the description of the casting, the instructions change depending on what the client is looking for you want to make sure to follow the instructions as much as possible to increase your chances of getting booked for that project.
										</p>
										<p>
											<strong>	
											What is the My Matches Section?</strong><br/>
											The My Matches Section is casting currently posted that you fit the criteria for, if you fit the age range, ethnicity, gender and state of which the casting is being held you will find it in your my matches section.
										</p>
										<p>
											<strong>	
											What is an Open Casting Call?</strong><br/>
											An open call is a casting you don’t have to submit yourself out for, you can simply just show up to the audition.
										</p>
										<p>
											<strong>	
											How do I get paid if I’m booked on a paid project?</strong><br/>
											You would get paid by the client directly, you will need to fill out an Invoice and the check typically takes 30 to 60 days.
										</p>
										<p>
											<strong>	
											Why would I want to do Castings if they aren’t paid?</strong><br/>
											Experience, Exposure. The more work you do the more experience and exposure you will receive in the entertainment industry.
										</p>
										<p>
											<strong>	
											If I get requested do I have to attend the audition?</strong><br/>
											No, you don’t have to attend any casting you don’t want to be a part of.
										</p>
										<p>
											<strong>	
											How do I pause my membership?</strong><br/>
											To pause your membership you can send an email to cast@avantgardetalents.com
										</p>


									</>

								}

								{ (this.props.match.params.id === '9' || this.props.match.params.id === 'all') &&
									<>
										<h1>HOW DOES AVANTGARDETALENTS.COM WORK?</h1>

										<p>
											After getting a wish list of entertainers, a date and the budget you wish to stay within from your entertainment request form, we find the act or acts from your list that are available for your specific date that fall within your budget. We then figure out how much of the production responsibilities you wish to take on (i.e. sound, hotel accommodations, ground transportation, etc.) and quote you a price for the entertainment inclusive of the production responsibilities that we will handle.<br/>
											What are the elements (production responsibilities) that make up a live musical performance?<br/>
											Almost every produced concert is made up of the following aspects:<br/>
											• - The band's fee<br/>
											• - Flights<br/>
											• - Ground transportation<br/>
											• - Hotel accommodations<br/>
											• - Pre production and onsite producer<br/>
											• - Sound<br/>
											• - Lights<br/>
											• - Back line equipment (the band's gear)<br/>
											• - Staging<br/>
											• - Power<br/>
											• - Misc. rider requirements<br/>
											What are the elements (production responsibilities) that make up a comedy, speaking, sports figure or Hollywood celebrity appearance?<br/>
											Almost every appearance by an individual is made up of the following aspects:<br/>
											• - The appearance fee<br/>
											• - Flights<br/>
											• - Ground transportation<br/>
											• - Hotel accommodations<br/>
											• - Sound (for speakers and comedians)<br/>
											• - Misc. rider requirements (i.e. coffee, hot meal, towels, water)<br/>
											Is there an upfront fee to use Avantgardetalents.com?<br/>
											NO. The only time your have to pay anything is if we secure an act for you and our fees are built in to the overall price we quote for the entertainment.<br/>
											What are rider requirements? <br/>
											A rider is a list of what the performer requires to perform or make an appearance. It RIDES or is attached to the contract so it is called a rider. This rider includes everything from the number of flights to the sound requirements to the type of food and beverages that they require.<br/>
											What do you mean by a TURN-KEY event?<br/>
											A turn-key event means that we will handle all of the necessary requirements mentioned above. This includes providing a producer to fly in to the city where the engagement is being held to handle the show from A-Z. How much we handle depends on how much you feel comfortable dealing with (hotels, ground transportation, etc.).<br/>
											What is the best way to get started working with AvantGardeTalents.com?<br/>
											Simply fill out an Entertainment Request Form and an agent will contact you within 48 hours.<br/>
										</p>
										

									</>
								}



								{ (false) &&
									<>
										<h1>Specialists in Producing Dreams!</h1>

										<p>
											AVANT GARDE TALENTS positions itself in the market as an entertainment solutions Talents Agency Online specializing in serving companies across the World, deploying our expertise in making our customers perceive a difference from an exclusive show.<br/>
											AVANT GARDE TALENTS professionals have a great deal of experience in the art market and event production, as well as assessing the need for each show in full with the contractor, adding value to their event, from choosing the right attraction to performing on the day. <br/>
											We are not a supplier, but a partner interested in the end result differently.<br/>
											You idealize, We perform!<br/>
											Here at AVANT GARDE TALENTS we love to turn ideas into reality. Therefore, we reduce the size or complexity of the project, we embrace as proposals of our customers with passion and total commitment. We put all our energy and experience into delivering efficient, modern solutions that can deliver great results. More than producing a show, our mission is to connect people, mark events and convey great ideas through art and entertainment.<br/>
											Serious Entertainment<br/>
											We are completing more than two decades of operations in the entertainment market. Our highlight is the agency of artists and artistic production, logistics and technique. During our career, we had the pleasure of serving private clients to the largest companies in the world, delivering thousands of projects with great quality and professionalism.<br/>
											With years in the market, AVANT GARDE TALENTS has become a reference in artistic agency.<br/>
											A acquis of respect<br/>
											We have an exclusive cast of artists, models, actors and celebrities that meet the current market demand with versatility and high quality.<br/>
											In addition, we have permanent partnerships with the most renowned artistic attractions in the country, which accredit privileged negotiated conditions for our clients.<br/>
											Relax and enjoy the show<br/>
											We'll be running your project with a team that specializes in event production, resource management, attraction hiring, structure optimization and general logistics management. All this for you to reduce your event and not have to worry about technical or bureaucratic details. With an AVANT GARDE TALENTS your idea comes out of paper.
											AVANT GARDE TALENTS mission is clear: 100 % of all Artists, models and actors aren’t represented by an agency and we are helping them take over the entertainment industry.  AVANT GARDE TALENTS provides the 100% with the tools, opportunities and support to begin and advance their careers. <br/>
											When we started AVANT GARDE TALENTS in 2000 our mission was to provide tools and resources for all aspiring talent to find success.  Over years as our company grew to become a recognized leader in castings nationwide.   An agency can tell you, the 100%, “no” and tell you they aren’t willing to invest in developing you, but they can’t tell you that you can’t be successful.<br/>
											Come to AVANT GARDE TALENTS and be whatever you want.
											AVANT GARDE TALENTS The Un Agency uses the latest technology and an energized staff with over five years of experience in bookings, advice and top client relationships.  We pride ourselves on a staff that treats everyone with respect and kindness.  At AVANT GARDE TALENTS we work nonstop to ensure each of our talent receives castings and opportunities in real time from our database of industry contacts.  The opportunities range from television and film to commercial, music video, runway, print and promotional castings and gigs.<br/>
											Industry Clients of AVANT GARDE TALENTS Enjoy a searchable database of talent with detailed characteristics, a commission free service, a detailed talent evaluation process to ensure they are getting dedicated professionals and a corporate staff with years of industry knowledge and contacts.<br/>
											At AVANT GARDE TALENTS we believe a client and talent’s relationship is built on teamwork – both sides must be willing to invest in time and the hard work that is needed for success and treat each other with a professional attitude.<br/>
											AVANT GARDE TALENTS is excited to announce we will continue to operate in major markets nationwide including New York, Miami, San Francisco, (Corporate) and, of course, Los Angeles.<br/>
											The Benefits of AVANT GARDE TALENTS<br/>
											  Because we will give you and your business the peace of mind and security you need! <br/>
											• We produce shows of all styles for all types of Event.<br/>
											• Fair price with professional and experienced service.<br/>
											• The most complete and innovative service package on the market for you!<br/>
											• We have expertise in Artistic Contract Management.<br/>
											• Logistics Management and Technical Production with specialized team, minimizing unforeseen events.<br/>
											• We have proven excellence in our service.<br/>
											• We deliver complete Entertainment solutions: the curator... Advice for Artistic Contracting. Tailored productions (creation of exclusive attractions).<br/>
											• Cost Optimization (adequacy to available budget).<br/>
											• Contractor Time Optimization (Field and In-house Production Team).<br/>
											• Relationship Management Artist X Contracting.<br/>
											• Real-time access and submissions to nationwide castings and auditions<br/>
											• An interactive composite card for all digital and traditional submissions<br/>
											•   A commission free environment<br/>
											•   A talent benefits program utilizing select businesses in each market<br/>
											•   Online 24/7 alerts and advanced casting recognition software matches you with the castings that suit you<br/>
											•   At AVANT GARDE TALENTS you have the opportunity to meet with Industry leaders at our monthly industry workshops<br/>
											If you feel you have the passion, the right attitude and the desire to be in the industry and you are ready to take the first step, click here to apply. Taking control of your career with AVANT GARDE TALENTS may be the move you’ve been looking for all along. So, what are you waiting for? Join The Un Agency and take control of your career today.<br/>
										</p>

								

									</>
								}






								{ (this.props.match.params.id === '5' || this.props.match.params.id === 'all') &&
									<>
										<h1>PHOTOGRAPHY</h1>

										<p>
											<strong>Why do you take measurements and photos during my first appointment?</strong><br/>
											We use this information to market you. Your measurements are included on your comp card and sometimes clients request talent based on measurements
										</p>
										<p>
											<strong>Do I need professional photography?</strong><br/>
											Professional photography can help market you. We can work with you. If you are not ready now, you can still book castings without professional photos but your chances do increase with better pictures.
										</p>
										<p>	
											<strong>How important are professional photos if I’m only looking to do acting jobs?</strong><br/>
											Actors need headshots to represent themselves
										</p>
										<p>	
											<strong>Can I use my own photographer?</strong><br/>
											Sure you can. We would like to know who the photographer is and we would like to see samples of their work.
										</p>
										<p>	
											<strong>Do we offer our talent access to a professional photographer?</strong><br/>
											Yes, we have a professional photographer we can recommend in your local area.
										</p>
										<p>	
											<strong>What are the benefits of working with your photographer?</strong><br/>
											We contract out photographers in all of our markets who shoot high quality photos; they know what we like and shoot what clients are looking for and we provide you to own the rights to the photos.
										</p>
										<p>	
											<strong>Do I have to use the Photos taken during my first appointment on my comp card? </strong><br/>
											You do not; you can add and remove photos as you wish.
										</p>
										<p>	
											<strong>How frequent should we be updating and changing our photos on our comp card? </strong><br/>
											Whenever you make a change in your appearance, whether that’s dying your hair or going to the gym to gain muscle your photos should always be up to date.
										</p>
										<p>	
											<strong>Can I shoot with your photographer and my own?</strong><br/>
											Yes, the more professional photos you have the better.
										</p>



									</>
								}

								{/* (this.props.match.params.id === '6' || this.props.match.params.id === 'all') &&
									<>
										<h1>INDUSTRY PROS</h1>

										<p>
											<strong>What is your fee to book talent?</strong><br/>
											We don’t charge any casting director, director, agent or client a fee to use our talent.
										</p>

										<p>
											<strong>If you don’t charge a fee, how do we pay the talent?</strong><br/>
											You will pay the talent directly.
										</p>

										

										<p>
											<strong>I’d like to hold an audition, what kind of space does your office have?</strong><br/>
											Every office has a large, open room that can accommodate 15-35 people and additional individual offices where castings directors can meet with talent in a more intimate environment.
										</p>

										<p>
											<strong>What is the fee to use your office space for an audition?</strong><br/>
											Any casting director is free to use our office space at no cost as long as they give our talent the opportunity to audition for the project.
										</p>

										<p>
											<strong>How do I request to host an event for your talent? </strong><br/>
											You must contact the Office Director in the local office, contact info can be found on our home page under Offices.
										</p>

										<p>
											<strong>Do we get paid to host events? </strong><br/>
											Yes, we compensate our instructors for teaching workshops.
										</p>
									</>
								*/}

								{/* (this.props.match.params.id === '7' || this.props.match.params.id === 'all') &&
									<>
										<h1>BEWARE OF SCAMS</h1>

										<p>
											The entertainment industry is full of exciting opportunities. However, unfortunately, there are also scam artists eager to swindle money from talent.
										</p>

										<p>
											Here are some tips for spotting pay scams:
										</p>

										<p>
											–  Stay away from projects that require depositing money or those involving Money Gram, Western Union, a cashier’s check or a money order. <br/>
											–  Asking for personal information, and refusal or inability to meet face-to-face before transactions, is a red flag. <br/>
											–  Beware of typos, grammatical errors, foreign addresses and telephone numbers. <br/>
											–  Scammers will create a sense of urgency by asking that you deposit or wire money immediately, or “without delay”, and will even threaten to take legal action if you don’t comply. These are idle threats. Never accept advanced payments, and those transactions involving “trusting you with the funds” and paying a representative back a portion of the money received.
										</p>

										<p>
											Follow these general guidelines to avoid being scammed:
										</p>

										<p>
											–  Question any opportunity that claims to have received your information even though you did not submit, and cannot verify or is vague about where they got your information. <br/>
											–  Deal only with individuals you can meet with in person. This alone will help you to avoid most scam attempts! <br/>
											–  Never engage in complicated money transactions, especially those involving Money Gram, Western Union or any other wiring service. <br/>
											–  Never give out your banking information, PayPal details, your social security number, etc., over email or text. <br/>
											–  If your bank flags a check as fraudulent believe it is fraudulent. If you have deposited funds into your account and suspect you have been scammed and/or money has been withdrawn from your account without your consent, contact your banking institution immediately.
										</p>

										<p>
											Above all, be cautious of any opportunity that sounds too good to be true!
										</p>

										<p>
											Here is a sample scam email for your reference. 
										</p>

										<p>
											Hello,<br/>
											Please follow the instructions below so that the <strong>travel agent</strong> can make all the necessary transport/accommodation arrangement prior to the event.<br/>
											<strong>You are to keep $5000 out of the total amount for your personal expenses which leaves you with $1480</strong>. I would need you to locate the nearest <strong>Money Gram</strong> outlet to transfer.<br/>
											AMOUNT: $1300 <br/>
											ANDREY JHONSON<br/>
											 Add: Company name <br/>
											City: Marina <br/>
											State: Dubai <br/>
											Country: UAE<br/>
											All you need to do is locate a MoneyGram outlet near you and tell them you wish to send $1300 to the recipient above. Please note that Money Gram usually charges around $120 as a sending fee.
										</p>

										<p>
											<strong>*Once the funds have been sent, please email me with the reference numbers</strong> from each transaction and I will forward it to the travel agent who will contact you with all the required travel itinerary you will need.
										</p>

										<p>
											<strong>Please note that this has to be done this morning</strong> in order to allow the travel agent enough time in making all of the necessary bookings and arrangements.
										</p>


									</>
								*/}



								


								
								


							</div>
						</div>

						

					</div>
				
				</section>

				



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(faq);