import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { login, logout, isAuthenticated, getToken } from "../services/auth";

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/inicio.css';

import api from "../services/api";
//import { login, logout, isAuthenticated, getToken } from "../services/auth";

import Admnav from '../components/admnav';


class inicio extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      pageview_page: [],
	      pageview_model: []
	    };
	}

	componentDidMount() {
	    this._getPGModel();
	    this._getPGPage();
	    this._checkLogin();
	}

	_checkLogin = async () => {
	    await api.get(`/staff/check`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        //Logado
	      }
	      else {
	        //Erro no login
	        logout();
	        this.props.history.push("/login");
	      }
	    }).catch((err) => {
	    	console.log(err);
	    	logout();
	        this.props.history.push("/login");
	    });

	}

	_getPGModel = async () => {
	    axios.get(`/pageview/model`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.pageview;
	        this.setState({
	          pageview_model: dados,
	        });
	      }
	      else {
	       
	      }
	    }).catch(() => {
	      
	    });

	 }

	 _getPGPage = async () => {
	    axios.get(`/pageview/page`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.pageview;
	        this.setState({
	          pageview_page: dados,
	        });
	      }
	      else {
	       
	      }
	    }).catch(() => {
	      
	    });

	 }

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Admnav page={'inicio'}/>

			 	<section className="modelcontainer">

			 		<div className="form-title">Visualizações</div>

			 	
		 			{this.state.pageview_page.map((f, index) =>
        				<div className="pgbox">
        					<div className="pgesq">
								Página: {f.page}
							</div>
							<div className="pgdir">
								Visualizações: {f.count}
							</div>
						</div>
                	)}

                	<div className="form-title">Modelos</div>

			 	
		 			{this.state.pageview_model.map((f, index) =>
		 				<>
		 				{f.model !== null &&
		 					<div className="pgbox">
	        					<div className="pgesq">
									{f.model.firstname} {f.model.lastname}
								</div>
								<div className="pgdir">
									Visualizações: {f.count}
								</div>
							</div>
		 				}
		 				</>
                	)}

                    

				
				</section>

			 </div>
			</div>
	    );
	}
}

export default withRouter(inicio);