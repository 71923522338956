import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';

import { uniqueId } from 'loadsh';
import filesize from 'filesize';



import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/getscouted.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import Upload from '../components/upload';
import FileList from '../components/FileList';


import { translate } from "../services/translate";

import { vet_medidas, vet_bust, vet_waist, vet_hips, vet_hair, vet_eyes, vet_category, vet_day, vet_city } from "../util/medidas";


class models extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	registred: false,
	    	uploadedFiles: [],
	    	model: null,
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'Get Scouted / Inscrição' });
	};


	handleUpload = files => {
		const uploadedFiles = files.map(file => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: null,
		}))

		this.setState({
			uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
		});

		uploadedFiles.forEach(this.processUpload);

	};

	updateFile = (id, data) => {
		this.setState({
			uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
				return id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile;
			})
		});
	}

	processUpload = (uploadedFile) => {
		const data = new FormData();
		data.append('file', uploadedFile.file, uploadedFile.name);

		api.post(`/models/insertphoto/${this.state.model._id}`, data, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100)/e.total));

				this.updateFile(uploadedFile.id, {
					progress,
				});
			}
		}).then(response => {
			//console.log(response);
			this.updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data._id,
				url: response.data.url
			});
		}).catch((error) => {
			//console.log(error);
			this.updateFile(uploadedFile.id, {
				error: true
			});
		});
	};

	handleDelete = async id => {
		await api.delete(`/models/photo/${id}`);
		this.setState({
			uploadedFiles: this.state.uploadedFiles.filter(file => file.id !== id),
		});
	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}


	handleSubmit = async e => {
	    e.preventDefault();
	    //alert(this.curriculum.current.files[0].name)
	    //console.log('ini');
	    //console.log(this.state.terms);

	    if (this.state.terms !== 'on') {
	    	alert('You must accept the terms.');
	    	return
	    }

	    const form = {
	    	firstname: this.state.firstname,
	    	lastname: this.state.lastname,
			phone: this.state.phone,
			email: this.state.email,
			instagram: this.state.instagram,
			birthday_day: this.state.birthday_day,
			birthday_month: this.state.birthday_month,
			birthday_year: this.state.birthday_year,
			about: this.state.about,
			city: this.state.city,
			skills: this.state.skills,
			category: this.state.category,
			height: this.state.height,
			bust: this.state.bust,
			waist: this.state.waist,
			hips: this.state.hips,
			hair: this.state.hair,
			eyes: this.state.eyes,
			shoes: this.state.shoes,
	    }

	    try {
	      await api.post('/models', form)
	      .then(response => { 
	        //cadastrou!
	        //this.props.history.push("/dashboard");
	        this.setState({
	        	registred: true,
	        	model: response.data.model,
	        })
	      })
	      .catch(error => {
	        console.log(error.response);
	        //console.log(error.response.status);
	          if (error.response.status === 400) {
	            //const error = error.response.data.error;
	            
	          }
	          else {
	            alert('Oops! Something went wrong, try again.');
	          }
	        
	      });

	     //-----



	    } catch (err) {
	      //console.log(err);
	      alert('Oops! Something went wrong, try again.');
	    }

	};

	

 	render() {
 		const { uploadedFiles } = this.state;
 		let years = []
	    for(let i=0; i < 70; i++){
	      years.push(<option value={2019-i}>{2019-i}</option>)
	    }
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'GET SCOUTED'}/>


			 	

				{this.state.registred === false &&
					<section className="centered">
						<div className="form-title">CANDIDACY FORM</div>
						<form className="row form" onSubmit={this.handleSubmit}>
							<div className="col-6">
								FIRST NAME<br/>
								<input type="text" name="firstname" onChange={e => this.handleChange(e)}/><br/>
							</div>
							
							<div className="col-6">
								LAST NAME<br/>
								<input type="text" name="lastname" onChange={e => this.handleChange(e)}/><br/>
							</div>

							<div className="col-6">
								PHONE<br/>
				  				<input type="text" name="phone" onChange={e => this.handleChange(e)}/><br/>
				  			</div>


				  			<div className="col-6">
				  				E-MAIL<br/>
				  				<input type="text" name="email" onChange={e => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-6">
				  				INSTAGRAM<br/>
				  				<input type="text" name="instagram" onChange={e => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-6 fullwidth">
				  				CITY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="city" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_city.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-12 form-birthday">
				  				BIRTHDAY
				  			</div>

				  			<div className="col-4 fullwidth">
				  				DAY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_day" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_day.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-4 fullwidth">
				  				MONTH<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_month" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										<option value="1">January</option>
										<option value="2">February</option>
										<option value="3">March</option>
										<option value="4">April</option>
										<option value="5">May</option>
										<option value="6">June</option>
										<option value="7">July</option>
										<option value="8">August</option>
										<option value="9">September</option>
										<option value="10">October</option>
										<option value="11">November</option>
										<option value="12">December</option>
									</select>
				  				</div>
				  			</div>

				  			<div className="col-4 fullwidth">
				  				YEAR<br/>
				  				<div className="divselect fullwidth">
				  					<select name="birthday_year" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{years}
									</select>
				  				</div>
				  			</div>

				  			<div className="col-12 form-subtitle">
				  				PERSONAL INFORMATION
				  			</div>
				  			
				  			<div className="col-12">
				  				TELL US A LITTLE ABOUT YOURSELF (BIO)<br/>
				  				<textarea name="about"  onChange={e => this.handleChange(e)}></textarea>
				  			</div>


				  			<div className="col-12">
				  				SKILLS<br/>
				  				<input type="text" name="skills" onChange={e => this.handleChange(e)}/>
				  			</div>

				  			<div className="col-6 fullwidth">
				  				CATEGORY<br/>
				  				<div className="divselect fullwidth">
				  					<select name="category" className="selectavant" onChange={e => this.handleChange(e)}>
										<option value=""></option>
										{vet_category.map((m, index) =>
					                        <option value={m}>{m}</option>
					                    )}
									</select>
				  				</div>
				  			</div>

				  			{this.state.category !== "ARTISTS" &&
				  				<>
				  				
						  			<div className="col-6 fullwidth">
						  				HEIGHT<br/>
						  				<div className="divselect fullwidth">
						  					<select name="height" className="selectavant" onChange={e => this.handleChange(e)}>
												<option value=""></option>
												{vet_medidas.map((m, index) =>
							                        <option value={m}>{m}</option>
							                    )}
											</select>
						  				</div>
						  			</div>

						  			<div className="col-6 fullwidth">
						  				BUST<br/>
						  				<div className="divselect fullwidth">
						  					<select name="bust" className="selectavant" onChange={e => this.handleChange(e)}>
												<option value=""></option>
												{vet_bust.map((m, index) =>
							                        <option value={m}>{m}</option>
							                    )}
											</select>
						  				</div>
						  			</div>

						  			<div className="col-6 fullwidth">
						  				WAIST<br/>
						  				<div className="divselect fullwidth">
						  					<select name="waist" className="selectavant" onChange={e => this.handleChange(e)}>
												<option value=""></option>
												{vet_waist.map((m, index) =>
							                        <option value={m}>{m}</option>
							                    )}
											</select>
						  				</div>
						  			</div>
						  		
						  			<div className="col-6 fullwidth">
						  				HIPS<br/>
						  				<div className="divselect fullwidth">
						  					<select name="hips" className="selectavant" onChange={e => this.handleChange(e)}>
												<option value=""></option>
												{vet_hips.map((m, index) =>
							                        <option value={m}>{m}</option>
							                    )}
											</select>
						  				</div>
						  			</div>

						  			<div className="col-6 fullwidth">
						  				HAIR<br/>
						  				<div className="divselect fullwidth">
						  					<select name="hair" className="selectavant" onChange={e => this.handleChange(e)}>
												<option value=""></option>
												{vet_hair.map((m, index) =>
							                        <option value={m}>{m}</option>
							                    )}
											</select>
						  				</div>
						  			</div>

						  			<div className="col-6 fullwidth">
						  				EYES<br/>
						  				<div className="divselect fullwidth">
						  					<select name="eyes" className="selectavant" onChange={e => this.handleChange(e)}>
												<option value=""></option>
												{vet_eyes.map((m, index) =>
							                        <option value={m}>{m}</option>
							                    )}
											</select>
						  				</div>
						  			</div>


						  			<div className="col-6">
						  				SHOES<br/>
						  				<input type="text" name="shoes" onChange={e => this.handleChange(e)}/>
						  			</div>
				  				</>
				  			}


				  			<div className="col-12 ">
							  	<label className="labelcheck" for="terms"><input className="inputcheck" type="checkbox" id="terms" name="terms"  onChange={e => this.handleChange(e)}/> I have read and accept the Application Process and Rules and the Privacy Policy.</label>
							</div>

							<div className="col-12">
								<input className="sendbtn" type="submit" value="NEXT"/>
							</div>


						</form>
				

					</section>
				}







				

				{this.state.registred &&
					<section className="centered">
						<div className="form-title">PHOTO SUBMISSIONS</div>
						<p className="tips">
							{translate('photo_tips')}
						</p>
						<Upload onUpload={this.handleUpload}/>
						{ !!uploadedFiles.length && (
							<FileList files={uploadedFiles} onDelete={this.handleDelete}/>
						)}
						<button className="finishbtn" onClick={() => { this.props.history.push("/"); }}>FINISH</button>
						<br/>
					</section>
				}




			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(models);