import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag";
import Carousel from 'nuka-carousel';

import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/model.css';

import api from "../services/api";

import esq from '../images/esq.png';
import dir from '../images/dir.png';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { translate } from "../services/translate";


class model extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      models: null,
	      visible: false,
	      link: '',
	      width: 0, 
	      height: 0,
	      slideIndex: 0,
	    };
	    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	show(link) {
        this.setState({ visible: true, link: link });
    }

    hide() {
        this.setState({ visible: false });
    }

	componentDidMount() {
		this.updateWindowDimensions();
  		window.addEventListener('resize', this.updateWindowDimensions);
	    this._downloadModels();
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	pageView = async () => {
		api.post(`/pageview/model`, { model: this.state.models._id });
	};

	_downloadModels = async () => {
	    api.get(`/models/${this.props.match.params.id}`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.models;
	        this.setState({
	          models: dados,
	        });
	        this.pageView();
	      }
	      else {
	        this.props.history.push("/404");
	      
	      }
	    }).catch((error) => {
	      this.props.history.push("/404");
	    });

	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}


	handleSubmit = async e => {
	    e.preventDefault();
	    const form = {
	    	author: `${this.state.firstname} ${this.state.lastname}`,
	    	email: this.state.email,
	    	comments: this.state.comments
	    }

	    try {
	      await api.post(`/cm/${this.state.models._id}`, form)
	      .then(response => { 
	        //cadastrou!
	        alert('Comment submitted.');
	      })
	      .catch(error => {
	      	console.log(error);
	      	alert('Oops! Something went wrong, try again..');
	      });
	    } catch (err) {
	    	console.log(err);
	      alert('Oops! Something went wrong, try again.');
	    }

	};


 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={this.state.models !== null ? this.state.models.category : ''}/>

			 	{this.state.models === null &&
			 		<section className="modelcontainer">

						<div className="row name">
							<div className="col-12">
								LOADING...
							</div>
						</div>

					</section>
			 	}

			 	{this.state.models !== null &&
			 		<section className="modelcontainer">

						<div className="row name">
							<div className="col-12">
								{`${this.state.models.firstname.toUpperCase()} ${this.state.models.lastname.toUpperCase()}`} 
								{(this.state.models.hasOwnProperty('nationality') && this.state.models.nationality !== '' && false) &&
									` - ${this.state.models.nationality.toUpperCase()}`

								}
								{(this.state.models.hasOwnProperty('nationality') && this.state.models.nationality !== '' ) &&
									<>
										<ReactCountryFlag countryCode={this.state.models.nationality} svg style={{marginLeft: 10, marginTop: -4}}/>
									</>
								}
							</div>
						</div>

						<div className="row">
							<div className="col-12" style={{marginTop: 15}}>
								<Carousel autoplay={true} heightMode={'max'} initialSlideHeight={600} 
				 					renderCenterLeftControls={({ previousSlide }) => (
							    		<button onClick={previousSlide} className="btnslideresq noSelect">
							    			<img className="photo noSelect" src={esq}/>
							    		</button>
							  		)}
							  		renderCenterRightControls={({ nextSlide }) => (
							    		<button onClick={nextSlide} className="btnsliderdir noSelect">
							    			<img className="photo noSelect" src={dir}/>
							    		</button>
							  		)}

				 				>
							        
							        {this.state.models.images.map((f, index) =>
							        	<div style={{height: 500, width: '100%'}} onClick={() => {
							        		this.setState({ slideIndex: index }); 
							        		this.show(f.url); 
							        	}}>
	                        				<img className="fotomeio" src={f.url} />
	                        			</div>
			                    	)}
							    </Carousel>
							</div>
						</div>


						<div className="row" style={{marginTop: 50}}>
							<div className="col-md-1 col-sm-0"/>
							<div className="col-md-5 col-sm-12" style={{textAlign: 'right'}}>
								<h1 className="namemodel">{`${this.state.models.firstname.toUpperCase()} ${this.state.models.lastname.toUpperCase()}`} </h1>
								{(this.state.models.pdf !== undefined && this.state.models.pdf !== null && this.state.models.pdf !== '') &&
									<a className="btnpdf" style={{marginTop: 0}} href={this.state.models.pdf} download="portfolio" target="_blank">DOWNLOAD PORTFOLIO</a>
								}
							</div>
							<div className="col-md-5 col-sm-12 about">
								<div className="quebra">
									{this.state.models.about}
								</div>

								<p>
									{this.state.models.skills}
								</p>

								{/*LINKS:*/}<br/>
								<div style={{marginTop: 5}}>

									{(this.state.models.instagram !== null && this.state.models.instagram !== '' && this.state.models.instagram !== undefined) &&
										<a href={`http://instagram.com/${this.state.models.instagram}`}  target="_blank"><i className="fa fa-instagram iconesmodel" aria-hidden="true"></i></a>
									}
									{(this.state.models.imdb !== null && this.state.models.imdb !== '' && this.state.models.imdb !== undefined) &&
										<a href={this.state.models.imdb} target="_blank"><i className="fa fa-imdb iconesmodel" aria-hidden="true"></i></a>
									}
									
									{(this.state.models.wiki !== null && this.state.models.wiki !== '' && this.state.models.wiki !== undefined) &&
										<a href={this.state.models.wiki} target="_blank"><i className="fa fa-wikipedia-w iconesmodel" aria-hidden="true"></i></a>
									}

									{/*this.state.models.videos.map((i, index) =>
										<a href={i.url}><i className="fa fa-youtube iconesmodel" aria-hidden="true"></i></a>
									)*/}

									
								</div>






							</div>
							<div className="ol-md-1 col-sm-0"/>
						</div>

						<div className="row" style={{marginTop: 50}}>
							<div className="col-12 barrainfos row justify-content-center">
								

								{!!this.state.models.height && 
									<div className="col-centered iteminfo">
										<div style={{color: '#777'}}>
											HEIGHT
										</div>
										<div>
											{this.state.models.height.toUpperCase()}
										</div>									
									</div>
								}


								{!!this.state.models.hair && 
									<div className="col-centered iteminfo">
										<div style={{color: '#777'}}>
											HAIR COLOR
										</div>
										<div>
											{this.state.models.hair.toUpperCase()}
										</div>									
									</div>
								}

								{!!this.state.models.eyes && 
									<div className="col-centered iteminfo">
										<div style={{color: '#777'}}>
											EYES
										</div>
										<div>
											{this.state.models.eyes.toUpperCase()}
										</div>									
									</div>
								}

								{!!this.state.models.bust && 
									<div className="col-centered iteminfo">
										<div style={{color: '#777'}}>
											BUST
										</div>
										<div>
											{this.state.models.bust.toUpperCase()}
										</div>									
									</div>
								}

								{!!this.state.models.waist && 
									<div className="col-centered iteminfo">
										<div style={{color: '#777'}}>
											WAIST
										</div>
										<div>
											{this.state.models.waist.toUpperCase()}
										</div>									
									</div>
								}

								{!!this.state.models.hips && 
									<div className="col-centered iteminfo">
										<div style={{color: '#777'}}>
											HIPS
										</div>
										<div>
											{this.state.models.hips.toUpperCase()}
										</div>									
									</div>
								}

								{!!this.state.models.shoes && 
									<div className="col-centered iteminfo">
										<div style={{color: '#777'}}>
											SHOES
										</div>
										<div>
											{this.state.models.shoes.toUpperCase()}
										</div>									
									</div>
								}

							</div>
						</div>

						
						{(this.state.models.videos !== null && this.state.models.videos !== []) &&
							<div className="row justify-content-center" style={{marginTop: 60}}>
								{this.state.models.videos.length === 1 &&
									<div className="col-centered">
										{this.state.models.videos.map((i, index) =>
											<iframe width={this.state.width * 0.65} height={this.state.width * 0.65 * (9/16)} src={i.url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
										)}
									</div>
								}

								{this.state.models.videos.length > 1 &&
									<div className="col-centered">
										<Carousel className="centralizado" heightMode={'max'} initialSlideHeight={this.state.width * 0.65 * (9/16)}  width={(this.state.width * 0.65) + 160}
						 					renderCenterLeftControls={({ previousSlide }) => (
									    		<button onClick={previousSlide} className="btnslideresq">
									    			<img className="photo" src={esq}/>
									    		</button>
									  		)}
									  		renderCenterRightControls={({ nextSlide }) => (
									    		<button onClick={nextSlide} className="btnsliderdir">
									    			<img className="photo" src={dir}/>
									    		</button>
									  		)}

						 				>
						 					{this.state.models.videos.map((i, index) =>
									        	<iframe className="centralizado" width={this.state.width * 0.65} height={this.state.width * 0.65 * (9/16)} src={i.url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									        )}
									    </Carousel>
									</div>
								}
							</div>
						}	
						


						
						<div className="row">
								

							<div className="col-12 measurements about" >
								<div className="hvzio2"/>

								{(this.state.models.category === 'MEN' || this.state.models.category === 'WOMEN' || this.state.models.category === 'KIDS'  || this.state.models.category === 'INFLUENCERS'  || this.state.models.category === 'TV HOST') &&
									<button className="btnhire" style={{marginRight: 10, marginTop: 5}} onClick={()=> (this.props.history.push(`/hire/models/${this.state.models._id}`))}>HIRE {this.state.models.firstname.toUpperCase()} {this.state.models.lastname.toUpperCase()}</button>
								}

								{(this.state.models.category === 'ACTOR') &&
									<button className="btnhire" style={{marginRight: 10, marginTop: 5}} onClick={()=> (this.props.history.push(`/hire/actor/${this.state.models._id}`))}>HIRE {this.state.models.firstname.toUpperCase()} {this.state.models.lastname.toUpperCase()}</button>
								}

								{(this.state.models.category === 'SINGER' || this.state.models.category === 'DJ' || this.state.models.category === 'COMEDY') &&
									<button className="btnhire" style={{marginRight: 10, marginTop: 5}} onClick={()=> (this.props.history.push(`/hire/show/${this.state.models._id}`))}>HIRE {this.state.models.firstname.toUpperCase()} {this.state.models.lastname.toUpperCase()}</button>
								}								

								{(this.state.models.category === 'HAIR STYLES' || this.state.models.category === 'MAKE-UP') &&
									<button className="btnhire" style={{marginRight: 10, marginTop: 5}} onClick={()=> (this.props.history.push(`/schedule/${this.state.models._id}`))}>SCHEDULE ATTENDANCE</button>
								}
								

								
								
							</div>

							{(this.state.models.category === 'SINGER') &&
								<div style={{marginLeft: 50}}>
									{/*<div className="row name" style={{marginTop: 15, height: 20}}>
										<div className="col-12">
											COMMENTS
										</div>
									</div>
									*/}
									<div className="row" style={{marginTop: 20}}>

										<div className="col-12">
											COMMENTS
										</div>

										{this.state.models.comments.map((i, index) =>
											<>
												{i.approved === true &&
													<div className="col-12">
														<p className="comment">
															<strong style={{marginBottom: 8}}>{i.author}</strong><br/>
															{i.comments}
														</p>
													</div>
												}
											</>
										)}

										<div className="col-12">
											<h3 className="comment"> SEND COMMENT</h3>
											<p>Your email address will not be published</p>
											<form className="row form" onSubmit={this.handleSubmit}>
												<div className="col-3">
													FIRST NAME<br/>
													<input type="text" name="firstname" onChange={e => this.handleChange(e)}/><br/>
												</div>
												
												<div className="col-3">
													LAST NAME<br/>
													<input type="text" name="lastname" onChange={e => this.handleChange(e)}/><br/>
												</div>

												<div className="col-6">
													E-MAIL<br/>
													<input type="text" name="email" onChange={e => this.handleChange(e)}/><br/>
												</div>

												<div className="col-12">
									  				COMMENT<br/>
									  				<textarea className="commentarea" name="comments"  onChange={e => this.handleChange(e)}></textarea>
									  			</div>

									  			<div className="col-12">
													<input className="sendbtn" type="submit" value="SEND"/>
												</div>

											</form>

										</div>



										

									</div>


								</div>
							}
							
							
						</div>

						
					
					</section>
			 	}

				


			 </div>
			 <Footer />
			 <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} height={this.state.height * 0.92} width={this.state.width * 0.92} className="sobretudo">
                    <div className="row">
                    	<div className="col-12">
                    		{/*<div style={{height: this.state.height * 0.82, width: this.state.width * 0.89}} className="popcenter">
                				<img className="fotomeio" src={this.state.link} />
                			</div>*/}
                			{this.state.models !== null &&
	                			<Carousel className="popcenter" heightMode={'max'} initialSlideHeight={this.state.height * 0.82} 
	                				slideIndex={this.state.slideIndex}
        							afterSlide={slideIndex => this.setState({ slideIndex })}
				 					renderCenterLeftControls={({ previousSlide }) => (
							    		<button onClick={previousSlide} className="btnslideresq">
							    			<img className="photo" src={esq}/>
							    		</button>
							  		)}
							  		renderCenterRightControls={({ nextSlide }) => (
							    		<button onClick={nextSlide} className="btnsliderdir">
							    			<img className="photo" src={dir}/>
							    		</button>
							  		)}

				 				>
							        
							        {this.state.models.images.map((f, index) =>
							        	<div style={{height: 500, width: '100%'}}>
	                        				<img className="fotomeio" src={f.url} />
	                        			</div>
			                    	)}
							    </Carousel>
							}
                    	</div>
                    </div>
              </Rodal>
			</div>
	    );
	}
}

export default withRouter(model);