import React, {Component} from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';

import '../css/bootstrap-grid.css';
import '../css/login.css';

import logo from '../images/logo.png';


class error404 extends Component {
 	render() {
	    return (
	    	<div className="">

			 	<section className="modelcontainer">
			 		<div className="centeredlogin">
			 			<form className="formlogin" onSubmit={this.handleLogin}>
			 				<img className="logologin noselect" href="index.html" src={logo} alt="Logo"/>
				 			<h1>Error: 404</h1>
						</form>
			 		</div>
				</section>

			</div>
	    );
	}
}

export default withRouter(error404);