import React, {Component} from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import api from "../../services/api";

import '../../css/style.css';
import '../../css/bootstrap-grid.css';
import '../../css/about.css';

import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

import { translate } from "../../services/translate";


class about extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    	
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: 'RV About' });
	};

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'CLIENTS'}/>

				
			 	<section className="modelcontainer">

			 		<div className="row name">
						<div className="col-12">
							<div className="form-title">CLIENTS</div>
						</div>
					</div>

					<div className="row">
					

						<div className="col-12">
							<div className="textbox_a">

								<p>
									Advertising:<br/>
									American Express, Anthropologie, Bentley, Bloomingdales, Brixton, BBC Worldwide, Cadillac, Calvin Klein, Club Monaco, Eastpak, Diane Von Furstenberg, Dolce and Gabbana, DKNY, FBB, Free People, H&M, Hermès, J Crew, IBM, Lacoste, Lands End, LLBean, Lane Bryant, Levi’s, Maybelline, Macys, Miu Miu NBC, Nike, Nordstrom, Neiman Marcus,  Perry Ellis, Pfizer, Prada, Rag and Bone, Ralph Lauren, Talbots, Tiffany, US OPEN, Zales, Vans, Victoria Secret, Volkswagen.<br/>
									Editorial: <br/>
									W Magazine, American Vogue, British Vogue, Cosmopolitan, Vice & V Magazine, Elle, GQ,  Good Housekeeping, Glamour, Harpers Bazaar, Italian Vogue, Lucky, Marie Claire, Fitness, Nylon, WSJ.
								</p>
								

							</div>
						</div>

						

					</div>
				
				</section>

				



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(about);