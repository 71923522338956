import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag";
import Carousel from 'nuka-carousel';

import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

import '../css/style.css';
import '../css/bootstrap-grid.css';
import '../css/model.css';

import api from "../services/api";

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { translate } from "../services/translate";


class model extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	      models: null,
	      visible: false,
	      link: '',
	    };
	}

	show(link) {
        this.setState({ visible: true, link: link });
    }

    hide() {
        this.setState({ visible: false });
    }

	componentDidMount() {
	    this._downloadModels();
	}

	pageView = async () => {
		api.post(`/pageview/model`, { model: this.state.models._id });
	};

	_downloadModels = async () => {
	    api.get(`/models/${this.props.match.params.id}`)
	    .then(res => {
	      if (res.status === 200 || res.status === 304) {
	        const dados = res.data.models;
	        this.setState({
	          models: dados,
	        });
	        this.pageView();
	      }
	      else {
	        this.props.history.push("/404");
	      
	      }
	    }).catch((error) => {
	      this.props.history.push("/404");
	    });

	}

	handleChange = (e) => {
	    this.setState({
	    	[e.target.name]: e.target.value
		})
	}


	handleSubmit = async e => {
	    e.preventDefault();
	    const form = {
	    	author: `${this.state.firstname} ${this.state.lastname}`,
	    	comments: this.state.comments
	    }

	    try {
	      await api.post(`/cm/${this.state.models._id}`, form)
	      .then(response => { 
	        //cadastrou!
	        alert('Comment submitted.');
	      })
	      .catch(error => {
	      	console.log(error);
	      	alert('Oops! Something went wrong, try again..');
	      });
	    } catch (err) {
	    	console.log(err);
	      alert('Oops! Something went wrong, try again.');
	    }

	};


 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={this.state.models !== null ? this.state.models.category : ''}/>

			 	{this.state.models === null &&
			 		<section className="modelcontainer">

						<div className="row name">
							<div className="col-12">
								LOADING...
							</div>
						</div>

					</section>
			 	}

			 	{this.state.models !== null &&
			 		<section className="modelcontainer">

						<div className="row name">
							<div className="col-12">
								{`${this.state.models.firstname.toUpperCase()} ${this.state.models.lastname.toUpperCase()}`} 
								{(this.state.models.hasOwnProperty('nationality') && this.state.models.nationality !== '' && false) &&
									` - ${this.state.models.nationality.toUpperCase()}`

								}
								{(this.state.models.hasOwnProperty('nationality') && this.state.models.nationality !== '' ) &&
									<>
										<ReactCountryFlag countryCode={this.state.models.nationality} svg style={{marginLeft: 10, marginTop: -4}}/>
									</>
								}
							</div>
						</div>



						<div className="row">

							{(this.state.models.about !== '' || true) &&
								<div className="col-6 about" style={{marginTop: 10}}>
									<div className="quebra">
										{this.state.models.about}
									</div>

									<p>
										{this.state.models.skills}
									</p>

									{this.state.models.category !== 'ARTISTS' &&
										<p>
											{!!this.state.models.height ? <>・ HEIGHT {this.state.models.height.toUpperCase()}<br/></> : ''}
											{!!this.state.models.hair ? <>・ HAIR COLOR {this.state.models.hair.toUpperCase()}<br/></> : ''}
											{!!this.state.models.eyes ? <>・ EYES {this.state.models.eyes.toUpperCase()}<br/></> : ''}
											{!!this.state.models.bust ? <>・ BUST {this.state.models.bust.toUpperCase()}<br/></> : ''}
											{!!this.state.models.waist ? <>・ WAIST {this.state.models.waist.toUpperCase()}<br/></> : ''}
											{!!this.state.models.hips ? <>・ HIPS {this.state.models.hips.toUpperCase()}<br/></> : ''}
											{!!this.state.models.shoes ? <>・ SHOES {this.state.models.shoes.toUpperCase()}<br/></> : ''}
										</p>
									}

									LINKS:<br/>
									<div style={{marginTop: 5}}>

										{(this.state.models.instagram !== null && this.state.models.instagram !== '' && this.state.models.instagram !== undefined) &&
											<a href={`http://instagram.com/${this.state.models.instagram}`} ><i className="fa fa-instagram iconesmodel" aria-hidden="true"></i></a>
										}
										{(this.state.models.imdb !== null && this.state.models.imdb !== '' && this.state.models.imdb !== undefined) &&
											<a href={this.state.models.imdb}><i className="fa fa-imdb iconesmodel" aria-hidden="true"></i></a>
										}
										
										{(this.state.models.wiki !== null && this.state.models.wiki !== '' && this.state.models.wiki !== undefined) &&
											<a href={this.state.models.wiki}><i className="fa fa-wikipedia-w iconesmodel" aria-hidden="true"></i></a>
										}

										{this.state.models.videos.map((i, index) =>
											<a href={i.url}><i className="fa fa-youtube iconesmodel" aria-hidden="true"></i></a>
										)}

										
									</div>


								</div>
							}

							<div className="col-6">
				 				<Carousel autoplay={true} heightMode={'max'} initialSlideHeight={500} 
				 					renderCenterLeftControls={({ previousSlide }) => (
							    		<button onClick={previousSlide} className="btnslider">{'<'}</button>
							  		)}
							  		renderCenterRightControls={({ nextSlide }) => (
							    		<button onClick={nextSlide} className="btnslider">{'>'}</button>
							  		)}

				 				>
							        
							        {this.state.models.images.map((f, index) =>
							        	<div style={{height: 500, width: '100%'}} onClick={() => { this.show(f.url); }}>
	                        				<img className="fotomeio" src={f.url} />
	                        			</div>
			                    	)}
							      </Carousel>
				 			</div>
							
						</div>


						
						<div className="row">
								

							<div className="col-12 measurements about">
								<div className="hvzio"/>

								{(this.state.models.category === 'MEN' || this.state.models.category === 'WOMEN' || this.state.models.category === 'KIDS'  || this.state.models.category === 'INFLUENCERS') &&
									<button className="btnhire" style={{marginRight: 10, marginTop: 20}} onClick={()=> (this.props.history.push(`/hire/models/${this.state.models._id}`))}>HIRE {this.state.models.firstname.toUpperCase()} {this.state.models.lastname.toUpperCase()}</button>
								}

								{(this.state.models.category === 'ACTOR') &&
									<button className="btnhire" style={{marginRight: 10, marginTop: 20}} onClick={()=> (this.props.history.push(`/hire/actor/${this.state.models._id}`))}>HIRE {this.state.models.firstname.toUpperCase()} {this.state.models.lastname.toUpperCase()}</button>
								}

								{(this.state.models.category === 'SINGER' || this.state.models.category === 'DJ' || this.state.models.category === 'COMEDY') &&
									<button className="btnhire" style={{marginRight: 10, marginTop: 20}} onClick={()=> (this.props.history.push(`/hire/show/${this.state.models._id}`))}>HIRE {this.state.models.firstname.toUpperCase()} {this.state.models.lastname.toUpperCase()}</button>
								}								

								{(this.state.models.category === 'HAIR STYLES' || this.state.models.category === 'MAKE-UP') &&
									<button className="btnhire" style={{marginRight: 10, marginTop: 20}} onClick={()=> (this.props.history.push(`/schedule/${this.state.models._id}`))}>SCHEDULE ATTENDANCE</button>
								}
								

								
								
							</div>

							{(this.state.models.category === 'SINGER') &&
								<>
									<div className="row name" style={{marginTop: 15}}>
										<div className="col-12">
											COMMENTS
										</div>
									</div>

									<div className="row">

										{this.state.models.comments.map((i, index) =>
											<>
												{i.approved === true &&
													<div className="col-12">
														<p className="comment">
															<strong style={{marginBottom: 8}}>{i.author}</strong><br/>
															{i.comments}
														</p>
													</div>
												}
											</>
										)}

										<div className="col-12">
											<h3 className="comment"> SEND COMMENT</h3>
											<form className="row form" onSubmit={this.handleSubmit}>
												<div className="col-6">
													FIRST NAME<br/>
													<input type="text" name="firstname" onChange={e => this.handleChange(e)}/><br/>
												</div>
												
												<div className="col-6">
													LAST NAME<br/>
													<input type="text" name="lastname" onChange={e => this.handleChange(e)}/><br/>
												</div>

												<div className="col-12">
									  				COMMENT<br/>
									  				<textarea className="commentarea" name="comments"  onChange={e => this.handleChange(e)}></textarea>
									  			</div>

									  			<div className="col-12">
													<input className="sendbtn" type="submit" value="SEND"/>
												</div>

											</form>

										</div>



										

									</div>


								</>
							}
							
							
						</div>

						
					
					</section>
			 	}

				


			 </div>
			 <Footer />
			 <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} height={600} width={600} className="sobretudo">
                    <div className="row">
                    	<div className="col-12">
                    		<div className="popcenter">
                				<img className="fotomeio" src={this.state.link} />
                			</div>
                    	</div>
                    </div>
              </Rodal>
			</div>
	    );
	}
}

export default withRouter(model);