import React, {Component} from 'react';
import axios from 'axios';
import { Redirect, withRouter, Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';

import '../../css/style.css';
import '../../css/bootstrap-grid.css';
import '../../css/model.css';
import '../../css/rv.css';

import api from "../../services/api";

import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

import { translate } from "../../services/translate";

import img1 from '../../views/RVS/RV Water/1.jpg';
import img2 from '../../views/RVS/RV Water/2.JPG';
import img3 from '../../views/RVS/RV Water/3.jpg';
import img4 from '../../views/RVS/RV Water/4.png';
import img5 from '../../views/RVS/RV Water/5.jpg';


class model extends Component {
	constructor(props) {
		super(props);
	    this.state = {
	    
	    };
	}

	componentDidMount() {
	    this.pageView();
	}

	pageView = async () => {
		api.post(`/pageview/page`, { page: `RV's - Walter` });
	};

	

 	render() {
	    return (
	      <div className="page-container">
	      	<div className="content-wrap">
			 	<Navbar page={'Walter'}/>

			 	<section className="modelcontainer">

					<div className="row name">
						<div className="col-md-6 col-sm-12" style={{marginBottom: 10}}>
							Walter
						</div>
					</div>


					<div className="row">

						
						<div className="col-6 about" style={{marginTop: 10}}>
							This vehicle is available in all of our locations.<br/>
							- Mobile High speed Internet 5 MG upon request<br/>
							- HD TV with Direct TV<br/>
							- 10K Built in Diesel Generator<br/>
							- Solar Energy Panels <br/>
							- Central heat and 3 air conditioning units<br/>
							- Large fridge for kraft and catering needs<br/>
							- Lounge area with seating for 11<br/>
							- 2 double makeup stations with LED vanity lighting<br/>
							- Large dressing room with 20" of built in wardrobe racks<br/>
							- Rear door<br/>
							- Ramp for easy load in<br/>
							- Electric Awning<br/>
							- Expresso Machine<br/>
							Height - 13”<br/>
							Length - 42”<br/>
							Width - 15” opened
						</div>


						<div className="col-md-6 col-sm-12">

							<Carousel autoplay={true} heightMode={'max'} initialSlideHeight={400} 
				 					renderCenterLeftControls={({ previousSlide }) => (
							    		<button onClick={previousSlide} className="btnslider">{'<'}</button>
							  		)}
							  		renderCenterRightControls={({ nextSlide }) => (
							    		<button onClick={nextSlide} className="btnslider">{'>'}</button>
							  		)}

				 				>
							        
							        <div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img1} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img2} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img3} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img4} />
                        			</div>

                        			<div style={{height: 400, width: '100%'}}>
                        				<img className="fotomeio" src={img5} />
                        			</div>






							</Carousel>
				 			
							
						</div>

						<div className="col-12 measurements about">
							<div className="hvzio"/>
							<button className="btnhire" style={{marginRight: 10, marginTop: 20}} onClick={()=> (this.props.history.push(`/rent/4`))}>RENT WALTER</button>
						</div>



					</div>


				</section>



			 </div>
			 <Footer />
			</div>
	    );
	}
}

export default withRouter(model);